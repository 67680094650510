import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { GetPricingDetails } from "../../Services/FlightDetails"
import { GetRePricingDetails } from "../../Services/FlightDetails"
import { GetPricingDetailsIR } from "../../Services/FlightDetails"
//import { GetRePricingDetailsIR } from "../../Services/FlightDetails"
//import { URLSearchParams } from 'url';
import { AES, enc } from 'crypto-js';
import { useNavigate, json } from 'react-router-dom'
import Countries from '../../appData/contries.json'
import states from '../../appData/states.json'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { getYear, getMonth } from 'react-datepicker';
import range from "lodash/range";
import dateFormat, { masks } from "dateformat";
import { GetBookingConfirm } from "../../Services/FlightDetails"
import { GetStates, GetCountries } from '../../Services/UtilitiesServices'
import { Authenticate, SubmitOTPToLogin, LogoutUser } from '../../Services/AccountServices';
import Modal from "react-bootstrap/Modal";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
// import Toast from 'react-bootstrap/Toast';
// import ToastContainer from 'react-bootstrap/ToastContainer';
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IsBasicValidationSuccess, CheckValidation, CommonFieldCheckValidation, CheckNetworkStatus, paxNameDuplicationCheck} from '../../Utilities/Validations';
import Spinner from 'react-bootstrap/Spinner';
import { addYears } from 'date-fns';
import { format } from 'date-fns';
import moment from"moment";
import {decodeJwt} from 'jose'
import { GoogleAuthentication } from "../../Services/GoogleAuthentication"
import { GoogleLogin } from '@react-oauth/google';
import { auth , provider} from "../../Configuration/firebase";
import { createUserWithEmailAndPassword,signInWithPopup, signOut } from "firebase/auth";


class UserDetails extends Component {
  isBookingBtnPressed=false;
  constructor(props) {
    super(props);
    this.state = {
      isGoogleAuthenticated: false,
      GoogleAuthenticatedEmail:"",
      isGoogleVerificationSuccess : false,
      credential:'',
      email:'',
      seconds: 0,
      minutes: 15,
      isFailed:false,
      isLogin:false,
      isRequiredContactNumber: false,
      isRequiredContactEmail: false,
      errorMessageContactNumber: "",
      errorMessageContactEmail: "",
      isLoaded: false,
      selectedMealIndex: -1,
      ApiPricingResponse:[],
      RePricingPaxlist: [],
      RequestFields: [],
      PricingDetailsResult: [],
      objseglistResult: [],
      TaxListResult: [],
      AdtpricingPaxListResult: [],
      ChdpricingPaxListResult: [],
      InfpricingPaxListResult: [],
      //objbaggageListResult: [],
      itinId: 0,
      fareId: 0,
      providerCode: "",
      itinIdR: 0,
      fareIdR: 0,
      providerCodeR: "",
      requestBody: {},
      RePricingDetailsResult: [],
      ReobjseglistResult: [],
      ReTaxListResult: [],

      objAdtPaxList: [],
      objchdPaxList: [],
      objinfPaxList: [],
      contactNumber: "",
      alternateContactNumber: "",
      contactEmail: "",
      BookingPaxdetails: [],
      BookingGstDetails: [],
      checkoutRequest: "",
      IRTcheckoutRequest: "",
      BookingResponse: [],
      BookingSegmentDetails: [],
      BookingPaxlistRsp: [],
      selectedCountry: 101,
      selectedCountryName: "",
      // countries: Countries,
      countryId: 0,
      selectedCountryIssue: 0,
      selectedCountryIssueName: "",
      countriesIssue: Countries,
      countryIssueId: 0,
      selectedState: 0,
      selectedStateName: "",
      States: states,
      stateId: 0,
      startDate: new Date(),
      ChildDobStartDate: addYears(new Date(), -2),
      years: range(1900, (new Date()).getFullYear() + 50, 1),

      AdultDobYears: range(1900, (new Date()).getFullYear() + 1, 1),
      ChildDobYears: range((new Date()).getFullYear() - 12, (new Date()).getFullYear() - 1, 1),
      InfantDobYears: range((new Date()).getFullYear() - 2, (new Date()).getFullYear() + 1, 1),

      ChildDateByDeptDate:"",

      doeYears: range((new Date()).getFullYear(), (new Date()).getFullYear() + 51, 1),
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      passengers: [
        {
          firstName: "",
          lastName: "",
          titleSelectBoxName: "",
          passportNumber: '',
          selectedCountry: '',
          dateOfBirth: null,
          selectedCountryIssue: '',
          dateOfExpiry: null,
        }
      ],
      BookRequestBody:{},
      tempDAta:{},
      // tempData: {
      //   prefixContactNumber: "+91",
      //   prefixAltrContactNumber: "+91",
      // },
      objGst:{},
      objPaxList:[],
      allPaxDataList:[],
      allPaxMealsAmount:[],
      allPaxBaggageAmount:[],
      sumMealAnBaggage:0,
      selectedCheckbox: [],
      selectedCheckboxChild: [],

      AdultDatesArray:[],
      ChildDatesArray:[],
      InfantDatesArray:[],
      DestinationArrivalTime:new Date(),
      displayCount: 6,
      showLess: false,
      prefixContactNumber:"+91",
      prefixAltrContactNumber:"+91",
      // IndianStates:[],
      Countries:[],
      error: "",
      showButton: false,
      isModalOpen: false,
      UserDetails: {},
      loginRequest: {},
      otpSubmitRequest: {},
      isOtpSent: false,
      isOtpSuccess:false,
      OtpResentOnce:0,    
      showButton: false,
      secondsRemaining: 60,
      IsRefresh:false,
      showLogin:false,
      show: false,
      FieldValidations:[],  //Adult section
      ChildFieldValidations:[],
      InfantFieldValidations:[],
      CommonFieldValidations:{
        contactNumber:false,
        alternateContactNumber:true,
        contactEmail:false
      },
      isFormSubmitted:false,
      isPassportMandatory:true,
      DepartureDate:new Date(),

    };
    this.ModalHideShow = this.ModalHideShow.bind(this);
    this.LoginOTP = this.LoginOTP.bind(this);
    this.SubmitOTP = this.SubmitOTP.bind(this);
    this.UseAnotherMobileNumber = this.UseAnotherMobileNumber.bind(this);
  }

  // onSuccess = (credentialResponse) => {
  //   console.log(credentialResponse);
  //   const { credential } = credentialResponse;
  //   const payload = credential ? decodeJwt(credential) : undefined;
  
  //   if (payload) {
  //     console.log(payload);
  //     console.log(payload.email);
  
  //     // Call your API function with the necessary data
  //     this.IdTokenValidation( credential,  payload.email );
  //   }
  // };
  signInWithGoogle = async () => {
    try {
    await signInWithPopup(auth,provider).then((data)=>{
        //console.log(data);
  
        if(data.user.emailVerified)
        {
            // Call your API function with the necessary data
            this.IdTokenValidation( data._tokenResponse.oauthIdToken,  data.user.email );
        }
    });
    } catch (err){
      console.error(err);
    }
  };

  IdTokenValidation(credential,email){
    let data = {};
    data.idToken= credential;
    data.emailId = email;
    this.setState({
      credential: credential,          
      email: email,
    });
    GoogleAuthentication(data)
      .then((response) => {
        // Handle the API response here
        //console.log('Google Authentication API response', response);
        if (response.data.status && response.data.responseMessage == 'Logined Successfully') {
          this.setState({
            isGoogleAuthenticated: false,  
            isGoogleVerificationSuccess :true,                
          });

          this.setState({
            showLogin:false,
            isLogin:true,
          }) 
          localStorage.setItem('tokenValue', response.data.token);
            localStorage.removeItem('NonUsertokenValue');
            localStorage.setItem('TM_UDetails', JSON.stringify(response.data.objUser));
  
            this.setState({
              isOtpSuccess:false,
              IsRefresh:true,
            })
            toast.success('You are successfully logged in', {
              position: 'bottom-center',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            });
           
            
            //window.location.reload();
           
        }
        else if(response.data.status && response.data.responseMessage == 'Success')
        {
          let data = this.state.loginRequest;
          data.emailId = response.data.email;
          this.setState({
            isGoogleAuthenticated: true,
            GoogleAuthenticatedEmail : response.data.email,
            loginRequest : data,
            isGoogleVerificationSuccess : true,
          });
        }
        else
        {
          this.setState({
            isGoogleAuthenticated: false,
            isGoogleVerificationSuccess :false,
          });
          this.setState({
            isOtpSuccess:true
        })
        }
      })
      .catch((error) => {
        // Handle API call errors here
        console.error('Google Authentication API error', error);
      });
  }

  updateTime = () => {
    const { minutes, seconds } = this.state;

    if (minutes === 0 && seconds === 0) {
      clearInterval(this.interval);
       // Display a toast notification
       toast.error(`Session has expired`, {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        onClose: () => {
          localStorage.removeItem('timerState');
          // Redirect to the homepage
      window.location.href = '/'; // You can replace '/' with the actual URL of your homepage
      },
    });
    } else {
      if (seconds === 0) {
        this.setState((prevState) => ({
          minutes: prevState.minutes - 1,
          seconds: 59,
        }));
      } else {
        this.setState((prevState) => ({ seconds: prevState.seconds - 1 }));
      }
    }
  };
  loadMoreItems = (dataLength) => {
    const { displayCount } = this.state;
    const additionalItems = 6; // Number of items to load each time

    this.setState({
      displayCount: dataLength,
      showLess: true,
    });
  };

  ModalHideShow()
  {
    this.setState({
      showLogin:!this.state.showLogin,
    })
  }

  showLessItems = (dataLength) => {
    const { displayCount } = this.state;
    const lessItems = 6; // Number of items to show when "Show Less" is clicked

    this.setState({
      displayCount: 6,
      showLess: false,
    });
  };
  countryChange(event, data) {
  

    this.setState({
      selectedCountry: event.target.value,
      //selectedCountryName:,
    });

  }

  countryIssueChange(event, data) {
  

    this.setState({
      selectedCountryIssue: event.target.value,
      //selectedCountryName:,
    });

  }

  addYears(date, years, type) {
    date.setFullYear(date.getFullYear() - years);
   
    return date;
  }

  componentDidMount() {
      //check whether User loggedIn or NOT...
   let userDetails=(localStorage.getItem("TM_UDetails"));
   let tokenValue=localStorage.getItem("tokenValue");

    if((userDetails==null || userDetails=="" || userDetails==undefined) && (tokenValue==null || tokenValue=="" || tokenValue==undefined))
   {
    this.setState({
      isLogin: false,
    });
   
   }
   else
   {
    this.setState({
      isLogin: true,
    });
  
   }

      //GET INDIAN COUNTRIES...
      GetCountries().then((response) => {
     
      if(response.data.status)
      {
        this.setState({
          Countries:response.data.objCountry, 
          //selectedCountry:101
        })
      }
    })


    const params = new URLSearchParams(window.location.search);

    // this.setState({
    //   years:range(1990, getYear(new Date()) + 1, 1),
    // })
    this.setState({

      isLoaded:false,
      checkoutRequest: params.get('checkoutRequest'),
      IRTcheckoutRequest: params.get('IRTcheckoutRequest'),

    });
    if (params.get('checkoutRequest') !== null) {
      const checkoutRequest = params.get('checkoutRequest');
   
      // Same secret key used for encryption
      const secretKey = '123';

      // Decrypt the encrypted data
      const decryptedData = AES.decrypt(checkoutRequest, secretKey).toString(enc.Utf8);

      // Parse the decrypted data back to an object
      const parsedData = JSON.parse(decryptedData);

      //this.requestBody = JSON.parse(localStorage.getItem("onewayBookingResult"));
      //this.requestBody = JSON.parse(checkoutRequest);
      this.requestBody = parsedData; 
      this.getPricingDetailsOnewayResult();  
      //if (!localStorage.getItem('timerState')) {
        //this.getPricingDetailsOnewayResult();
      //}
      // else
      // {
      //   let responseBody=JSON.parse(localStorage.getItem("responseBody"));
      //   this.PricingResponse(responseBody);
      // }
       // Use setInterval to update the timer
    //this.interval = setInterval(this.updateTime, 1000);
     // Check if there is a timer value stored in local storage
    //  const storedTimer = localStorage.getItem('timerState');
    //  if (storedTimer) {
    //    const { minutes, seconds } = JSON.parse(storedTimer);
    //    this.setState({ minutes, seconds }, () => {
    //     this.interval = setInterval(this.updateTime, 1000); // Start the timer with the stored values
    //    });
    //  } else {
    //    this.interval = setInterval(this.updateTime, 1000); // Start the timer with the default values
    //  }
    this.interval = setInterval(this.updateTime, 1000);
    
    }

    if (params.get('IRTcheckoutRequest') !== null) {
      const IRTcheckoutRequest = params.get('IRTcheckoutRequest');
     
      // Same secret key used for encryption
      const secretKey = '123';

      // Decrypt the encrypted data
      const IRTdecryptedData = AES.decrypt(IRTcheckoutRequest, secretKey).toString(enc.Utf8);

      // Parse the decrypted data back to an object
      const IRTparsedData = JSON.parse(IRTdecryptedData);
    

      //this.requestBody = JSON.parse(localStorage.getItem("onewayBookingResult"));
      //this.requestBody = JSON.parse(checkoutRequest);
      //this.requestBody1 = JSON.parse(localStorage.getItem("IRTBookingResult"));
      this.requestBody1 = IRTparsedData;
    
      this.getPricingDetailsIrtResult();

    }



  }

  componentWillUnmount() {
    // Clear the interval to prevent memory leaks
    clearInterval(this.interval);
    //localStorage.removeItem('timerState');
    //localStorage.removeItem('responseBody');
    this.setState({
      minutes:5,
      seconds:0,
    })
  }

  // componentDidUpdate() {
  //     // Create a timer state object to store in localStorage
  // const timerStateToStore = {
  //   minutes: this.state.minutes,
  //   seconds: this.state.seconds,
  // };

  // // Save the timer state when the component unmounts
  // localStorage.setItem('timerState', JSON.stringify(timerStateToStore));
  // }
 
  getPricingDetailsOnewayResult() {

    GetPricingDetails(this.requestBody).then((response) => {

      //console.log(response.data);
      if(response.data.travelType != "I")
      {
        //console.log("Domestic");
          this.setState({
            isPassportMandatory :false,
          })
      }
      //localStorage.setItem('responseBody', JSON.stringify(response));

     if(response.data.departureDate != null || response.data.departureDate != "")
     {
      this.setState({
        departureDate : response.data.departureDate,
        ChildDobStartDate : (new Date(response.data.departureDate)).getFullYear - 2,
       });
     }
     else
     {
      this.setState({
        departureDate : new Date(),
        ChildDobStartDate : (new Date(response.data.departureDate)).getFullYear - 2,
       });
     }




     //console.log(new Date());
    // let d1=moment(response.data.objApiResponse.departureDate).format("YYYY/MM/DD ").getYear();
    //  console.log(d1);

    //  const dateObject = new Date(d1);
    //  console.log(dateObject);


      let ChildDateByDeptDate1= new Date(response.data.objApiResponse.objSegList[0].departureDate)
     this.setState({
      ChildDateByDeptDate : moment(response.data.objApiResponse.departureDate).format("YYYY/MM/DD ")
     })
      if (response.data.status) {

        const {
          objSegList,
          taxList,
          objAdtPaxList,
          objChdPaxList,
          objInfPaxList,
        } = response.data.objApiResponse;
      

        // Onward OR Return Arrival Date for passport Expiry date validation...
        let segData=response.data.objApiResponse.objSegList;
        let DestinationArrivalTime;
        if(segData.length==1)
            DestinationArrivalTime=segData[0].arrivalDate;
        else
            DestinationArrivalTime=segData[1].arrivalDate;       

        this.setState({
          DestinationArrivalTime:dateFormat(DestinationArrivalTime,"yyyy-mm-dd")
        })

        // COntactnumber and EMail Validation Clearing...
        let validationCommon = this.state.CommonFieldValidations;
        let contactToSent = this.state.BookRequestBody;
        if(response.data.travellerContact != "" || response.data.travellerContact!= null)
        {
          validationCommon.contactNumber = true;
          contactToSent.contactNumber = response.data.travellerContact;
          this.setState({
            contactNumber : response.data.travellerContact,
            BookRequestBody : contactToSent,
            CommonFieldValidations : validationCommon,
          })          
        }
        if(response.data.travellerEmail != "" || response.data.travellerEmail!= null)
        {
          validationCommon.contactEmail = true;
          contactToSent.contactEmail = response.data.travellerEmail;
          this.setState({
            contactEmail : response.data.travellerEmail,
            BookRequestBody : contactToSent,
            CommonFieldValidations : validationCommon,
          })          
        }
        

        this.setState({
          objseglistResult: objSegList,
          TaxListResult: taxList,
          ApiPricingResponse: response.data.objApiResponse,
          AdtpricingPaxListResult: objAdtPaxList,
          ChdpricingPaxListResult: objChdPaxList,
          InfpricingPaxListResult: objInfPaxList,
          PricingDetailsResult: response.data,
          //RequestFields: this.requestBody,
          RePricingPaxlist: null,
          selectedCountry: response.data.countryId,
          selectedCountryIssue: response.data.countryId,
          isLoaded: true,
        });
      
        //Meals Baggage Checkbox and Dates Array based on Sector....Adult
        let adultData1=[];
        let ValidationArray=[];
        let AdultDatePickerArray=[];
        for(let i=0;i<objAdtPaxList.length; i++)
        {
          adultData1.push({ "adult" : i });
          
          AdultDatePickerArray.push({
            "adult" : i,
            "dob" : "",
            "doe" : ""
          });
         
        }



        let data123=[];
        for(let i=0; i < adultData1.length; i++)
        {     
        
          let letMealData=[];
          let maxArray=0;
          if(objAdtPaxList[i].objmealseglist.length > objAdtPaxList[i].objbaggageseglist.length)
            maxArray=objAdtPaxList[i].objmealseglist.length
          else
            maxArray=objAdtPaxList[i].objbaggageseglist.length

          for(let j=0; j < maxArray; j++)
          {           
            letMealData.push({"index": j, "value": "", "baggage":""})            
          }
          adultData1[i].data=letMealData;
       
        }      
        this.setState({ selectedCheckbox: adultData1,
                        AdultDatesArray: AdultDatePickerArray,
                        
                      });


          //Meals Baggage Checkbox Array based on Sector....Child
          let childData1=[];
          let ChildDatePickerArray=[];
          for(let i=0;i<objChdPaxList.length; i++)
          {
            childData1.push({ "child" : i });
            ChildDatePickerArray.push({"child" : i, "dob" : "", "doe" : ""});
          }
  
          let data123Child=[];          
          for(let i=0; i < childData1.length; i++)
          {
            let letMealData=[];
            let maxArray=0;
            if(objChdPaxList[i].objmealseglist.length > objChdPaxList[i].objbaggageseglist.length)
              maxArray=objChdPaxList[i].objmealseglist.length
            else
              maxArray=objChdPaxList[i].objbaggageseglist.length

            for(let j=0; j < maxArray; j++)
            {
              letMealData.push({"index": j, "value": "", "baggage":""})              
            }
            childData1[i].data=letMealData;
           
          }         
          this.setState({ selectedCheckboxChild: childData1,
                            ChildDatesArray : ChildDatePickerArray});

          let InfantDatePickerArray=[];
          for(let i=0;i<objInfPaxList.length; i++)
          {
            InfantDatePickerArray.push({"infant" : i, "dob" : "", "doe" : ""});
          }
          
          this.setState({
            InfantDatesArray : InfantDatePickerArray,
          })

        //pricing request Body...
        let adultData = [];

        for (let i = 0; i < response.data.adult; i++) {
        
          adultData.push({ paxKey: objAdtPaxList[i].paxKey });
        }


        let childData = [];
        for (let i = 0; i < response.data.child; i++) {
          childData.push({ paxKey: objChdPaxList[i].paxKey });
        }

        let infantData = [];
        for (let i = 0; i < response.data.infant; i++) {
          infantData.push({ paxKey: objInfPaxList[i].paxKey });
        }
        this.setState({
          objAdtPaxList: adultData,
          objchdPaxList: childData,
          objinfPaxList: infantData,
        })


        //Setting paxdetails List...
        let adultListToSet= objAdtPaxList;
        let childListToSet= objChdPaxList;
        let infantListToSet= objInfPaxList;
        let allPaxList=[];

        for(let j=0; j<adultListToSet.length; j++)
        {
          allPaxList.push({
            "paxKey":adultListToSet[j].paxKey,
            "paxType":"adult",
            "nationality":"IN",
            "countryofIssue":"IN"
          })

          //Passport mandatory or NOt....
          let ValidationSettingsAdult;
          if(this.state.isPassportMandatory)
          {
            ValidationSettingsAdult={
              "adult" : j ,
              "paxKey":adultListToSet[j].paxKey,
              "firstName":false,
              "lastName":false,
              "dateOfExpiry":false,
              "dateofBirth":false,
              "documentNumber":false,
              "title":false,
            }
          }
          else
          {
            ValidationSettingsAdult={
              "adult" : j ,
              "paxKey":adultListToSet[j].paxKey,
              "firstName":false,
              "lastName":false,
              "dateOfExpiry":true,
              "dateofBirth":true,
              "documentNumber":true,
              "title":false,
            }
          }

          ValidationArray.push(ValidationSettingsAdult);
        }

        this.setState({
          FieldValidations:ValidationArray
        })
        
        let ValidationArrayChd=[];
        for(let j=0; j<childListToSet.length; j++)
        {
          allPaxList.push({
            "paxKey":childListToSet[j].paxKey,
            "paxType":"child",
            "nationality":"IN",
            "countryofIssue":"IN"
          })

           //Passport mandatory or NOt....
           let ValidationSettingsChild;
           if(this.state.isPassportMandatory)
           {
              ValidationSettingsChild = {
                "child" : j ,
                "paxKey":childListToSet[j].paxKey,
                "firstName":false,
                "lastName":false,
                "dateOfExpiry":false,
                "dateofBirth":false,
                "documentNumber":false,
                "title":false,
              }
          }
          else
          {
              ValidationSettingsChild = {
                "child" : j ,
                "paxKey":childListToSet[j].paxKey,
                "firstName":false,
                "lastName":false,
                "dateOfExpiry":true,
                "dateofBirth":false,
                "documentNumber":true,
                "title":false,
              }
          }
          ValidationArrayChd.push(ValidationSettingsChild);
        }

        this.setState({
          ChildFieldValidations:ValidationArrayChd
        })

        let ValidationArrayInf=[];
        for(let j=0; j<infantListToSet.length; j++)
        {
          allPaxList.push({
            "paxKey":infantListToSet[j].paxKey,
            "paxType":"infant",
            "nationality":"IN",
            "countryofIssue":"IN"
          })

          //Passport mandatory or NOt....
          let ValidationSettingsInfant;
          if(this.state.isPassportMandatory)
          {
              ValidationSettingsInfant = {
                "infant" : j ,
                "paxKey":infantListToSet[j].paxKey,
                "firstName":false,
                "lastName":false,
                "dateOfExpiry":false,
                "dateofBirth":false,
                "documentNumber":false,
                "title":false,
              }
          }
          else
          {
              ValidationSettingsInfant = {
                "infant" : j ,
                "paxKey":infantListToSet[j].paxKey,
                "firstName":false,
                "lastName":false,
                "dateOfExpiry":true,
                "dateofBirth":false,
                "documentNumber":true,
                "title":false,
            }
          }
          ValidationArrayInf.push(ValidationSettingsInfant);

        }

        this.setState({
          InfantFieldValidations:ValidationArrayInf
        })
       this.setState({
        allPaxDataList:allPaxList,
        
       })
      }
      else
      {
        this.setState({
          isFailed: true,
          isLoaded: true,
        })
      }
    })
      .catch((error) => {      
        // console.log("SDfsdfdsf");
        if(error.response.status == 403){
          this.props.navigate('/issue');
        }
        else if (error.response && error.response.status === 500) {
          toast.error(`An internal server error occurred`, {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
            onClose: () => {
              // Redirect to the previous page
              window.history.back();
            },
        });
        }
        else if (error.response.data.responseMessage == "InvalidToken") {        
          this.props.navigate('/');
        }
      });
  }

  PricingResponse(response) {
    if (response.data.status) {
      const {
        objSegList,
        taxList,
        objAdtPaxList,
        objChdPaxList,
        objInfPaxList,
      } = response.data.objApiResponse;
    

      // Onward OR Return Arrival Date for passport Expiry date validation...
      let segData=response.data.objApiResponse.objSegList;
      let DestinationArrivalTime;
      if(segData.length==1)
          DestinationArrivalTime=segData[0].arrivalDate;
      else
          DestinationArrivalTime=segData[1].arrivalDate;       

      this.setState({
        DestinationArrivalTime:dateFormat(DestinationArrivalTime,"yyyy-mm-dd")
      })

      this.setState({
        objseglistResult: objSegList,
        TaxListResult: taxList,
        ApiPricingResponse: response.data.objApiResponse,
        AdtpricingPaxListResult: objAdtPaxList,
        ChdpricingPaxListResult: objChdPaxList,
        InfpricingPaxListResult: objInfPaxList,
        PricingDetailsResult: response.data,
        //RequestFields: this.requestBody,
        RePricingPaxlist: null,
        selectedCountry: response.data.countryId,
        selectedCountryIssue: response.data.countryId,
        isLoaded: true,
      });
    
      //Meals Baggage Checkbox and Dates Array based on Sector....Adult
      let adultData1=[];
      let ValidationArray=[];
      let AdultDatePickerArray=[];
      for(let i=0;i<objAdtPaxList.length; i++)
      {
        adultData1.push({ "adult" : i });
        
        AdultDatePickerArray.push({
          "adult" : i,
          "dob" : "",
          "doe" : ""
        });
       
      }



      let data123=[];
      for(let i=0; i < adultData1.length; i++)
      {     
      
        let letMealData=[];
        let maxArray=0;
        if(objAdtPaxList[i].objmealseglist.length > objAdtPaxList[i].objbaggageseglist.length)
          maxArray=objAdtPaxList[i].objmealseglist.length
        else
          maxArray=objAdtPaxList[i].objbaggageseglist.length

        for(let j=0; j < maxArray; j++)
        {           
          letMealData.push({"index": j, "value": "", "baggage":""})            
        }
        adultData1[i].data=letMealData;
     
      }      
      this.setState({ selectedCheckbox: adultData1,
                      AdultDatesArray: AdultDatePickerArray,
                      
                    });


        //Meals Baggage Checkbox Array based on Sector....Child
        let childData1=[];
        let ChildDatePickerArray=[];
        for(let i=0;i<objChdPaxList.length; i++)
        {
          childData1.push({ "child" : i });
          ChildDatePickerArray.push({"child" : i, "dob" : "", "doe" : ""});
        }

        let data123Child=[];          
        for(let i=0; i < childData1.length; i++)
        {
          let letMealData=[];
          let maxArray=0;
          if(objChdPaxList[i].objmealseglist.length > objChdPaxList[i].objbaggageseglist.length)
            maxArray=objChdPaxList[i].objmealseglist.length
          else
            maxArray=objChdPaxList[i].objbaggageseglist.length

          for(let j=0; j < maxArray; j++)
          {
            letMealData.push({"index": j, "value": "", "baggage":""})              
          }
          childData1[i].data=letMealData;
         
        }         
        this.setState({ selectedCheckboxChild: childData1,
                          ChildDatesArray : ChildDatePickerArray});

        let InfantDatePickerArray=[];
        for(let i=0;i<objInfPaxList.length; i++)
        {
          InfantDatePickerArray.push({"infant" : i, "dob" : "", "doe" : ""});
        }
        
        this.setState({
          InfantDatesArray : InfantDatePickerArray,
        })

      //pricing request Body...
      let adultData = [];

      for (let i = 0; i < response.data.adult; i++) {
      
        adultData.push({ paxKey: objAdtPaxList[i].paxKey });
      }


      let childData = [];
      for (let i = 0; i < response.data.child; i++) {
        childData.push({ paxKey: objChdPaxList[i].paxKey });
      }

      let infantData = [];
      for (let i = 0; i < response.data.infant; i++) {
        infantData.push({ paxKey: objInfPaxList[i].paxKey });
      }
      this.setState({
        objAdtPaxList: adultData,
        objchdPaxList: childData,
        objinfPaxList: infantData,
      })


      //Setting paxdetails List...
      let adultListToSet= objAdtPaxList;
      let childListToSet= objChdPaxList;
      let infantListToSet= objInfPaxList;
      let allPaxList=[];

      for(let j=0; j<adultListToSet.length; j++)
      {
        allPaxList.push({
          "paxKey":adultListToSet[j].paxKey,
          "paxType":"adult",
          "nationality":"IN",
          "countryofIssue":"IN"
        })

        //Passport mandatory or NOt....
        let ValidationSettingsAdult;
        if(this.state.isPassportMandatory)
        {
          ValidationSettingsAdult={
            "adult" : j ,
            "paxKey":adultListToSet[j].paxKey,
            "firstName":false,
            "lastName":false,
            "dateOfExpiry":false,
            "dateofBirth":false,
            "documentNumber":false,
            "title":false,
          }
        }
        else
        {
          ValidationSettingsAdult={
            "adult" : j ,
            "paxKey":adultListToSet[j].paxKey,
            "firstName":false,
            "lastName":false,
            "dateOfExpiry":true,
            "dateofBirth":true,
            "documentNumber":true,
            "title":false,
          }
        }

        ValidationArray.push(ValidationSettingsAdult);
      }

      this.setState({
        FieldValidations:ValidationArray
      })
      
      let ValidationArrayChd=[];
      for(let j=0; j<childListToSet.length; j++)
      {
        allPaxList.push({
          "paxKey":childListToSet[j].paxKey,
          "paxType":"child",
          "nationality":"IN",
          "countryofIssue":"IN"
        })

         //Passport mandatory or NOt....
         let ValidationSettingsChild;
         if(this.state.isPassportMandatory)
         {
            ValidationSettingsChild = {
              "child" : j ,
              "paxKey":childListToSet[j].paxKey,
              "firstName":false,
              "lastName":false,
              "dateOfExpiry":false,
              "dateofBirth":false,
              "documentNumber":false,
              "title":false,
            }
        }
        else
        {
            ValidationSettingsChild = {
              "child" : j ,
              "paxKey":childListToSet[j].paxKey,
              "firstName":false,
              "lastName":false,
              "dateOfExpiry":true,
              "dateofBirth":false,
              "documentNumber":true,
              "title":false,
            }
        }
        ValidationArrayChd.push(ValidationSettingsChild);
      }

      this.setState({
        ChildFieldValidations:ValidationArrayChd
      })

      let ValidationArrayInf=[];
      for(let j=0; j<infantListToSet.length; j++)
      {
        allPaxList.push({
          "paxKey":infantListToSet[j].paxKey,
          "paxType":"infant",
          "nationality":"IN",
          "countryofIssue":"IN"
        })

        //Passport mandatory or NOt....
        let ValidationSettingsInfant;
        if(this.state.isPassportMandatory)
        {
            ValidationSettingsInfant = {
              "infant" : j ,
              "paxKey":infantListToSet[j].paxKey,
              "firstName":false,
              "lastName":false,
              "dateOfExpiry":false,
              "dateofBirth":false,
              "documentNumber":false,
              "title":false,
            }
        }
        else
        {
            ValidationSettingsInfant = {
              "infant" : j ,
              "paxKey":infantListToSet[j].paxKey,
              "firstName":false,
              "lastName":false,
              "dateOfExpiry":true,
              "dateofBirth":false,
              "documentNumber":true,
              "title":false,
          }
        }
        ValidationArrayInf.push(ValidationSettingsInfant);

      }

      this.setState({
        InfantFieldValidations:ValidationArrayInf
      })
     this.setState({
      allPaxDataList:allPaxList,
      
     })
    }
    else
    {
      this.setState({
        isFailed: true,
        isLoaded: true,
      })
    }
    // Test...
  }

  

  getPricingDetailsIrtResult() {
    GetPricingDetailsIR(this.requestBody1).then((response) => {   
      //console.log(response.data) 
      if (response.data.status) {      
        this.setState({

          objseglistResult: response.data.objSegList,
          TaxListResult: response.data.taxList,
          //chdTaxListListResult: response.data.chdTaxList,
          AdtpricingPaxListResult: response.data.objAdtPaxList,
          ChdpricingPaxListResult: response.data.objChdPaxList,
          InfpricingPaxListResult: response.data.objInfPaxList,
          PricingDetailsResult: response.data,
          //RequestFields: this.requestBody1,
          RePricingPaxlist: null,
          selectedCountry: response.data.countryId,
          selectedCountryIssue: response.data.countryId,
        });
      
        //pricing request Body...
        let adultData = [];

        for (let i = 0; i < response.data.adult; i++) {       
          adultData.push({ paxKey: response.data.objAdtPaxList[i].paxKey });
        }


        let childData = [];
        for (let i = 0; i < response.data.child; i++) {
          childData.push({ paxKey: response.data.objChdPaxList[i].paxKey });
        }

        let infantData = [];
        for (let i = 0; i < response.data.infant; i++) {
          infantData.push({ paxKey: response.data.objInfPaxList[i].paxKey });
        }
        this.setState({
          objAdtPaxList: adultData,
          objchdPaxList: childData,
          objinfPaxList: infantData,
        })
      }
    })
      .catch((error) => {
       
        if (error.response.data.responseMessage == "InvalidToken") {         
          this.props.navigate('/');
        }
      });
    // }

  }

  getRePricingDetailsResult(id, fare, code, idR, fareR, codeR, list) {
       
    //Check network connection...
    if(!CheckNetworkStatus())
        return;

    const { contactNumber, contactEmail } = this.state;

    let AllFieldOk=false;

    this.setState({
      isFormSubmitted:true,
    })

       //Basic Vaidation without Passport details....
       let isBasicValidity = IsBasicValidationSuccess(this.state.FieldValidations, this.state.ChildFieldValidations,this.state.InfantFieldValidations, this.state.isPassportMandatory, this.state.CommonFieldValidations);

       if(!isBasicValidity)
         return;   // Return IF not Valid...

         let paxAllDAta=this.state.allPaxDataList;
         let duplicateNames = paxNameDuplicationCheck(paxAllDAta);

      if (duplicateNames.length > 0) {
        toast.error(`Duplicate names exist: ${duplicateNames.join(', ')}`, {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        });
      }
      else
      {
        if (contactNumber === "") {
          this.setState({
            isRequiredContactNumber: true,
            errorMessageContactNumber: "Contact Number is required.",
            show: false,
          });
        } else {
          this.setState({
            isRequiredContactNumber: false,
            errorMessageContactNumber: "",
           
          });
        }
        if (contactEmail === "") {
          this.setState({
            isRequiredContactEmail: true,
            errorMessageContactEmail: "Contact Email is required.",
            show: false,
          });
        } else {
          this.setState({
            isRequiredContactEmail: false,
            errorMessageContactEmail: "",
            
          });
        }

        let datas={};

    
    datas = Object.assign({}, this.state.BookRequestBody);
   
        
    datas.contactNumber=this.state.prefixContactNumber + this.state.BookRequestBody.contactNumber;
    datas.contactEmail=this.state.BookRequestBody.contactEmail;
   

      let selectedMealsAndBaggage=this.state.objAdtPaxList;
    let paxAllDAta=this.state.allPaxDataList;

    //Adult meals & baggage
    for(let i=0; i< selectedMealsAndBaggage.length; i++)
    {
      for(let j=0; j<paxAllDAta.length; j++)
      {
        if(selectedMealsAndBaggage[i].paxKey==paxAllDAta[j].paxKey)
        {
          let item=paxAllDAta[j];
          item.objBaggage=selectedMealsAndBaggage[i].objBaggage

          item.objMealList=selectedMealsAndBaggage[i].objMealList;
          paxAllDAta[j]=item;
          break;
        }
      }      
    }

    let chldselectedMealsAndBaggage=this.state.objchdPaxList;
    //Child meals & baggage
    for(let i=0; i< chldselectedMealsAndBaggage.length; i++)
    {
      for(let j=0; j<paxAllDAta.length; j++)
      {
        if(chldselectedMealsAndBaggage[i].paxKey==paxAllDAta[j].paxKey)
        {
          let item=paxAllDAta[j];
          item.objBaggage=chldselectedMealsAndBaggage[i].objBaggage

          item.objMealList=chldselectedMealsAndBaggage[i].objMealList;
          paxAllDAta[j]=item;        
          break;
        }
      }      
    }
    
    this.setState({
      allPaxDataList:paxAllDAta
    })
    /////////
    datas.objPaxList=this.state.allPaxDataList

    
        const data = {
          itinId: id,
          fareId: fare,
          providerCode: code,
          itinIdR: idR,
          fareIdR: fareR,
          providerCodeR: codeR,
          objAdtPaxList: this.state.objAdtPaxList,
          objChdPaxList: this.state.objchdPaxList,
          objInfPaxList: this.state.objinfPaxList,
          contactNumber: datas.contactNumber,
          contactEmail: datas.contactEmail,
          objPaxList : this.state.allPaxDataList,
        };
      
        GetRePricingDetails(data).then((response) => {    
          if (response.data.status) {       
            this.setState({
              ReobjseglistResult: response.data.objSegList,
              ReTaxListResult: response.data.taxList,
              RePricingDetailsResult: response.data,
              RequestFields: data,
              show : true,
            });
          
          }
          else
          {
            this.setState({
              show : false,
            });
            toast.success('Something Went wrong', {
              position: 'bottom-center',
              autoClose: 5000, 
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
              
            });
            
          }
        })
          .catch((error) => {
          
            if (error.response.data.responseMessage == "InvalidToken") {
              // Swal.fire(
              //   'Unauthorized Access !',
              //   'Please Login Again',
              //   'warning'
              // )
    
              this.props.navigate('/');
    
            }
          });
        // }
      }
      
    

   

  }

  handleContactNumberChange = (e) => {
    // const inputValue = e.target.value;
    // const digitsOnly = inputValue.replace(/\D/g, "").slice(0, 10);
    // this.setState({ contactNumber: digitsOnly });

    // if (digitsOnly.length !== 10) {
    //   this.setState({ error: "Contact number must be 10 digits" });
    // } else {
    //   this.setState({ error: "" });
    // }
    const { value } = e.target;
    this.inputChangeHandler(e); 
    this.setState({
      contactNumber: value,
      errorMessageContactNumber: "",
    });
  };

  EnterBtnSubmit(event, type) {
    
    if(event.key=="Enter")
      if(type=="mob")
        this.LoginOTP();
      else
        this.SubmitOTP();

  }
  
  handleContactEmailChange = (e) => {
    const { value } = e.target;
    this.inputChangeHandler(e); 
    this.setState({
      contactEmail: value,
      errorMessageContactEmail: "",
    });
  };
  // getRePricingDetailsIRResult(id, fare, code,idR, fareR, codeR, list) {
  //   const data = {
  //     itinId: id,
  //     fareId: fare,
  //     providerCode: code,
  //     itinIdR: idR,
  //     fareIdR: fareR,
  //     providerCodeR: codeR,
  //     objAdtPaxList: this.state.objAdtPaxList,
  //     objChdPaxList: this.state.objchdPaxList,
  //     objInfPaxList:this.state.objinfPaxList
  //   };


  //   GetRePricingDetailsIR(data).then((response) => {
  //     if (response.data.status) {
  //       this.setState({


  //         ReobjseglistResult: response.data.objSegList,
  //         ReTaxListResult: response.data.taxList,
  //         //chdTaxListListResult: response.data.chdTaxList,
  //         //AdtpricingPaxListResult: response.data.objAdtPaxList,
  //         //ChdpricingPaxListResult: response.data.objChdPaxList,
  //         //InfpricingPaxListResult: response.data.objInfPaxList,
  //         RePricingDetailsResult: response.data,
  //         //isLoaded: true,
  //       });
  //     }
  //   })
  //   .catch((error) => {
  //     if(error.response.data.responseMessage=="InvalidToken")
  //     {
  //       Swal.fire(
  //         'Unauthorized Access !',
  //         'Please Login Again',
  //         'warning'
  //       )

  //       this.props.navigate('/');

  //     }
  //   });
  //   // }
  
  // }
  CommonFieldCheck(event)
  {
    let data = CommonFieldCheckValidation(event, this.state.CommonFieldValidations);
    this.setState({
      CommonFieldValidations:data,
    })
  }

  CheckValidationByFunction(event, index, type, fieldName, FieldArray, allPax, paxType)
  {
    
    // if(type=="dNumber" && !this.state.isPassportMandatory){
    //   let fieldsReset;
    //   if(paxType=="Adt")
    //   {
    //     fieldsReset=this.state.FieldValidations;

    //     if(event.target.value!="")
    //     {          
    //       fieldsReset[index].documentNumber=false;
    //       fieldsReset[index].dateofBirth=false;
    //       fieldsReset[index].dateOfExpiry=false;
    //     }
    //     else
    //     {
          
    //       fieldsReset[index].documentNumber=true;
    //       fieldsReset[index].dateofBirth=true;
    //       fieldsReset[index].dateOfExpiry=true;
    //     }
        

    //     this.setState({
    //       FieldValidations:fieldsReset
    //     })

    //   }
    //   else if(paxType=="Chd")
    //   {
    //     fieldsReset=this.state.ChildFieldValidations;
        
    //     if(event.target.value!="")
    //     {          
    //       fieldsReset[index].documentNumber=false;
    //       fieldsReset[index].dateofBirth=false;
    //       fieldsReset[index].dateOfExpiry=false;
    //     }
    //     else
    //     {
          
    //       fieldsReset[index].documentNumber=true;
    //       fieldsReset[index].dateofBirth=true;
    //       fieldsReset[index].dateOfExpiry=true;
    //     }
        

    //     this.setState({
    //       ChildFieldValidations:fieldsReset
    //     })
    //   }
    //   else if(paxType=="Inf")
    //   {
    //     fieldsReset=this.state.InfantFieldValidations;
       
    //     if(event.target.value!="")
    //     {          
    //       fieldsReset[index].documentNumber=false;
    //       fieldsReset[index].dateofBirth=false;
    //       fieldsReset[index].dateOfExpiry=false;
    //     }
    //     else
    //     {
         
    //       fieldsReset[index].documentNumber=true;
    //       fieldsReset[index].dateofBirth=true;
    //       fieldsReset[index].dateOfExpiry=true;
    //     }
        

    //     this.setState({
    //       InfantFieldValidations:fieldsReset
    //     })
    //   }      
    // }

    let data=CheckValidation(event, index, type, fieldName, FieldArray, allPax, this.state.isPassportMandatory);
 
      if(paxType=="Adt")
      {
        this.setState({
          FieldValidations:data,
        })
      }      
      else if(paxType=="Chd")
      {
        this.setState({
          ChildFieldValidations:data,
        })
      }
      else if(paxType=="Inf")
      {
        this.setState({
          InfantFieldValidations:data,
        })
      }

     

  }

  // CheckValidation(event, index, type, fieldName)
  // {
  //   let FieldArray = this.state.FieldValidations;
  //   let allPax=this.state.allPaxDataList;
  //   for(let i=0; i < allPax.length; i++)
  //   {
  //     if(allPax[i].paxKey==FieldArray[index].paxKey)
  //     {
  //       if(type=="dateType"){
  //         if(Required(allPax[i][type]))
  //         {
  //           FieldArray[index][fieldName]=true;
  //         }
  //         else
  //         {
  //           FieldArray[index][fieldName]=false;
  //         }
  //       }
  //       else{
  //       if(event.target.name=="documentNumber")
  //       {
  //         if(isValidatePassport(allPax[i][event.target.name]))
  //         {
  //           FieldArray[index][event.target.name]=true;
  //         }
  //         else
  //         {
  //           FieldArray[index][event.target.name]=false;
  //         }
  //       }
  //       else
  //       {
  //         if(Required(allPax[i][event.target.name]))
  //         {
  //           FieldArray[index][event.target.name]=true;
  //         }
  //         else
  //         {
  //           FieldArray[index][event.target.name]=false;
  //         }
  //       }
  //     }
  //     }
  //   }
    
  //   //reStore New ValidationStatus...
  //   this.setState({
  //     FieldValidations:FieldArray,      
  //   })
  // }

  //Travellers details setting...
  adultTravellerMealsDetailsChange(event, index, item, item1, paxKey, sectorCode)
  {   
  //   let selectedMealIndex = this.state.selectedMealIndex;

  // if (event.target.checked) {
  //   selectedMealIndex = index;
  // } else {
  //   selectedMealIndex = -1; // Reset the selected value to zero
  // }

  // this.setState({ selectedMealIndex });
 
    let adultAllDetails=this.state.objAdtPaxList;
   
    let isExist=false, isMealExist=false;
    for(let i=0; i < adultAllDetails.length; i++)
    {
      if(adultAllDetails[i].paxKey==paxKey)
      {        
        isExist=true
        let data={};
        data.name=item.name;
        data.amount=item.amount;
        data.key=item.code;
        data.segmentCode=item1.sectorCode;
        data.tripMode=item1.tripMode;

        let mealsArray=adultAllDetails[i].objMealList;
      
        if(adultAllDetails[i].hasOwnProperty("objMealList"))
        {
        
          for(let j = 0 ;j < mealsArray.length; j++)
          {          
            if(mealsArray[j].segmentCode==sectorCode)
            {
              isMealExist=true;
              if(!event.target.checked)
              {
                mealsArray.splice(j, 1);
              
                adultAllDetails[i].objMealList=mealsArray;

                this.setState({
                  objAdtPaxList:adultAllDetails
                })

                return;
              }
                   
              let mealListArray=[];
              let mealAmountArray=[];
              mealListArray.push(data)
              mealAmountArray.push(data.amount)
              adultAllDetails[i].objMealList=mealListArray;
            }
          }
          if(!isMealExist)
          {          
            mealsArray.push(data);          
            adultAllDetails[i].objMealList=mealsArray            
          }
        }
        else
        {
          let mealListArray=[];
          let mealAmountArray=[];
          mealListArray.push(data)
          mealAmountArray.push(data.amount)
          adultAllDetails[i].objMealList=mealListArray;
        }

        //state...
        this.setState({
          objAdtPaxList:adultAllDetails
        })

      }
    }

    //IF no such adult in Array...
    if(!isExist)
    {
      let mealData={};
      mealData.name=item.name;
      mealData.amount=item.amount;
      mealData.key=item.code;
      mealData.segmentCode=item1.sectorCode;
      mealData.tripMode=item1.tripMode;
      
      let mealArray=[];
      mealArray.push(mealData);

      let data={};
      data.paxKey=paxKey;
      data.objMealList=mealArray;

      adultAllDetails.push(data);   
     
      //state...
      this.setState({
        objAdtPaxList:adultAllDetails
      })
    }

    //Meals Amount Total....
    let mealAmountArray=this.state.allPaxMealsAmount;
    let isPaxAlreadySelected=false;
    if(mealAmountArray.length>0)
    for(let i=0; i< mealAmountArray.length; i++)
    {     
      if(mealAmountArray[i].paxKey==paxKey && mealAmountArray[i].sectorCode==sectorCode)
      {
        isPaxAlreadySelected=true;
        mealAmountArray[i].amount=item.amount;     
        this.setState({
          allPaxMealsAmount:mealAmountArray
        })       
        break;   
      }
    }
    if(!isPaxAlreadySelected)
    {
      let newItem={}
      newItem.paxKey=paxKey;
      newItem.sectorCode=sectorCode;
      newItem.amount=item.amount;
      mealAmountArray.push(newItem)
      this.setState({
        allPaxMealsAmount:mealAmountArray
      })
    
      
    }

    let total=parseInt(this.state.allPaxBaggageAmount.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0)) + parseInt(this.state.allPaxMealsAmount.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0));

    this.setState({
      sumMealAnBaggage:total
    })
  
  }

  removeMealAmount(event, paxKey, sectorCode)
  {
    let mealAmountArray=this.state.allPaxMealsAmount;
  
    let tempArray=[];
    for(let i=0; i< mealAmountArray.length; i++)
    {
     
      if(mealAmountArray[i].paxKey==paxKey && mealAmountArray[i].sectorCode==sectorCode)
      {
        // tempArray.push(mealAmountArray[i]);
      }
      else
      {
        tempArray.push(mealAmountArray[i]);
      }

    }
   
    this.setState({
      allPaxMealsAmount:tempArray
    })

    let total=parseInt(this.state.allPaxBaggageAmount.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0)) + parseInt(tempArray.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0));

    this.setState({
      sumMealAnBaggage:total
    })

  }

  removeBaggageAmount(event, paxKey, sectorCode)
  {
    let baggageAmountArray=this.state.allPaxBaggageAmount;
   
    let tempArray=[];
    for(let i=0; i< baggageAmountArray.length; i++)
    {
     
      if(baggageAmountArray[i].paxKey==paxKey && baggageAmountArray[i].sectorCode==sectorCode)
      {
        // tempArray.push(baggageAmountArray[i]);
      }
      else
      {
        tempArray.push(baggageAmountArray[i]);
      }

    }
   
    this.setState({
      allPaxBaggageAmount:tempArray
    })

    let total=parseInt(tempArray.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0)) + parseInt(this.state.allPaxMealsAmount.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0));

    this.setState({
      sumMealAnBaggage:total
    })

  }

  childTravellerMealsDetailsChange(event, index, item, item1, paxKey,sectorCode) {
    let chldAllDetails = this.state.objchdPaxList;
    let isExist = false, isMealExist=false;;
    for (let i = 0; i < chldAllDetails.length; i++) {
      if (chldAllDetails[i].paxKey == paxKey) {
        isExist = true
        let data = {};
        data.name = item.name;
        data.amount = item.amount;
        data.key = item.code;
        data.segmentCode = item1.sectorCode;
        data.tripMode = item1.tripMode;

        let mealsArray=chldAllDetails[i].objMealList;
       
        if(chldAllDetails[i].hasOwnProperty("objMealList"))
        {
          
          for(let j = 0 ;j < mealsArray.length; j++)
          {
            
            if(mealsArray[j].segmentCode==sectorCode)
            {
              
              isMealExist=true;
              let mealListArray=[];
              let mealAmountArray=[];
              mealListArray.push(data)
              mealAmountArray.push(data.amount)
              chldAllDetails[i].objMealList=mealListArray;
            }
          }
          if(!isMealExist)
          {            
            mealsArray.push(data);           
            chldAllDetails[i].objMealList=mealsArray
          
          }
        }
        else
        {
          let mealListArray=[];
          let mealAmountArray=[];
          mealListArray.push(data)
          mealAmountArray.push(data.amount)
          chldAllDetails[i].objMealList=mealListArray;
        }


        
     
        //state...
        this.setState({
          objchdPaxList: chldAllDetails
        })

      }
    }

    //IF no such adult in Array...
    if (!isExist) {
      let mealData = {};
      mealData.name = item.name;
      mealData.amount = item.amount;
      mealData.key = item.code;
      mealData.segmentCode = item1.sectorCode;
      mealData.tripMode = item1.tripMode;

      let mealArray = [];
      mealArray.push(mealData);

      let data = {};
      data.paxKey = paxKey;
      data.objMealList = mealArray;

      chldAllDetails.push(data);
     


      //state...
      this.setState({
        objchdPaxList: chldAllDetails
      })
    }

     //Meals Amount Total....
     let mealAmountArray=this.state.allPaxMealsAmount;
     let isPaxAlreadySelected=false;
     if(mealAmountArray.length>0)
     for(let i=0; i< mealAmountArray.length; i++)
     {
       if(mealAmountArray[i].paxKey==paxKey && mealAmountArray[i].sectorCode==sectorCode)
       {
         isPaxAlreadySelected=true;
         mealAmountArray[i].amount=item.amount;     
         this.setState({
           allPaxMealsAmount:mealAmountArray
         })
        
         break;   
       }
     }
     if(!isPaxAlreadySelected)
     {
       let newItem={}
       newItem.paxKey=paxKey;
       newItem.sectorCode=sectorCode;
       newItem.amount=item.amount;
       mealAmountArray.push(newItem)
       this.setState({
         allPaxMealsAmount:mealAmountArray
       })
      
       
     }

     let total=parseInt(this.state.allPaxBaggageAmount.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0)) + parseInt(this.state.allPaxMealsAmount.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0));

     this.setState({
      sumMealAnBaggage:total
    })
  
  }

  //Travellers Baggage setting...
  adultTravellerBaggageDetailsChange(event, index, item, item1, paxKey,sectorCode) {
    
    let adultAllDetails = this.state.objAdtPaxList;
    let isExist = false, isBaggageExist;
    for (let i = 0; i < adultAllDetails.length; i++) {
      if (adultAllDetails[i].paxKey == paxKey) {
        isExist = true
        let data = {};
        data.name = item.name;
        data.amount = item.amount;
        data.key = item.code;
        data.segmentCode = item1.sectorCode;
        data.tripMode = item1.tripMode;

        let baggageArray=adultAllDetails[i].objBaggage;
       
        if(adultAllDetails[i].hasOwnProperty("objBaggage"))
        {
          for(let j=0; j < baggageArray.length; j++)
          {
            if(baggageArray[j].segmentCode==sectorCode)
            {
             
              if(!event.target.checked)
              {
                let newBaggageArray=baggageArray.filter((baggage)=>{
                  if(baggage.segmentCode != sectorCode)
                    return 1;
                })
               
                adultAllDetails[i].objBaggage=newBaggageArray;
                //state...
                this.setState({
                  objAdtPaxList: adultAllDetails
                })
                return;
              }
              ///////
              isBaggageExist=true;
              let baggageListArray=[];
              baggageListArray.push(data);
              adultAllDetails[i].objBaggage=baggageListArray;
            }
          }
          if(!isBaggageExist)
          {          
            baggageArray.push(data);           
            adultAllDetails[i].objBaggage=baggageArray          
          }
        }
        else
        {
          let baggageListArray=[];         
          baggageListArray.push(data);         
          adultAllDetails[i].objBaggage=baggageListArray;
        }

        // let baggageListArray = [];
        // baggageListArray.push(data)
        // adultAllDetails[i].objBaggage = baggageListArray;

        //state...
        this.setState({
          objAdtPaxList: adultAllDetails
        })

      }
    }

    //IF no such adult in Array...
    if (!isExist) {
      let baggageData = {};
      baggageData.name = item.name;
      baggageData.amount = item.amount;
      baggageData.key = item.code;
      baggageData.segmentCode = item1.sectorCode;
      baggageData.tripMode = item1.tripMode;

      let baggageArray = [];
      baggageArray.push(baggageData);

      let data = {};
      data.paxKey = paxKey;
      data.objBaggageList = baggageArray;

      adultAllDetails.push(data);
    
      //state...
      this.setState({
        objAdtPaxList: adultAllDetails
      })
    }

    //Baggage Amount Total....
    let baggageAmountArray=this.state.allPaxBaggageAmount;
    let isPaxAlreadySelected=false;
    if(baggageAmountArray.length>0)
    for(let i=0; i< baggageAmountArray.length; i++)
    {
      if(baggageAmountArray[i].paxKey==paxKey && baggageAmountArray[i].sectorCode==sectorCode)
      {
        isPaxAlreadySelected=true;
        baggageAmountArray[i].amount=item.amount;     
        this.setState({
          allPaxBaggageAmount:baggageAmountArray
        })
        break;   
      }
    }
    if(!isPaxAlreadySelected)
    {
      let newItem={}
      newItem.paxKey=paxKey;
      newItem.sectorCode=sectorCode;
      newItem.amount=item.amount;
      baggageAmountArray.push(newItem)
      this.setState({
        allPaxBaggageAmount:baggageAmountArray
      })
   
    }
   let total=parseInt(this.state.allPaxBaggageAmount.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0)) + parseInt(this.state.allPaxMealsAmount.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0));
  
    this.setState({
      sumMealAnBaggage:total
    })
   
  }

  childTravellerBaggageDetailsChange(event, index, item, item1, paxKey,sectorCode) {

    let adultAllDetails = this.state.objchdPaxList;
    let isExist = false, isBaggageExist=false;
    for (let i = 0; i < adultAllDetails.length; i++) {
      if (adultAllDetails[i].paxKey == paxKey) {
        isExist = true
        let data = {};
        data.name = item.name;
        data.amount = item.amount;
        data.key = item.code;
        data.segmentCode = item1.sectorCode;
        data.tripMode = item1.tripMode;

        /////
          let baggageArray=adultAllDetails[i].objBaggage;
        if(adultAllDetails[i].hasOwnProperty("objBaggage"))
        {
          for(let j=0; j < baggageArray.length; j++)
          {
            if(baggageArray[j].segmentCode==sectorCode)
            {
               if(!event.target.checked)
               {
                 let newBaggageArray=baggageArray.filter((baggage)=>{
                   if(baggage.segmentCode != sectorCode)
                     return 1;
                 })
                
                 adultAllDetails[i].objBaggage=newBaggageArray;
                 //state...
                 this.setState({
                   objAdtPaxList: adultAllDetails
                 })
                 return;
               }
               ///////

              isBaggageExist=true;
              let baggageListArray=[];
              baggageListArray.push(data);
              adultAllDetails[i].objBaggage=baggageListArray;
            }
          }
          if(!isBaggageExist)
          {
           
            baggageArray.push(data);          
            adultAllDetails[i].objBaggage=baggageArray
          }
        }
        else
        {
          let baggageListArray=[];         
          baggageListArray.push(data);         
          adultAllDetails[i].objBaggage=baggageListArray;
        }

        /////

        // let baggageListArray = [];
        // baggageListArray.push(data)
        // adultAllDetails[i].objBaggage = baggageListArray;

        //state...
        this.setState({
          objchdPaxList: adultAllDetails
        })

      }
    }

    //IF no such adult in Array...
    if (!isExist) {
      let baggageData = {};
      baggageData.name = item.name;
      baggageData.amount = item.amount;
      baggageData.key = item.code;
      baggageData.segmentCode = item1.sectorCode;
      baggageData.tripMode = item1.tripMode;

      let baggageArray = [];
      baggageArray.push(baggageData);

      let data = {};
      data.paxKey = paxKey;
      data.objBaggageList = baggageArray;

      adultAllDetails.push(data);

      //state...
      this.setState({
        objchdPaxList: adultAllDetails
      })
    }

    //Baggage Amount Total....
    let baggageAmountArray=this.state.allPaxBaggageAmount;
    let isPaxAlreadySelected=false;
    if(baggageAmountArray.length>0)
    for(let i=0; i< baggageAmountArray.length; i++)
    {
      if(baggageAmountArray[i].paxKey==paxKey && baggageAmountArray[i].sectorCode==sectorCode)
      {
        isPaxAlreadySelected=true;
        baggageAmountArray[i].amount=item.amount;     
        this.setState({
          allPaxBaggageAmount:baggageAmountArray
        })       
        break;   
      }
    }
    if(!isPaxAlreadySelected)
    {
      let newItem={}
      newItem.paxKey=paxKey;
      newItem.sectorCode=sectorCode;
      newItem.amount=item.amount;
      baggageAmountArray.push(newItem)
      this.setState({
        allPaxBaggageAmount:baggageAmountArray
      })
    }

    let total=parseInt(this.state.allPaxBaggageAmount.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0)) + parseInt(this.state.allPaxMealsAmount.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0));

    this.setState({
      sumMealAnBaggage:total
    })

  }


  AdtDetailshandleChange(event, index, fieldName, paxKey, paxType) {    

    let paxArray=this.state.allPaxDataList;
    for(let i=0; i< paxArray.length; i++)
    {
      if(paxArray[i].paxKey==paxKey)
      {
        if(fieldName=="dateofBirth")
        {
          let item=paxArray[i];
          item.dateofBirth=dateFormat(event, "yyyy-mm-dd");
          paxArray[i]=item;
          break;
        }
        if(fieldName=="dateOfExpiry")
        {
          let item=paxArray[i];
          item.dateOfExpiry=dateFormat(event, "yyyy-mm-dd");
          paxArray[i]=item;
          break;
        }        
        let item=paxArray[i];
        item[event.target.name]=event.target.value;
        paxArray[i]=item;
        break;
      }
    }
   
    this.setState({
      allPaxDataList:paxArray
    })

    //Display...
    let DatesSet=[];    
    if(paxType=="Adult")
    {
      DatesSet=this.state.AdultDatesArray;    
      if(fieldName=="dateofBirth")
        DatesSet[index].dob=dateFormat(event, "dd-mm-yyyy");
      else
        DatesSet[index].doe=dateFormat(event, "dd-mm-yyyy");

    this.setState({
      AdultDatesArray : DatesSet
    })
        
    }
    else if(paxType=="Child")
    {
      DatesSet=this.state.ChildDatesArray;
      if(fieldName=="dateofBirth")
        DatesSet[index].dob=dateFormat(event, "dd-mm-yyyy");
      else
        DatesSet[index].doe=dateFormat(event, "dd-mm-yyyy");

    this.setState({
      ChildDatesArray : DatesSet
    })
    }
    else if(paxType=="Infant")
    {
      DatesSet=this.state.InfantDatesArray;
      if(fieldName=="dateofBirth")
        DatesSet[index].dob=dateFormat(event, "dd-mm-yyyy");
      else
        DatesSet[index].doe=dateFormat(event, "dd-mm-yyyy");

    this.setState({
      InfantDatesArray : DatesSet
    })
    }
    
    ///////////////////////////////////////////////////////////////
    // if(fieldName=="nationality")
    // {
    //   let data=this.state.objPaxList;
    //   let item=this.state.objPaxList;
    //   let item1=item[index];

    //   item1.dateofBirth=dateFormat(event, "yyyy-mm-dd");
    //   data[index]=item1;

    //   this.setState({
    //     objPaxList:data
    //   })
    //   return;
    // }
    // if(fieldName=="dateOfExpiry")
    // {
    //   let data=this.state.objPaxList;
    //   let item=this.state.objPaxList;
    //   let item1=item[index];

    //   item1.dateOfExpiry=dateFormat(event, "yyyy-mm-dd");
    //   data[index]=item1;

    //   this.setState({
    //     objPaxList:data
    //   })
    //   return;
    // }
    // if(this.state.objPaxList.length>index)
    // {
    //   let data=this.state.objPaxList;
    //   let item=this.state.objPaxList;
    //   let item1=item[index]
    //   item1[event.target.name]=event.target.value;
    //   data[index]=item1;

    //   this.setState({
    //     objPaxList:data
    //   })

    // }
    // else{
    //   let data=this.state.objPaxList;
    //   let temp={};
    //   temp[event.target.name]=event.target.value;
    //   data.push(temp)

    //   this.setState({
    //     objPaxList:data
    //   })
    // }    
   
  }

  setMealsAnBaggageInObjAllPaxDataList()
  {
    let selectedMealsAndBaggage=this.state.objAdtPaxList;
    let paxAllDAta=this.state.objAdtPaxList;

    for(let i=0; i< selectedMealsAndBaggage.length; i++)
    {
      for(let j=0; j<paxAllDAta.length; j++)
      {
        if(selectedMealsAndBaggage[i].paxKey==paxAllDAta[j].paxKey)
        {
          let item=paxAllDAta[j];
          item.objMealList=selectedMealsAndBaggage[i].objMealList;
          paxAllDAta[j]=item;
          break;
        }
      }      
    }

    this.setState({
      allPaxDataList:paxAllDAta
    })
  }

  getBookingResponseResult(id, fare, code, idR, fareR, codeR, no, altno, email, list, list2) {
        
    //Check network connection...
    if(!CheckNetworkStatus())
        return;

    // const data = {
    //   itinId: id,
    //   fareId: fare,
    //   providerCode: code,
    //   itinIdR: idR,
    //   fareIdR: fareR,
    //   providerCodeR: codeR,
    //   contactNumber: no,
    //   alternateContactNumber: altno,
    //   contactEmail: email,
    //   objAdtPaxList: this.state.objAdtPaxList,
    //   objChdPaxList: this.state.objchdPaxList,
    //   objInfPaxList: this.state.objinfPaxList,
    //   BookingPaxdetails: this.state.BookingPaxdetails,
    //   BookingGstDetails: this.state.BookingGstDetails,
    // };

    this.isBookingBtnPressed=true;
    let data={};
    // data = Object.assign({}, this.state.BookRequestBody);

    
    data = Object.assign({}, this.state.BookRequestBody);
    data.itinId=id;
    data.fareId=fare;
    data.providerCode=code;
    data.itinIdR=idR;
    data.fareIdR=fareR;
    data.providerCodeR=codeR;  
    data.objGst=this.state.objGst;

    data.contactNumber=this.state.prefixContactNumber + this.state.BookRequestBody.contactNumber;
    if(this.state.BookRequestBody.alternateContactNumber != null || this.state.BookRequestBody.alternateContactNumber != undefined)
      data.alternateContactNumber =this.state.prefixAltrContactNumber + this.state.BookRequestBody.alternateContactNumber;
       
    let selectedMealsAndBaggage=this.state.objAdtPaxList;
    let paxAllDAta=this.state.allPaxDataList;

    //Adult meals & baggage
    for(let i=0; i< selectedMealsAndBaggage.length; i++)
    {
      for(let j=0; j<paxAllDAta.length; j++)
      {
        if(selectedMealsAndBaggage[i].paxKey==paxAllDAta[j].paxKey)
        {
          let item=paxAllDAta[j];
          item.objBaggage=selectedMealsAndBaggage[i].objBaggage

          item.objMealList=selectedMealsAndBaggage[i].objMealList;
          paxAllDAta[j]=item;
          break;
        }
      }      
    }

    let chldselectedMealsAndBaggage=this.state.objchdPaxList;
    //Child meals & baggage
    for(let i=0; i< chldselectedMealsAndBaggage.length; i++)
    {
      for(let j=0; j<paxAllDAta.length; j++)
      {
        if(chldselectedMealsAndBaggage[i].paxKey==paxAllDAta[j].paxKey)
        {
          let item=paxAllDAta[j];
          item.objBaggage=chldselectedMealsAndBaggage[i].objBaggage

          item.objMealList=chldselectedMealsAndBaggage[i].objMealList;
          paxAllDAta[j]=item;        
          break;
        }
      }      
    }
    
    this.setState({
      allPaxDataList:paxAllDAta
    })
    /////////
    data.objPaxList=this.state.allPaxDataList
    //this.props.navigate('/BookingConfirm', { state: { bookParam: data } });

///////////////////////////////////////////////////////////////////////////////////////////////
      // this.props.navigate('/Payment', { state: { bookParam: data } });

      const secretKey = '123'; // Secret key used for encryption
        const jsonData = JSON.stringify(data);
        const encryptedData = AES.encrypt(jsonData, secretKey).toString();
        // localStorage.setItem("onewayBookingResult", JSON.stringify(data));
        // this.props.navigate('/checkout');
        const bookparam = encodeURIComponent(encryptedData);
        this.props.navigate(`/Payment?PaymentConfirmRequest=${bookparam}`)
///////////////////////////////////////////////////////////////////////////////////////////////


    //this.props.navigate('/Payment', { state: { bookParam: data } });
    
  //   GetBookingConfirm(data).then((response) => {   
  //     if (response.data.status) {

  //       const secretKey = '123'; // Secret key used for encryption
  //   const jsonData = JSON.stringify(response);
  //   const encryptedData = AES.encrypt(jsonData, secretKey).toString();
  //   //localStorage.setItem("onewayBookingResult", JSON.stringify(data));
  //   //this.props.navigate('/checkout');
  //   //const bookparam = encodeURIComponent(encryptedData);
  //   //this.props.navigate(`/BookingConfirm?BookingConfirmRequest=${bookparam}`)
      
  // }
  // else
  // {
  //   // window.alert("Booking Failed");
  //   // return;
  //   const secretKey = '123'; // Secret key used for encryption
  //   const jsonData = JSON.stringify(response);
  //   const encryptedData = AES.encrypt(jsonData, secretKey).toString();
  //   //localStorage.setItem("onewayBookingResult", JSON.stringify(data));
  //   //this.props.navigate('/checkout');
  //   const bookparam = encodeURIComponent(encryptedData);
   
  //   this.props.navigate(`/BookingConfirm?BookingConfirmRequest=${bookparam}`)

  // }
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //     if(error.response.data.responseMessage=="InvalidToken")
  //     {
  //       // Swal.fire(
  //       //   'Unauthorized Access !',
  //       //   'Please Login Again',
  //       //   'warning'
  //       // )
        
  //       this.props.navigate('/');
        
  //     }
  //   });

   

    
    


  }

  getBookingResponseIRTResult(id, fare, code, idR, fareR, codeR, no, altno, email, list, list2) {
    const data = {
      itinId: id,
      fareId: fare,
      providerCode: code,
      itinIdR: idR,
      fareIdR: fareR,
      providerCodeR: codeR,
      contactNumber: no,
      alternateContactNumber: altno,
      contactEmail: email,
      BookingPaxdetails: this.state.BookingPaxdetails,
      BookingGstDetails: this.state.BookingGstDetails,
    };   

    const secretKey = '123'; // Secret key used for encryption
    const jsonData = JSON.stringify(data);
    const encryptedData = AES.encrypt(jsonData, secretKey).toString();
    //localStorage.setItem("onewayBookingResult", JSON.stringify(data));
    //this.props.navigate('/checkout');
    const bookparam = encodeURIComponent(encryptedData);   
    this.props.navigate(`/BookingConfirm?IRTBookingConfirmRequest=${bookparam}`)
  }

  selectionFieldsChange(event)
  {
    let data={};
    data[event.target.name]=event.target.value;

    this.setState({
      tempDAta:data,
      [event.target.name]:event.target.value,

    })
  }

  // selectionFieldsChange(event) {
  //   const { name, value } = event.target;
  //   this.setState(prevState => ({
  //     tempData: {
  //       ...prevState.tempData,
  //       [name]: value,
  //     },
  //   }));
  // }
  
  

  inputChangeHandler(event){
    const { name, value } = event.target
    let data=this.state.BookRequestBody;
    data[name]=value;
    this.setState({
      BookRequestBody:data,
    })

  }

  // inputChangeHandler(event) {
  //   const { name, value } = event.target;
  //   const { BookRequestBody, tempData } = this.state;
  
  //   const mergedData = { ...BookRequestBody, ...tempData };
  //   if (name === "contactNumber") {
  //     mergedData[name] = tempData.prefixContactNumber + value;
  //   } else if (name === "alternateContactNumber") {
  //     mergedData[name] = tempData.prefixAltrContactNumber + value;
  //   } else {
  //     mergedData[name] = value;
  //   }
  
  //   this.setState({
  //     BookRequestBody: mergedData,
  //   }, () => {
  //   });
  // }

  gstInputChangeHandler(event)
  {
    const { name, value } = event.target
    let data=this.state.objGst;
    data[name]=value;
    this.setState({
      objGst:data,
      selectedState:value
    })
  }
  handleCheckboxChange = (checkboxValue, index, index1,event, type) => {

    let data=this.state.selectedCheckbox;
    if(event.target.checked)
      if(type=="Meals")
        data[index].data[index1].value=checkboxValue;
      else
        data[index].data[index1].baggage=checkboxValue;
    else
      if(type=="Meals")
          data[index].data[index1].value="";
      else
          data[index].data[index1].baggage="";

     this.setState({
      selectedCheckbox:data
    })


    // let isExist=false;
    // for(let i = 0; i < data.length; i++)
    // {
    //   if(data[i].index1==index1 && data[i].adult==index)
    //   {
    //     isExist=true;
    //     data[i].value=checkboxValue;

    //     // this.setState({
    //     //   selectedCheckbox:data,
    //     // })
    //   }
    // }

    // if(!isExist)
    // data.push({
    //   "adult":index,
    //   "index":index1,
    //   "value":checkboxValue
    // });

    // this.setState({
    //   selectedCheckbox:data
    // })





    // if(this.state.selectedCheckbox==checkboxValue)
    // {
    //   this.setState({ selectedCheckbox: null });
    // }
    // else
    // {
    //   this.setState({ selectedCheckbox: checkboxValue });
    // }
    
  };

  mobileInputChangeHandler = (event) => {
    const { name, value } = event.target
    let data = {};
    data.mobileNumber = value;
    this.setState({
      loginRequest: data,
    })
  }


    ///////////AUthentication/////////////////////////////
    LoginOTP() {
     
      if(this.state.OtpResentOnce <= 1)
        Authenticate(this.state.loginRequest).
          then((response) => {
           
            if (response.data.status) {          
             
              this.setState({
                isOtpSent: true
              });
              this.startTimer();
              this.setState({
                secondsRemaining: 60,
              })
            }
          })
    }

    startTimer = () => {
      this.timer = setInterval(() => {
        this.setState(prevState => ({
          secondsRemaining: prevState.secondsRemaining - 1
        }), () => {
          if (this.state.secondsRemaining === 0) {
            this.setState({ showButton: true });
            clearInterval(this.timer);
          }
        });
      }, 1000); // 1 second
    };

    handleResendOTP = () => {
      this.setState({
        OtpResentOnce:this.state.OtpResentOnce + 1 ,
  
      })
      // Handle resend OTP logic here
      // For example, make an API call to resend the OTP
      this.setState({ showButton: false, secondsRemaining: 60 }, () => {
        this.startTimer();
        this.LoginOTP()
      });
    };

      /////////////////Input value set//////////////////////////////////////////////
  otpInputChangeHandler = (event) => {
    const { name, value } = event.target;
    let data = {};
    data.otp = value;
    this.setState({
      otpSubmitRequest: data,
    })
  }

    ///////////OTP Submit/////////////////////////////
    SubmitOTP() {
      //otpSubmitRequest.mobileNumber=loginRequest.mobileNumber;

      
      let data = {};
      data = this.state.otpSubmitRequest;
      data.mobileNumber = this.state.loginRequest.mobileNumber;
      data.otp=data.otp.trim();
      SubmitOTPToLogin(data).
        then((response) => {
        
          if (response.data.status) {
            this.setState({
              showLogin:false,
              isLogin:true,
            })
            localStorage.setItem('tokenValue', response.data.token);
            localStorage.removeItem('NonUsertokenValue');
            localStorage.setItem('TM_UDetails', JSON.stringify(response.data.objUser));
  
            this.setState({
              isOtpSuccess:false,
              IsRefresh:true,
            })
            toast.success('You are successfully logged in', {
              position: 'bottom-center',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            });
            // this.componentDidMount();            
           
            // this.forceUpdate();
            //this.render();
          }
          else
          {
            this.setState({
                isOtpSuccess:true
            })
          }
        })
    }

    UseAnotherMobileNumber(){
      this.setState({
        isOtpSent:false,
      });
    }

  handleCheckboxChangeChild = (checkboxValue, index, index1,event, type) => {
  
    let data=this.state.selectedCheckboxChild;
    if(event.target.checked)
      if(type=="Meals")
        data[index].data[index1].value=checkboxValue;
      else
        data[index].data[index1].baggage=checkboxValue;
    else
      if(type=="Meals")
          data[index].data[index1].value="";
      else
          data[index].data[index1].baggage="";
    this.setState({
          selectedCheckboxChild:data
    })

    // let isExist=false;
    // for(let i = 0; i < data.length; i++)
    // {
    //   if(data[i].index1==index1 && data[i].adult==index)
    //   {
    //     isExist=true;
    //     data[i].value=checkboxValue;

    //     // this.setState({
    //     //   selectedCheckbox:data,
    //     // })
    //   }
    // }

    // if(!isExist)
    // data.push({
    //   "adult":index,
    //   "index":index1,
    //   "value":checkboxValue
    // });

    // this.setState({
    //   selectedCheckbox:data
    // })





    // if(this.state.selectedCheckbox==checkboxValue)
    // {
    //   this.setState({ selectedCheckbox: null });
    // }
    // else
    // {
    //   this.setState({ selectedCheckbox: checkboxValue });
    // }
    
  };
  // handleContactNumberChange = (event) => {
  //   const inputValue = event.target.value;
  //   const digitsOnly = inputValue.replace(/\D/g, "").slice(0, 10);
  //   this.setState({ contactNumber: digitsOnly });

  //   if (digitsOnly.length !== 10) {
  //     this.setState({ error: "Contact number must be 10 digits" });
  //   } else {
  //     this.setState({ error: "" });
  //   }
  // }
  //function UserDetails() {
  //return (
    handleClose = () => {
      this.setState({ show: false });
    };
  
    handleShow = () => {
      this.setState({ show: true });
    };
  render() {
    const { minutes, seconds } = this.state;
    const { isModalOpen,isLogin,isFailed } = this.state;
    const { showButton, secondsRemaining } = this.state;
    let mealsList = [];
    const { password, newPassword, confirmPassword } = this.state;
    const { passportNumber, selectedCountry, dateOfBirth, selectedCountryIssue, dateOfExpiry,selectedState } = this.state;

    let totalAmount = 0;
    const { profiledetails, firstName, lastName, titleSelectBoxName } = this.state;
    const { selectedCheckbox } = this.state;
    const { displayCount, showLess } = this.state;
    const { item1, index, index1 } = this.props;
    const { contactNumber, error } = this.state;
    
    return (this.state.isLoaded) ? (
      <div>
        {isFailed && (
        <div>
         <div className="container">
         <div className="row">
          <h4>Request Failed</h4>
         </div>
         </div>
       

        </div>
        )}

        {!isFailed && (
        <div className="passenger-details-area pt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-9">
                <div>
                  <div className="timer mb-2 sticky-top">
                    <p className="small-text no-margin">
                    <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="currentColor"
                              className="bi bi-clock"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                            </svg>{" "}
              <span id="time">{minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}</span> minutes left to complete the booking               
                    </p>
                  </div>
                </div>
                <h4 className="td-head">Flight Details</h4>
                {/*Flight details*/}
                <div className="deal-wrapper">
                  <div className="checkout-flight">
                    {this.state.objseglistResult.map((item, index) => (
                      <div className="t-details-area mb-0" key={index}>
                        <div className="row align-items-center">
                          <div className="airline-company col-lg-2 col-md-2 text-center">
                            <img
                              src={`https://agents.alhind.com/images/logos/${item.airlineCode}.gif`}
                              width={40}
                            />
                            <p className="small-text">
                              {item.airlineName}
                              <br />
                              {item.flightdetails}
                            </p>
                          </div>
                          <div className="route-details-area col-lg-2 col-md-2 text-right">
                            <div className="row">
                              <p className="small-text">
                                {item.departureAirportCode}
                              </p>
                              <h6 className="time-text">
                                {item.departureTime}
                              </h6>
                              <p className="small-text text-grey">
                                {item.departureDate}
                                <br />
                                {item.departureCity}
                              </p>
                            </div>
                          </div>
                          <div className="travel-time col-lg-3 col-md-3 text-center">
                            <p className="small-text text-grey">
                              {item.travelDuration}
                            </p>
                            <span>
                              <img src="assets/img/arrow.png" />
                            </span>
                           
                          </div>
                          <div className="route-details-area col-lg-2 col-md-2 text-left">
                            <div className="row">
                              <p className="small-text">
                                {item.arrivalAirportCode}
                              </p>
                              <h6 className="time-text">{item.arrivalTime}</h6>
                              <p className="small-text text-grey">
                                {item.arrivalDate}
                                <br />
                                {item.arrivalCity}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 airline-price-area me-auto">
                            <div className="d-flex align-items-center justify-content-center">
                              <div className="flex-row">
                                <p className="small-text">
                                  {item.airlineFlightClass}
                                </p>
                                {item.cabinBaggage.length > 0 && (
                                <p className="small-text">
                                  <img
                                    src="assets/img/baggage.svg"
                                    width={15}
                                  />{" "}
                                  {item.cabinBaggage}
                                </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                  </div>


                </div>
                {/*flight details ends*/}
                {/*Traveller tab starts*/}
                <div className="accordion addon mt-3">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="panel-heading">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#traveller-collapse"
                        aria-expanded="true"
                        aria-controls="traveller-collapse"
                      >
                        <img src="assets/img/passenger.svg" width={35} />
                        Traveller Details.
                      </button>
                    </h2>
                    <div
                      id="traveller-collapse"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panel-heading"
                    >
                      <div className="accordion-body">
                        <div className="t-details-area">
                          <div className="pax-head">
                            <h4 className="no-margin">Adult</h4>
                            {this.state.AdtpricingPaxListResult.map(
                              (item, index) => (
                                <div className="pax-info" key={index}>
                                  <div className="passenger-section mb-2">
                                    <div className="row align-items-center">
                                      <div className="col-md-1 col-lg-1 p-bg">
                                        <h6 className="no-margin">
                                          Adult {index + 1} 
                                        </h6>
                                      </div>
                                      <div className="col-md-2 col-lg-2">
                                        <select
                                          name={`title`}
                                          id={`titleSelectBox${index}`}
                                          className="form-control"
                                          value={
                                            this.state[
                                            `titleSelectBoxName${index}`
                                            ]
                                          }
                                          //value={titleSelectBoxName}
                                          // onChange={(event) =>
                                          //   this.handleChange(event, index)
                                          // }
                                          onChange={(event) => {this.AdtDetailshandleChange(event, index, "TL", item.paxKey); this.CheckValidationByFunction(event, index,"title", "", this.state.FieldValidations,this.state.allPaxDataList, "Adt")}}
                                        >
                                          <option value="">Title</option>
                                          <option value="Mr">Mr</option>
                                          <option value="Ms">Ms</option>
                                          <option value="Mrs">Mrs</option>
                                        </select>
                                        { (!this.state.FieldValidations[index].title && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i> Title required </div>}
                                      </div>

                                      <div className="col-md-4 col-lg-4">
                                      <div className="did-floating-label-content">
                                        <input
                                          type="text"
                                          name={`firstName`}
                                          value={firstName}
                                          onChange={(event) => { this.AdtDetailshandleChange(event, index, "FN", item.paxKey); this.CheckValidationByFunction(event, index,"fName", "", this.state.FieldValidations,this.state.allPaxDataList, "Adt")}}
                                          className="did-floating-input form-control"
                                          placeholder=" "
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="First Name / Given Name"
                                        />
                                          <label className="did-floating-label">First Name</label>
                                        { (!this.state.FieldValidations[index].firstName && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i> First name required </div>}


                                      </div>
                                        </div>
                                      <div className="col-md-5 col-lg-5">
                                      <div className="did-floating-label-content">
                                        <input
                                          type="text"
                                          name={`lastName`}
                                          value={lastName}
                                          onChange={(event) => {this.AdtDetailshandleChange(event, index, "LN", item.paxKey); this.CheckValidationByFunction(event, index,"lName", "", this.state.FieldValidations,this.state.allPaxDataList, "Adt")}}
                                          className="form-control did-floating-input"
                                          placeholder=" "
                                        />
                                          <label className="did-floating-label">Last Name</label>
                                          { (!this.state.FieldValidations[index].lastName && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i> Last name required </div>}
                                       </div>
                                      </div>
                                    </div>
                                  </div>
                                  { this.state.isPassportMandatory && <div className="pt-1 add-on-area mb-3">
                                    <a
                                      className="mr-20 no-underline"
                                      data-bs-toggle="collapse"
                                      href={`#collapsePassport1${index}`}
                                      role="button"
                                      aria-expanded="false"
                                      aria-controls="collapsePassport1"
                                    >
                                      Passport Details
                                    </a>
                                    <div
                                      className="collapse show pt-2"
                                      id={`collapsePassport1${index}`}
                                    >
                                      <div className="card card-body">
                                        <h4>Passport Details</h4>
                                        <button type="button"
                                      className="btn-close collapse-close"
                                      data-bs-toggle="collapse"
                                      href={`#collapsePassport1${index}`}
                                      role="button"
                                      aria-expanded="false"
                                      aria-controls="collapsePassport1"
                                    ></button>
                                        <div className="row">
                                          <div className="col-lg-4 col-md-4 col-4">
                                          <label>Passport Number</label>
                                          
                                            <input
                                              type="text"
                                              className="form-control mb-3"
                                              placeholder="Passport number"
                                              name={`documentNumber`}
                                              autoComplete="off"
                                              value={passportNumber}
                                              onChange={(event) => {this.AdtDetailshandleChange(event, index,"", item.paxKey); this.CheckValidationByFunction(event, index,"dNumber", "", this.state.FieldValidations,this.state.allPaxDataList, "Adt")}}
                                            />
                                             { (!this.state.FieldValidations[index].documentNumber && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i> Passport Number required </div>}
                                          </div>
                                          <div className="col-lg-4 col-md-4 col-4">
                                            <label>Nationality</label>
                                            <select
                                              name="nationality"
                                              id="country"
                                              className="form-control mb-3"
                                              value={selectedCountry}
                                              // onChange={(e) => {
                                              //   this.countryChange(e);
                                              // }}
                                              onChange={(event) => this.AdtDetailshandleChange(event, index,"country", item.paxKey)}
                                            >
                                              {/* <option>Nationality</option> */}
                                              {this.state.Countries.map(
                                                (country) => (
                                                  <option
                                                    key={country.id}
                                                    value={country.countryCode}
                                                    selected={country.id === 101 ? true : false}
                                                  >
                                                    {country.name}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </div>
                                          <div className="col-lg-4 col-md-4 col-4">
                                          <label>Date of birth</label>
                                            <DatePicker   
                                              renderCustomHeader={({
                                                date,
                                                changeYear,
                                                changeMonth,
                                                decreaseMonth,
                                                increaseMonth,
                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled,
                                              }) => (
                                                <div
                                                  style={{
                                                    margin: 10,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <button
                                                    onClick={decreaseMonth}
                                                    disabled={
                                                      prevMonthButtonDisabled
                                                    }
                                                  >
                                                    {"<"}
                                                  </button>
                                                  <select
                                                    value={date.getFullYear()}
                                                    onChange={({
                                                      target: { value },
                                                    }) => changeYear(value)}
                                                  >
                                                    {this.state.AdultDobYears.map(
                                                      (option) => (
                                                        <option
                                                          key={option}
                                                          value={option}
                                                        >
                                                          {option}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>

                                                  <select
                                                    value={
                                                      this.state.months[
                                                      date.getMonth()
                                                      ]
                                                    }
                                                    onChange={({
                                                      target: { value },
                                                    }) =>
                                                      changeMonth(
                                                        this.state.months.indexOf(
                                                          value
                                                        )
                                                      )
                                                    }
                                                  >
                                                    {this.state.months.map(
                                                      (option) => (
                                                        <option
                                                          key={option}
                                                          value={option}
                                                        >
                                                          {option}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>

                                                  <button
                                                    onClick={increaseMonth}
                                                    disabled={
                                                      nextMonthButtonDisabled
                                                    }
                                                  >
                                                    {">"}
                                                  </button>
                                                </div>
                                              )}
                                              autoComplete="off"
                                              value={this.state.AdultDatesArray[index].dob}
                                              name="dateofBirth"
                                              onChange={(event) => {this.AdtDetailshandleChange(event, index, "dateofBirth", item.paxKey, "Adult"); this.CheckValidationByFunction(event, index, "dateType", "dateofBirth",this.state.FieldValidations,this.state.allPaxDataList, "Adt")}}
                                              maxDate={this.addYears(new Date(this.state.departureDate), 12)}
                                              // onChange={(date) => {
                                              //   this.setState({
                                              //     startDate: date,
                                              //   });
                                              // }}
                                            />
                                            { (!this.state.FieldValidations[index].dateofBirth && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i> DOB required </div>}
                                          </div>
                                          <div className="col-lg-4 col-md-4 col-4">
                                          <label>Country of issue</label>
                                            <select
                                              name="countryofIssue"
                                              id="countryIssue"
                                              className="form-control mb-3"
                                              value={selectedCountryIssue}
                                              onChange={(event) => this.AdtDetailshandleChange(event, index,"countryOfIssue", item.paxKey)}
                                            >
                                              {/* <option>Country of issue</option> */}
                                              {this.state.Countries.map(
                                                (country) => (
                                                  <option
                                                    key={country.id}
                                                    value={country.countryCode}
                                                    selected={country.id === 101 ? true : false}
                                                  >
                                                    {country.name}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </div>
                                          <div className="col-lg-4 col-md-4 col-4">
                                          <label>Date of expiry</label>
                                            <DatePicker
                                            minDate={new Date()}
                                              renderCustomHeader={({
                                                date,
                                                changeYear,
                                                changeMonth,
                                                decreaseMonth,
                                                increaseMonth,
                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled,
                                              }) => (
                                                <div
                                                  style={{
                                                    margin: 10,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <button
                                                    onClick={decreaseMonth}
                                                    disabled={
                                                      prevMonthButtonDisabled
                                                    }
                                                  >
                                                    {"<"}
                                                  </button>
                                                  <select
                                                    value={date.getFullYear()}
                                                    onChange={({
                                                      target: { value },
                                                    }) => changeYear(value)}
                                                  >
                                                    {this.state.doeYears.map(
                                                      (option) => (
                                                        <option
                                                          key={option}
                                                          value={option}
                                                        >
                                                          {option}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>

                                                  <select
                                                    value={
                                                      this.state.months[
                                                      date.getMonth()
                                                      ]
                                                    }
                                                    onChange={({
                                                      target: { value },
                                                    }) =>
                                                      changeMonth(
                                                        this.state.months.indexOf(
                                                          value
                                                        )
                                                      )
                                                    }
                                                  >
                                                    {this.state.months.map(
                                                      (option) => (
                                                        <option
                                                          key={option}
                                                          value={option}
                                                        >
                                                          {option}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>

                                                  <button
                                                    onClick={increaseMonth}
                                                    disabled={
                                                      nextMonthButtonDisabled
                                                    }
                                                  >
                                                    {">"}
                                                  </button>
                                                </div>
                                              )}
                                              value={this.state.AdultDatesArray[index].doe}
                                              onChange={(event) => {this.AdtDetailshandleChange(event, index, "dateOfExpiry", item.paxKey, "Adult"); this.CheckValidationByFunction(event, index, "dateType", "dateOfExpiry",this.state.FieldValidations,this.state.allPaxDataList, "Adt")}}
                                            />
                                            { (!this.state.FieldValidations[index].dateOfExpiry && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i> DOE required </div>}
                                          </div>
                                        
                                         
                                        </div>
                                      </div>
                                    </div>

                                    {/*Addon tab starts*/}
                                    {(item.objmealseglist.length>0 || item.objbaggageseglist.length >0 ) && <div className="accordion addon mt-3">
                                      <div className="accordion-item">
                                        <h2
                                          className="accordion-header"
                                          id="panel-heading"
                                        >
                                          <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={
                                              `#panel-collapse-adult` +
                                              `${index}`
                                            }
                                            aria-expanded="true"
                                            aria-controls="panel-collapse-adult"
                                          >
                                            <img
                                              src="assets/img/meal.svg"
                                              width={30}
                                            />{" "}
                                            Choose Meals &amp; Baggage
                                          </button>
                                        </h2>
                                        <div
                                          id={
                                            `panel-collapse-adult` + `${index}`
                                          }
                                          className="accordion-collapse collapse"
                                          aria-labelledby="panel-heading"
                                        >
                                          <div className="accordion-body">
                                            {/* Tabs navs */}
                                            <ul
                                              className="nav nav-tabs mb-3"
                                              id="ex1"
                                              role="tablist"
                                            >
                                              {item.objmealseglist.length >
                                                0 && (
                                                  <li
                                                    className="nav-item"
                                                    role="presentation"
                                                  >
                                                    <a
                                                      className="nav-link active"
                                                      id={"ex1-tab-" + `${index}`}
                                                      data-bs-toggle="tab"
                                                      href={
                                                        "#ex1-tabs-" + `${index}`
                                                      }
                                                      role="tab"
                                                      aria-controls={
                                                        "ex1-tabs-" + `${index}`
                                                      }
                                                      aria-selected="true"
                                                    >
                                                      Meals
                                                    </a>
                                                  </li>
                                                )}
                                              {item.objbaggageseglist.length >
                                                0 && (
                                                  <li
                                                    className="nav-item"
                                                    role="presentation"
                                                  >
                                                    <a
                                                      className="nav-link"
                                                      id={
                                                        "adt1-ex1-tab-" +
                                                        `${index}`
                                                      }
                                                      data-bs-toggle="tab"
                                                      href={
                                                        "#adt1-ex1-tabs-" +
                                                        `${index}`
                                                      }
                                                      role="tab"
                                                      aria-controls={
                                                        "adt1-ex1-tabs-" +
                                                        `${index}`
                                                      }
                                                      aria-selected="false"
                                                    >
                                                      Baggage
                                                    </a>
                                                  </li>
                                                )}
                                            </ul>
                                            {/* Tabs navs */}
                                            {/* Tabs content */}
                                            <div
                                              className="tab-content"
                                              id="ex1-content"
                                            >
                                              <div
                                                className="tab-pane fade show active"
                                                id={"ex1-tabs-" + `${index}`}
                                                role="tabpanel"
                                                aria-labelledby={
                                                  "ex1-tab-" + `${index}`
                                                }
                                              >
                                                {/* Meals inner tabs starting */}
                                                <ul
                                                  className="nav nav-tabs mb-3 segment-tabs"
                                                  id="seg"
                                                  role="tablist"
                                                >
                                                  {item.objmealseglist.map(
                                                    (item1, index1) => (
                                                      <div key={index1}>
                                                        <li
                                                          className="nav-item"
                                                          role="presentation"
                                                        >
                                                          <a
                                                            className={
                                                              "nav-link " +
                                                              (index1 == 0
                                                                ? "show active"
                                                                : "")
                                                            }
                                                            id={
                                                              "adt-seg-tab-" +
                                                              `${index1}` +
                                                              "-" +
                                                              `${index}`
                                                            }
                                                            data-bs-toggle="tab"
                                                            href={
                                                              "#adt-seg-tabs-" +
                                                              `${index1}` +
                                                              "-" +
                                                              `${index}`
                                                            }
                                                            role="tab"
                                                            aria-controls={
                                                              "adt-seg-tabs-" +
                                                              `${index1}` +
                                                              "-" +
                                                              `${index}`
                                                            }
                                                            aria-selected="true"
                                                          >
                                                            {item1.sectorCode}
                                                          </a>
                                                        </li>
                                                      </div>
                                                    )
                                                  )}
                                                </ul>
                                                <div
                                                  className="tab-content"
                                                  id="ex2-content"
                                                >
                                                  {item.objmealseglist.map(
                                                    (item1, index1) => (
                                                      <div key={index1}
                                                        className={
                                                          "tab-pane fade scrollbar1 " +
                                                          (index1 == 0
                                                            ? "show active"
                                                            : "")
                                                        }
                                                        id={
                                                          "adt-seg-tabs-" +
                                                          `${index1}` +
                                                          "-" +
                                                          `${index}`
                                                        }
                                                        role="tabpanel"
                                                        aria-labelledby={
                                                          "adt-seg-tab-" +
                                                          `${index1}` +
                                                          "-" +
                                                          `${index}`
                                                        }
                                                      >
                                                         <div className="row">
                                                          
                                                          {item1.objmealList.slice(0, displayCount).map((item2, index2) => (
                                                           
                                                                <div className="col-lg-2 col-md-2 text-center mb-3" key={index2}>
                                                                  <div className="meal-radio">
                                                                    <input
                                                                      type="checkbox"
                                                                      id={
                                                                        "adt-meal01" +
                                                                        `${index}` +
                                                                        "-" +
                                                                        `${index1}` +
                                                                        "-" +
                                                                        `${index2}`
                                                                      }
                                                                      name={
                                                                        "select" +
                                                                        `${index}` +
                                                                        "-" +
                                                                        `${index1}`
                                                                      }
                                                                      value={"checkbox"+`${index}`+`${index1}`+`${index2}`}
                                                                      checked={("checkbox"+`${index}`+`${index1}`+`${index2}` === this.state.selectedCheckbox[index].data[index1].value) ? true : false}
                                                                      // id={`adt-meal01${index}-${index1}-${index2}`}
                                                                      // name={`select${index}-${index1}-${index2}`}
                                                                      // defaultValue={1}
                                                                      // checked={this.state.selectedMealIndex === index2}
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        this.adultTravellerMealsDetailsChange(
                                                                          e,
                                                                          index,
                                                                          item2,
                                                                          item1,
                                                                          item.paxKey,
                                                                          item1.sectorCode
                                                                        );
                                                                        this.handleCheckboxChange("checkbox"+`${index}`+`${index1}`+`${index2}`, index, index1, e, "Meals");
                                                                        if(!e.target.checked){this.removeMealAmount(e, item.paxKey, item1.sectorCode)}
                                                                      }}
                                                                    />
                                                                    <label
                                                                      htmlFor={
                                                                        "adt-meal01" +
                                                                        `${index}` +
                                                                        "-" +
                                                                        `${index1}` +
                                                                        "-" +
                                                                        `${index2}`
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          item2.mealUrl
                                                                        }
                                                                        className="img-fluid"
                                                                        title={item2.name}
                                                                      />
                                                                      <p className="no-margin small-text truncate" title={item2.name}>
                                                                        {
                                                                          item2.name
                                                                        }
                                                                      </p>
                                                                      <p className="no-margin small-text">
                                                                        {
                                                                          item2.amount
                                                                        }
                                                                      </p>
                                                                    </label>
                                                                  </div>
  
  
  
  
                                                                </div>
                                                   
                                                              )
                                                            )}
                                                          </div>
                                                          {item1.objmealList.length > displayCount && !showLess && (
            <a onClick={()=>{this.loadMoreItems(item1.objmealList.length)}}>Show More</a>
          )}
  
          {showLess && (
            <a onClick={this.showLessItems}>Show Less</a>
          )}
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                              <div
                                                className="tab-pane fade"
                                                id={
                                                  "adt1-ex1-tabs-" + `${index}`
                                                }
                                                role="tabpanel"
                                                aria-labelledby={
                                                  "adt1-ex1-tab-" + `${index}`
                                                }
                                              >
                                                {/* Baggage inner tabs starting */}
                                                <ul
                                                  className="nav nav-tabs mb-3 segment-tabs"
                                                  id="seg"
                                                  role="tablist"
                                                >
                                                  {item.objbaggageseglist.map(
                                                    (item1, index1) => (
                                                      <div key={index1}>
                                                        <li
                                                          className="nav-item"
                                                          role="presentation"
                                                        >
                                                          <a
                                                            className={
                                                              "nav-link " +
                                                              (index1 == 0
                                                                ? "show active"
                                                                : "")
                                                            }
                                                            id={
                                                              "adt-bag-seg-tab-" +
                                                              `${index1}` +
                                                              "-" +
                                                              `${index}`
                                                            }
                                                            data-bs-toggle="tab"
                                                            href={
                                                              "#adt-bag-seg-tabs-" +
                                                              `${index1}` +
                                                              "-" +
                                                              `${index}`
                                                            }
                                                            role="tab"
                                                            aria-controls={
                                                              "adt-bag-seg-tabs-" +
                                                              `${index1}` +
                                                              "-" +
                                                              `${index}`
                                                            }
                                                            aria-selected="true"
                                                          >
                                                            {item1.sectorCode}
                                                          </a>
                                                        </li>
                                                      </div>
                                                    )
                                                  )}
                                                </ul>
                                                <div
                                                  className="tab-content"
                                                  id="ex2-content"
                                                >
                                                  {item.objbaggageseglist.map(
                                                    (item1, index1) => (
                                                      <div key={index1}
                                                        className={
                                                          "tab-pane fade" +
                                                          (index1 == 0
                                                            ? "show active"
                                                            : "")
                                                        }
                                                        id={
                                                          "adt-bag-seg-tabs-" +
                                                          `${index1}` +
                                                          "-" +
                                                          `${index}`
                                                        }
                                                        role="tabpanel"
                                                        aria-labelledby={
                                                          "adt-bag-seg-tab-" +
                                                          `${index1}` +
                                                          "-" +
                                                          `${index}`
                                                        }
                                                      >
                                                        <div className="row">
                                                          {item1.objbaggageList.map(
                                                            (item2, index2) => (
                                                              <div className="col-lg-2 col-md-2 text-center mb-3" key={index2}>
                                                                <div className="meal-radio">
                                                                  <input
                                                                    type="checkbox"
                                                                    id={
                                                                      "adt-bag01" +
                                                                      `${index}` +
                                                                      "-" +
                                                                      `${index1}` +
                                                                      "-" +
                                                                      `${index2}`
                                                                    }
                                                                    name="select"
                                                                    value={"checkbox"+`${index}`+`${index1}`+`${index2}`}
                                                                    checked={("checkbox"+`${index}`+`${index1}`+`${index2}` === this.state.selectedCheckbox[index].data[index1].baggage) ? true : false}
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      this.adultTravellerBaggageDetailsChange(
                                                                        e,
                                                                        index,
                                                                        item2,
                                                                        item1,
                                                                        item.paxKey,
                                                                        item1.sectorCode
                                                                      );
                                                                      this.handleCheckboxChange("checkbox"+`${index}`+`${index1}`+`${index2}`, index, index1, e, "Baggage") 
                                                                      if(!e.target.checked){this.removeBaggageAmount(e, item.paxKey, item1.sectorCode)}
                                                                    }}
                                                                  />
                                                                  <label
                                                                    htmlFor={
                                                                      "adt-bag01" +
                                                                      `${index}` +
                                                                      "-" +
                                                                      `${index1}` +
                                                                      "-" +
                                                                      `${index2}`
                                                                    }
                                                                  >
                                                                    <img
                                                                      src="assets/img/baggage/baggage.svg"
                                                                      width={80}
                                                                    />
                                                                    <p className="no-margin small-text">
                                                                      {
                                                                        item2.name
                                                                      }
                                                                    </p>
                                                                    <p className="no-margin small-text">
                                                                      {
                                                                        item2.amount
                                                                      }
                                                                    </p>
                                                                  </label>
                                                                </div>
                                                              </div>
                                                            )
                                                          )}
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              </div>

                                              {/*<div class="tab-pane fade" id="ex1-tabs-3" role="tabpanel" aria-labelledby="ex1-tab-3">
    Tab 3 content
  </div>*/}
                                            </div>

                                            {/* Tabs content */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>}
                                  </div>}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      

                        {this.state.ChdpricingPaxListResult.length > 0 && (
                        <div className="t-details-area">
                          <div className="pax-head">
                            <h4 className="no-margin">Child</h4>
                            {this.state.ChdpricingPaxListResult.map(
                              (item, index) => (
                                <div className="pax-info" key={index}>
                                  <div className="passenger-section mb-2">
                                    <div className="row align-items-center">
                                      <div className="col-md-1 col-lg-1 p-bg">
                                        <h6 className="no-margin">Child {index + 1}</h6>
                                      </div>
                                      <div className="col-md-2 col-lg-2">
                                        <select
                                          name={`title`}
                                          id={`titleSelectBox${index}`}
                                          className="form-control"
                                          value={
                                            this.state[
                                            `titleSelectBoxName${index}`
                                            ]
                                          }
                                          onChange={(event) => {this.AdtDetailshandleChange(event, index, "TL", item.paxKey); this.CheckValidationByFunction(event, index,"title", "", this.state.ChildFieldValidations,this.state.allPaxDataList, "chd")}}
                                        >
                                          <option value="">Title</option>
                                          <option value="Mstr">Mstr</option>
                                          <option value="Miss">Miss</option>
                                          {/* <option value="Mrs">Mrs</option> */}
                                        </select>
                                        { (!this.state.ChildFieldValidations[index].title && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i> Title required </div>}
                                      </div>
                                      <div className="col-md-3 col-lg-3">
                                        <input
                                          type="text"
                                          name="firstName"
                                          value={firstName}
                                          onChange={(event) => {this.AdtDetailshandleChange(event, index, "FN", item.paxKey); this.CheckValidationByFunction(event, index,"fName", "", this.state.ChildFieldValidations,this.state.allPaxDataList, "Chd")}}
                                          className="form-control"
                                          placeholder="First Name"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="First Name / Given Name"
                                        />
                                        { (!this.state.ChildFieldValidations[index].firstName && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i> First name required </div>}
                                      </div>
                                      <div className="col-md-3 col-lg-3">
                                        <input
                                          type="text"
                                          name="lastName"
                                          value={lastName}
                                          onChange={(event) => {this.AdtDetailshandleChange(event, index, "LN", item.paxKey); this.CheckValidationByFunction(event, index,"lName", "", this.state.ChildFieldValidations,this.state.allPaxDataList, "Chd")}}
                                          className="form-control"
                                          placeholder="Last Name"
                                        />
                                         { (!this.state.ChildFieldValidations[index].lastName && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i> Last name required </div>}
                                      </div>
                                      <div className="col-md-3 col-lg-3">
                                      <DatePicker
                                      placeholderText="Date Of Birth"
                                          renderCustomHeader={({
                                            date,
                                            changeYear,
                                            changeMonth,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled,
                                          }) => (
                                            <div
                                              style={{
                                                margin: 10,
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <button
                                                onClick={decreaseMonth}
                                                disabled={
                                                  prevMonthButtonDisabled
                                                }
                                              >
                                                {"<"}
                                              </button>                                              
                                              <select
                                                value={date.getFullYear()}
                                                onChange={({
                                                  target: { value },
                                                }) => changeYear(value)}
                                              >
                                                {this.state.ChildDobYears.map(
                                                  (option) => (
                                                    <option
                                                      key={option}
                                                      value={option}
                                                    >
                                                      {option}
                                                    </option>
                                                  )
                                                )}
                                              </select>

                                              <select
                                                value={
                                                  this.state.months[
                                                  date.getMonth()
                                                  ]
                                                }
                                                onChange={({
                                                  target: { value },
                                                }) =>
                                                  changeMonth(
                                                    this.state.months.indexOf(
                                                      value
                                                    )
                                                  )
                                                }
                                              >
                                                {this.state.months.map(
                                                  (option) => (
                                                    <option
                                                      key={option}
                                                      value={option}
                                                    >
                                                      {option}
                                                    </option>
                                                  )
                                                )}
                                              </select>

                                              <button
                                                onClick={increaseMonth}
                                                disabled={
                                                  nextMonthButtonDisabled
                                                }
                                              >
                                                {">"}
                                              </button>
                                            </div>
                                          )}
                                          autoComplete="off"
                                          value={this.state.ChildDatesArray[index].dob}
                                          name="dateofBirthChdd"
                                          onChange={(event) => {this.AdtDetailshandleChange(event, index, "dateofBirth", item.paxKey, "Child"); this.CheckValidationByFunction(event, index, "dateType", "dateofBirth",this.state.ChildFieldValidations,this.state.allPaxDataList, "Chd")}}
                                          maxDate={this.addYears(new Date(this.state.departureDate), 2)}
                                          minDate={this.addYears(new Date(this.state.departureDate), 12)}
                                          // onChange={(date) => {
                                          //   this.setState({ startDate: date });
                                          // }}
                                        />
                                        { (!this.state.ChildFieldValidations[index].dateofBirth && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i> DOB required </div>}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="pt-1 add-on-area mb-3">
                                    <a
                                      className="mr-20 no-underline"
                                      data-bs-toggle="collapse"
                                      href={`#collapsePassport2${index}`}
                                      role="button"
                                      aria-expanded="false"
                                      aria-controls="collapsePassport2"
                                    >
                                      Passport Details
                                    </a>
                                    <div
                                      className="collapse"
                                      id={`collapsePassport2${index}`}
                                    >
                                      <div className="card card-body">
                                        <h4>Passport Details</h4>
                                        <button type="button"
                                      className="btn btn-close collapse-close"
                                      data-bs-toggle="collapse"
                                      href={`#collapsePassport2${index}`}
                                      role="button"
                                      aria-expanded="false"
                                      aria-controls="collapsePassport2"
                                    >
                                     
                                    </button>
                                        <div className="row">
                                          <div className="col-lg-4 col-md-4 col-4">
                                          <label>Passport Number</label>
                                            <input
                                              type="text"
                                              className="form-control mb-3"
                                              placeholder="Passport number"
                                              autoComplete="off"
                                              name={`documentNumber`}
                                              value={passportNumber}
                                              onChange={(event) => {this.AdtDetailshandleChange(event, index,"", item.paxKey); this.CheckValidationByFunction(event, index,"dNumber", "", this.state.ChildFieldValidations,this.state.allPaxDataList, "Chd")}}
                                            />
                                            { (!this.state.ChildFieldValidations[index].documentNumber && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i> Passport Number required </div>}
                                          </div>
                                          <div className="col-lg-4 col-md-4 col-4">
                                          <label>Nationality</label>
                                            <select
                                              name="nationality"
                                              id="country"
                                              className="form-control mb-3"
                                              value={selectedCountry}
                                              // onChange={(e) => {
                                              //   this.countryChange(e);
                                              // }}
                                              onChange={(event) => this.AdtDetailshandleChange(event, index,"country", item.paxKey)}
                                            >
                                              <option>Nationality</option>
                                              {this.state.Countries.map(
                                                (country) => (
                                                  <option
                                                    key={country.id}
                                                    value={country.countryCode}
                                                    selected={country.id === 101 ? true : false}
                                                  >
                                                    {country.name}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </div>
                                          {/* <div className="col-lg-4 col-md-4 col-4">
                                          <label>Date of birth</label>
                                            <DatePicker
                                              renderCustomHeader={({
                                                date,
                                                changeYear,
                                                changeMonth,
                                                decreaseMonth,
                                                increaseMonth,
                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled,
                                              }) => (
                                                <div
                                                  style={{
                                                    margin: 10,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <button
                                                    onClick={decreaseMonth}
                                                    disabled={
                                                      prevMonthButtonDisabled
                                                    }
                                                  >
                                                    {"<"}
                                                  </button>
                                                  <select
                                                    value={date.getFullYear()}
                                                    onChange={({
                                                      target: { value },
                                                    }) => changeYear(value)}
                                                  >
                                                    {this.state.years.map(
                                                      (option) => (
                                                        <option
                                                          key={option}
                                                          value={option}
                                                        >
                                                          {option}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>

                                                  <select
                                                    value={
                                                      this.state.months[
                                                      date.getMonth()
                                                      ]
                                                    }
                                                    onChange={({
                                                      target: { value },
                                                    }) =>
                                                      changeMonth(
                                                        this.state.months.indexOf(
                                                          value
                                                        )
                                                      )
                                                    }
                                                  >
                                                    {this.state.months.map(
                                                      (option) => (
                                                        <option
                                                          key={option}
                                                          value={option}
                                                        >
                                                          {option}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>

                                                  <button
                                                    onClick={increaseMonth}
                                                    disabled={
                                                      nextMonthButtonDisabled
                                                    }
                                                  >
                                                    {">"}
                                                  </button>
                                                </div>
                                              )}
                                              selected={this.state.startDate}
                                              name="dateofBirth"
                                              onChange={(event) => this.AdtDetailshandleChange(event, index, "nationality", item.paxKey)}
                                            />
                                          </div> */}
                                          <div className="col-lg-4 col-md-4 col-4">
                                          <label>Country of issue</label>
                                            <select
                                              name="countryofIssue"
                                              id="countryIssue"
                                              className="form-control mb-3"
                                              value={selectedCountryIssue}
                                              onChange={(event) => this.AdtDetailshandleChange(event, index,"countryOfIssue", item.paxKey)}
                                            >
                                              <option>Country of issue</option>
                                              {this.state.Countries.map(
                                                (country) => (
                                                  <option
                                                    key={country.id}
                                                    value={country.countryCode}
                                                    selected={country.id === 101 ? true : false}
                                                  >
                                                    {country.name}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </div>
                                          <div className="col-lg-4 col-md-4 col-4">
                                          <label>Date of expiry</label>
                                            <DatePicker
                                            minDate={new Date()}
                                              renderCustomHeader={({
                                                date,
                                                changeYear,
                                                changeMonth,
                                                decreaseMonth,
                                                increaseMonth,
                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled,
                                              }) => (
                                                <div
                                                  style={{
                                                    margin: 10,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <button
                                                    onClick={decreaseMonth}
                                                    disabled={
                                                      prevMonthButtonDisabled
                                                    }
                                                  >
                                                    {"<"}
                                                  </button>
                                                  <select
                                                    value={date.getFullYear()}
                                                    onChange={({
                                                      target: { value },
                                                    }) => changeYear(value)}
                                                  >
                                                    {this.state.doeYears.map(
                                                      (option) => (
                                                        <option
                                                          key={option}
                                                          value={option}
                                                        >
                                                          {option}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>

                                                  <select
                                                    value={
                                                      this.state.months[
                                                      date.getMonth()
                                                      ]
                                                    }
                                                    onChange={({
                                                      target: { value },
                                                    }) =>
                                                      changeMonth(
                                                        this.state.months.indexOf(
                                                          value
                                                        )
                                                      )
                                                    }
                                                  >
                                                    {this.state.months.map(
                                                      (option) => (
                                                        <option
                                                          key={option}
                                                          value={option}
                                                        >
                                                          {option}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>

                                                  <button
                                                    onClick={increaseMonth}
                                                    disabled={
                                                      nextMonthButtonDisabled
                                                    }
                                                  >
                                                    {">"}
                                                  </button>
                                                </div>
                                              )}
                                              value={this.state.ChildDatesArray[index].doe}
                                              onChange={(event) => {this.AdtDetailshandleChange(event, index, "dateOfExpiry", item.paxKey, "Child"); this.CheckValidationByFunction(event, index, "dateType", "dateOfExpiry",this.state.ChildFieldValidations,this.state.allPaxDataList, "Chd")}}
                                            />
                                            { (!this.state.ChildFieldValidations[index].dateOfExpiry && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i> DOE required </div>}
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    {/*Addon tab starts*/}
                                    {(item.objmealseglist.length > 0 || item.objbaggageseglist.length > 0) && <div className="accordion addon mt-3">
                                      <div className="accordion-item">
                                        <h2
                                          className="accordion-header"
                                          id="panel-heading-child"
                                        >
                                          <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={
                                              `#panel-collapse-child` +
                                              `${index}`
                                            }
                                            aria-expanded="true"
                                            aria-controls="panel-collapse-child"
                                          >
                                            <img
                                              src="assets/img/meal.svg"
                                              width={30}
                                            />{" "}
                                            Choose Meals &amp; Baggage
                                          </button>
                                        </h2>
                                        <div
                                          id={
                                            `panel-collapse-child` + `${index}`
                                          }
                                          className="accordion-collapse collapse"
                                          aria-labelledby="panel-heading-child"
                                        >
                                          <div className="accordion-body">
                                            {/* Tabs navs */}
                                            <ul
                                              className="nav nav-tabs mb-3"
                                              id="ex1"
                                              role="tablist"
                                            >
                                              {item.objmealseglist.length >
                                                0 && (
                                                  <li
                                                    className="nav-item"
                                                    role="presentation"
                                                  >
                                                    <a
                                                      className="nav-link show active"
                                                      id={
                                                        "child-ex1-tab-" +
                                                        `${index}`
                                                      }
                                                      data-bs-toggle="tab"
                                                      href={
                                                        "#child-ex1-tabs-" +
                                                        `${index}`
                                                      }
                                                      role="tab"
                                                      aria-controls={
                                                        "child-ex1-tabs-" +
                                                        `${index}`
                                                      }
                                                      aria-selected="true"
                                                    >
                                                      Meals
                                                    </a>
                                                  </li>
                                                )}
                                              {item.objbaggageseglist.length >
                                                0 && (
                                                  <li
                                                    className="nav-item"
                                                    role="presentation"
                                                  >
                                                    <a
                                                      className="nav-link"
                                                      id={
                                                        "childB-ex1-tab-" +
                                                        `${index}`
                                                      }
                                                      data-bs-toggle="tab"
                                                      href={
                                                        "#childB-ex1-tabs-" +
                                                        `${index}`
                                                      }
                                                      role="tab"
                                                      aria-controls={
                                                        "childB-ex1-tabs-" +
                                                        `${index}`
                                                      }
                                                      aria-selected="false"
                                                    >
                                                      Baggage
                                                    </a>
                                                  </li>
                                                )}
                                            </ul>
                                            {/* Tabs navs */}
                                            {/* Tabs content */}
                                            <div
                                              className="tab-content"
                                              id="ex1-content"
                                            >
                                              <div
                                                className="tab-pane fade show active"
                                                id={
                                                  "child-ex1-tabs-" + `${index}`
                                                }
                                                role="tabpanel"
                                                aria-labelledby={
                                                  "child-ex1-tab-" + `${index}`
                                                }
                                              >
                                                {/* Meals inner tabs starting */}
                                                <ul
                                                  className="nav nav-tabs mb-3 segment-tabs"
                                                  id="seg"
                                                  role="tablist"
                                                >
                                                  {item.objmealseglist.map(
                                                    (item1, index1) => (
                                                      <div key={index1}>
                                                        <li
                                                          className="nav-item"
                                                          role="presentation"
                                                        >
                                                          <a
                                                            className={
                                                              "nav-link " +
                                                              (index1 == 0
                                                                ? "show active"
                                                                : "")
                                                            }
                                                            id={
                                                              "child-seg-tab-" +
                                                              `${index1}` +
                                                              "-" +
                                                              `${index}`
                                                            }
                                                            data-bs-toggle="tab"
                                                            href={
                                                              "#child-seg-tabs-" +
                                                              `${index1}` +
                                                              "-" +
                                                              `${index}`
                                                            }
                                                            role="tab"
                                                            aria-controls={
                                                              "child-seg-tabs-" +
                                                              `${index1}` +
                                                              "-" +
                                                              `${index}`
                                                            }
                                                            aria-selected="true"
                                                          >
                                                            {item1.sectorCode}
                                                          </a>
                                                        </li>
                                                      </div>
                                                    )
                                                  )}
                                                </ul>
                                                <div
                                                  className="tab-content"
                                                  id="ex2-content"
                                                >
                                                  {item.objmealseglist.map(
                                                    (item1, index1) => (
                                                      <div key={index1}
                                                        className={
                                                          "tab-pane fade scrollbar1 " +
                                                          (index1 == 0
                                                            ? "show active"
                                                            : "")
                                                        }
                                                        id={
                                                          "child-seg-tabs-" +
                                                          `${index1}` +
                                                          "-" +
                                                          `${index}`
                                                        }
                                                        role="tabpanel"
                                                        aria-labelledby={
                                                          "child-seg-tab-" +
                                                          `${index1}` +
                                                          "-" +
                                                          `${index}`
                                                        }
                                                      >
                                                        <div className="row">
                                                        {item1.objmealList.slice(0, displayCount).map((item2, index2) => (
                                                          
                                                              <div className="col-lg-2 col-md-2 text-center mb-3" key={index2}>
                                                                <div className="meal-radio">
                                                                  <input
                                                                    type="checkbox"
                                                                    id={
                                                                      "chd-meal01" +
                                                                      `${index}` +
                                                                      "-" +
                                                                      `${index1}` +
                                                                      "-" +
                                                                      `${index2}`
                                                                    }
                                                                    name={
                                                                      "select" +
                                                                      `${index}` +
                                                                      "-" +
                                                                      `${index1}`
                                                                    }
                                                                    value={"checkbox"+`${index}`+`${index1}`+`${index2}`}
                                                                    checked={("checkbox"+`${index}`+`${index1}`+`${index2}` === this.state.selectedCheckboxChild[index].data[index1].value) ? true : false}
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      this.childTravellerMealsDetailsChange(
                                                                        e,
                                                                        index,
                                                                        item2,
                                                                        item1,
                                                                        item.paxKey,
                                                                        item1.sectorCode
                                                                      );
                                                                      this.handleCheckboxChangeChild("checkbox"+`${index}`+`${index1}`+`${index2}`, index, index1, e, "Meals") 
                                                                      if(!e.target.checked){this.removeMealAmount(e, item.paxKey, item1.sectorCode)}
                                                                    }}
                                                                  />
                                                                  <label
                                                                    htmlFor={
                                                                      "chd-meal01" +
                                                                      `${index}` +
                                                                      "-" +
                                                                      `${index1}` +
                                                                      "-" +
                                                                      `${index2}`
                                                                    }
                                                                  >
                                                                    <img
                                                                      src={`${item2.mealUrl}`}
                                                                      className="img-fluid"
                                                                    />
                                                                    <p className="no-margin small-text truncate">
                                                                      {
                                                                        item2.name
                                                                      }
                                                                    </p>
                                                                    <p className="no-margin small-text">
                                                                      {
                                                                        item2.amount
                                                                      }
                                                                    </p>
                                                                  </label>
                                                                </div>
                                                              </div>
                                                            )
                                                          )}
                                                        </div>
                                                        {item1.objmealList.length > displayCount && !showLess && (
                                                          <a onClick={()=>{this.loadMoreItems(item1.objmealList.length)}}>Show More</a>
                                                        )}
                                                
                                                        {showLess && (
                                                          <a onClick={this.showLessItems}>Show Less</a>
                                                        )}
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                              <div
                                                className="tab-pane fade"
                                                id={
                                                  "childB-ex1-tabs-" +
                                                  `${index}`
                                                }
                                                role="tabpanel"
                                                aria-labelledby={
                                                  "childB-ex1-tab-" + `${index}`
                                                }
                                              >
                                                {/* Baggage inner tabs starting */}
                                                <ul
                                                  className="nav nav-tabs mb-3 segment-tabs"
                                                  id="seg"
                                                  role="tablist"
                                                >
                                                  {item.objbaggageseglist.map(
                                                    (item1, index1) => (
                                                      <div key={index1}>
                                                        <li
                                                          className="nav-item"
                                                          role="presentation"
                                                        >
                                                          <a
                                                            className={
                                                              "nav-link " +
                                                              (index1 == 0
                                                                ? "show active"
                                                                : "")
                                                            }
                                                            id={
                                                              "child-bag-seg-tab-" +
                                                              `${index1}` +
                                                              "-" +
                                                              `${index}`
                                                            }
                                                            data-bs-toggle="tab"
                                                            href={
                                                              "#child-bag-seg-tabs-" +
                                                              `${index1}` +
                                                              "-" +
                                                              `${index}`
                                                            }
                                                            role="tab"
                                                            aria-controls={
                                                              "child-bag-seg-tabs-" +
                                                              `${index1}` +
                                                              "-" +
                                                              `${index}`
                                                            }
                                                            aria-selected="true"
                                                          >
                                                            {item1.sectorCode}
                                                          </a>
                                                        </li>
                                                      </div>
                                                    )
                                                  )}
                                                </ul>
                                                <div
                                                  className="tab-content"
                                                  id="ex2-content"
                                                >
                                                  {item.objbaggageseglist.map(
                                                    (item1, index1) => (
                                                      <div key={index1}
                                                        className={
                                                          "tab-pane fade" +
                                                          (index1 == 0
                                                            ? "show active"
                                                            : "")
                                                        }
                                                        id={
                                                          "child-bag-seg-tabs-" +
                                                          `${index1}` +
                                                          "-" +
                                                          `${index}`
                                                        }
                                                        role="tabpanel"
                                                        aria-labelledby={
                                                          "child-bag-seg-tab-" +
                                                          `${index1}` +
                                                          "-" +
                                                          `${index}`
                                                        }
                                                      >
                                                        <div className="row">
                                                          {item1.objbaggageList.map(
                                                            (item2, index2) => (
                                                              <div className="col-lg-2 col-md-2 text-center" key={index2}>
                                                                <div className="meal-radio">
                                                                  <input
                                                                    type="checkbox"
                                                                    id={
                                                                      "chd-bag01" +
                                                                      `${index}` +
                                                                      "-" +
                                                                      `${index1}` +
                                                                      "-" +
                                                                      `${index2}`
                                                                    }
                                                                    name="selectChd"
                                                                    value={"checkbox"+`${index}`+`${index1}`+`${index2}`}
                                                                    checked={("checkbox"+`${index}`+`${index1}`+`${index2}` === this.state.selectedCheckboxChild[index].data[index1].baggage) ? true : false}
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      this.childTravellerBaggageDetailsChange(
                                                                        e,
                                                                        index,
                                                                        item2,
                                                                        item1,
                                                                        item.paxKey,
                                                                        item1.sectorCode
                                                                      );
                                                                      this.handleCheckboxChangeChild("checkbox"+`${index}`+`${index1}`+`${index2}`, index, index1, e, "Baggage") 
                                                                      if(!e.target.checked){this.removeBaggageAmount(e, item.paxKey, item1.sectorCode)}
                                                                    }}
                                                                  />
                                                                  <label
                                                                    htmlFor={
                                                                      "chd-bag01" +
                                                                      `${index}` +
                                                                      "-" +
                                                                      `${index1}` +
                                                                      "-" +
                                                                      `${index2}`
                                                                    }
                                                                  >
                                                                    <img
                                                                      src="assets/img/baggage/baggage.svg"
                                                                      width={80}
                                                                    />
                                                                    <p className="no-margin small-text">
                                                                      {
                                                                        item2.name
                                                                      }
                                                                    </p>
                                                                    <p className="no-margin small-text">
                                                                      {
                                                                        item2.amount
                                                                      }
                                                                    </p>
                                                                  </label>
                                                                </div>
                                                              </div>
                                                            )
                                                          )}
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              </div>

                                              {/*<div class="tab-pane fade" id="ex1-tabs-3" role="tabpanel" aria-labelledby="ex1-tab-3">
    Tab 3 content
  </div>*/}
                                            </div>

                                            {/* Tabs content */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                        )}

                      {this.state.InfpricingPaxListResult.length > 0 && (
                        <div className="t-details-area">
                          <div className="pax-head">
                            <h4 className="no-margin">Infant</h4>
                            {this.state.InfpricingPaxListResult.map(
                              (item, index) => (
                                <div className="pax-info" key={index}>
                                  <div className="passenger-section mb-2">
                                    <div className="row align-items-center">
                                      <div className="col-md-1 col-lg-1 p-bg">
                                        <h6 className="no-margin">Infant {index + 1}</h6>
                                      </div>
                                      <div className="col-md-2 col-lg-2">
                                        <select
                                          name={`title`}
                                          id={`titleSelectBox${index}`}
                                          className="form-control"
                                          // value={
                                          //   this.state[
                                          //   `titleSelectBoxName${index}`
                                          //   ]
                                          // }
                                          onChange={(event) => {this.AdtDetailshandleChange(event, index, "TL", item.paxKey); this.CheckValidationByFunction(event, index,"title", "", this.state.InfantFieldValidations,this.state.allPaxDataList, "Inf")}}
                                        >
                                          <option value="">Title</option>
                                          <option value="Mstr">Mstr</option>
                                          <option value="Miss">Miss</option>
                                          {/* <option value="Mrs">Mrs</option> */}
                                        </select>
                                        { (!this.state.InfantFieldValidations[index].title && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i> Title required </div>}
                                      </div>
                                      <div className="col-md-3 col-lg-3">
                                        <input
                                          type="text"
                                          name="firstName"
                                          value={firstName}
                                          onChange={(event) => {this.AdtDetailshandleChange(event, index, "FN", item.paxKey); this.CheckValidationByFunction(event, index,"fName", "", this.state.InfantFieldValidations,this.state.allPaxDataList, "Inf")}}
                                          className="form-control"
                                          placeholder="First Name"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="First Name / Given Name"
                                        />
                                        { (!this.state.InfantFieldValidations[index].firstName && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i> First name required </div>}
                                      </div>
                                      <div className="col-md-3 col-lg-3">
                                        <input
                                          type="text"
                                          name="lastName"
                                          value={lastName}
                                          onChange={(event) => {this.AdtDetailshandleChange(event, index, "LN", item.paxKey); this.CheckValidationByFunction(event, index,"lName", "", this.state.InfantFieldValidations,this.state.allPaxDataList, "Inf")}}
                                          className="form-control"
                                          placeholder="Last Name"
                                        />
                                        { (!this.state.InfantFieldValidations[index].lastName && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i> Last name required </div>}
                                      </div>
                                      <div className="col-md-3 col-lg-3">
                                        <DatePicker
                                        placeholderText="Date Of Birth"
                                          renderCustomHeader={({
                                            date,
                                            changeYear,
                                            changeMonth,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled,
                                          }) => (
                                            <div
                                              style={{
                                                margin: 10,
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <button
                                                onClick={decreaseMonth}
                                                disabled={
                                                  prevMonthButtonDisabled
                                                }
                                              >
                                                {"<"}
                                              </button>
                                              <select
                                                value={date.getFullYear()}
                                                onChange={({
                                                  target: { value },
                                                }) => changeYear(value)}
                                              >
                                                {this.state.InfantDobYears.map(
                                                  (option) => (
                                                    <option
                                                      key={option}
                                                      value={option}
                                                    >
                                                      {option}
                                                    </option>
                                                  )
                                                )}
                                              </select>

                                              <select
                                                value={
                                                  this.state.months[
                                                  date.getMonth()
                                                  ]
                                                }
                                                onChange={({
                                                  target: { value },
                                                }) =>
                                                  changeMonth(
                                                    this.state.months.indexOf(
                                                      value
                                                    )
                                                  )
                                                }
                                              >
                                                {this.state.months.map(
                                                  (option) => (
                                                    <option
                                                      key={option}
                                                      value={option}
                                                    >
                                                      {option}
                                                    </option>
                                                  )
                                                )}
                                              </select>

                                              <button
                                                onClick={increaseMonth}
                                                disabled={
                                                  nextMonthButtonDisabled
                                                }
                                              >
                                                {">"}
                                              </button>
                                            </div>
                                          )}
                                          autoComplete="off"
                                          value={this.state.InfantDatesArray[index].dob}
                                          name="dateofBirth"
                                          onChange={(event) => {this.AdtDetailshandleChange(event, index, "dateofBirth", item.paxKey, "Infant"); this.CheckValidationByFunction(event, index, "dateType", "dateofBirth",this.state.InfantFieldValidations,this.state.allPaxDataList, "Inf")}}
                                          maxDate={new Date()}
                                          minDate={this.addYears(new Date(this.state.departureDate), 2)}
                                          // onChange={(date) => {
                                          //   this.setState({ startDate: date });
                                          // }}
                                        />
                                        { (!this.state.InfantFieldValidations[index].dateofBirth && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i> DOB required </div>}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="pt-1 add-on-area mb-3">
                                    <a
                                      className="mr-20 no-underline"
                                      data-bs-toggle="collapse"
                                      href={`#collapsePassport3${index}`}
                                      role="button"
                                      aria-expanded="false"
                                      aria-controls="collapsePassport3"
                                    >
                                      Passport Details
                                    </a>
                                    <div
                                      className="collapse"
                                      id={`collapsePassport3${index}`}
                                    >
                                      <div className="card card-body">
                                        <h4>Passport Details</h4>
                                        <button type="button"
                                      className="btn btn-close collapse-close"
                                      data-bs-toggle="collapse"
                                      href={`#collapsePassport3${index}`}
                                      role="button"
                                      aria-expanded="false"
                                      aria-controls="collapsePassport3"
                                    >
                                   
                                    </button>
                                        <div className="row">
                                          <div className="col-lg-4 col-md-4 col-4">
                                          <label>Passport Number</label>
                                            <input
                                              type="text"
                                              className="form-control mb-3"
                                              placeholder="Passport number"
                                              autoComplete="off"
                                              name={`documentNumber`}
                                              value={passportNumber}
                                              onChange={(event) => {this.AdtDetailshandleChange(event, index,"", item.paxKey); this.CheckValidationByFunction(event, index,"dNumber", "", this.state.InfantFieldValidations,this.state.allPaxDataList, "Inf")}}
                                            />
                                            { (!this.state.InfantFieldValidations[index].documentNumber && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i> Passport Number required </div>}
                                          </div>
                                          <div className="col-lg-4 col-md-4 col-4">
                                          <label>Nationality</label>
                                            <select
                                              name="nationality"
                                              id="country"
                                              className="form-control mb-3"
                                              value={selectedCountry}
                                              // onChange={(e) => {
                                              //   this.countryChange(e);
                                              // }}
                                              onChange={(event) => this.AdtDetailshandleChange(event, index,"country", item.paxKey)}
                                            >
                                              <option>Nationality</option>
                                              {this.state.Countries.map(
                                                (country) => (
                                                  <option
                                                    key={country.id}
                                                    value={country.countryCode}
                                                    selected={country.id === 101 ? true : false}
                                                  >
                                                    {country.name}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </div>
                                          {/* <div className="col-lg-4 col-md-4 col-4">
                                          <label>Date of birth</label>
                                            <DatePicker
                                              renderCustomHeader={({
                                                date,
                                                changeYear,
                                                changeMonth,
                                                decreaseMonth,
                                                increaseMonth,
                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled,
                                              }) => (
                                                <div
                                                  style={{
                                                    margin: 10,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <button
                                                    onClick={decreaseMonth}
                                                    disabled={
                                                      prevMonthButtonDisabled
                                                    }
                                                  >
                                                    {"<"}
                                                  </button>
                                                  <select
                                                    value={date.getFullYear()}
                                                    onChange={({
                                                      target: { value },
                                                    }) => changeYear(value)}
                                                  >
                                                    {this.state.years.map(
                                                      (option) => (
                                                        <option
                                                          key={option}
                                                          value={option}
                                                        >
                                                          {option}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>

                                                  <select
                                                    value={
                                                      this.state.months[
                                                      date.getMonth()
                                                      ]
                                                    }
                                                    onChange={({
                                                      target: { value },
                                                    }) =>
                                                      changeMonth(
                                                        this.state.months.indexOf(
                                                          value
                                                        )
                                                      )
                                                    }
                                                  >
                                                    {this.state.months.map(
                                                      (option) => (
                                                        <option
                                                          key={option}
                                                          value={option}
                                                        >
                                                          {option}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>

                                                  <button
                                                    onClick={increaseMonth}
                                                    disabled={
                                                      nextMonthButtonDisabled
                                                    }
                                                  >
                                                    {">"}
                                                  </button>
                                                </div>
                                              )}
                                              selected={this.state.startDate}
                                              name="dateofBirth"
                                              onChange={(event) => this.AdtDetailshandleChange(event, index, "nationality", item.paxKey)}
                                            />
                                          </div> */}
                                          <div className="col-lg-4 col-md-4 col-4">
                                          <label>Country of issue</label>
                                            <select
                                              name="countryofIssue"
                                              id="countryIssue"
                                              className="form-control mb-3"
                                              value={selectedCountryIssue}
                                              onChange={(event) => this.AdtDetailshandleChange(event, index,"countryOfIssue", item.paxKey)}
                                            >
                                              <option>Country of issue</option>
                                              {this.state.Countries.map(
                                                (country) => (
                                                  <option
                                                    key={country.id}
                                                    value={country.countryCode}
                                                    selected={country.id === 101 ? true : false}
                                                  >
                                                    {country.name}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </div>
                                          <div className="col-lg-4 col-md-4 col-4">
                                          <label>Date of expiry</label>
                                            <DatePicker
                                            minDate={new Date()}
                                              renderCustomHeader={({
                                                date,
                                                changeYear,
                                                changeMonth,
                                                decreaseMonth,
                                                increaseMonth,
                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled,
                                              }) => (
                                                <div
                                                  style={{
                                                    margin: 10,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <button
                                                    onClick={decreaseMonth}
                                                    disabled={
                                                      prevMonthButtonDisabled
                                                    }
                                                  >
                                                    {"<"}
                                                  </button>
                                                  <select
                                                    value={date.getFullYear()}
                                                    onChange={({
                                                      target: { value },
                                                    }) => changeYear(value)}
                                                  >
                                                    {this.state.doeYears.map(
                                                      (option) => (
                                                        <option
                                                          key={option}
                                                          value={option}
                                                        >
                                                          {option}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>

                                                  <select
                                                    value={
                                                      this.state.months[
                                                      date.getMonth()
                                                      ]
                                                    }
                                                    onChange={({
                                                      target: { value },
                                                    }) =>
                                                      changeMonth(
                                                        this.state.months.indexOf(
                                                          value
                                                        )
                                                      )
                                                    }
                                                  >
                                                    {this.state.months.map(
                                                      (option) => (
                                                        <option
                                                          key={option}
                                                          value={option}
                                                        >
                                                          {option}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>

                                                  <button
                                                    onClick={increaseMonth}
                                                    disabled={
                                                      nextMonthButtonDisabled
                                                    }
                                                  >
                                                    {">"}
                                                  </button>
                                                </div>
                                              )}
                                              value={this.state.InfantDatesArray[index].doe}
                                              onChange={(event) => {this.AdtDetailshandleChange(event, index, "dateOfExpiry", item.paxKey,"Infant"); this.CheckValidationByFunction(event, index, "dateType", "dateOfExpiry",this.state.InfantFieldValidations,this.state.allPaxDataList, "Inf")}}
                                            />
                                             { (!this.state.InfantFieldValidations[index].dateOfExpiry && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i> DOE required </div>}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )}
                      </div>
                    </div>
                  </div>
                </div>
                {/*traveller tab ends*/}
                <div className="accordion addon">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="panel-heading">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#contact-collapse"
                        aria-expanded="true"
                        aria-controls="contact-collapse"
                      >
                        <img src="assets/img/contact.svg" width={35} />{" "}
                        Traveller Contact{" "}
                        <span className="small-text text-grey font-400">
                          {" "}
                          (Your ticket and flight info will be sent here)
                        </span>
                      </button>
                    </h2>
                    <div
                      id="contact-collapse"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panel-heading"
                    >
                      <div className="accordion-body">
                        <div className="p-details-area">
                          <div className="row">
                            <div className="col-lg-4 col-md-4">
                              <div className="input-group">
                                <select className="country-code" value={this.state.prefixContactNumber}  name="prefixContactNumber" onChange={(e)=>this.selectionFieldsChange(e)} id="prefixContactNumber" >
                                  <option value="+91">+91</option>
                                  <option value="+971">+971</option>
                                  <option value="+944">+944</option>
                                  <option value="+944">+944</option>
                                </select>
                                {/* <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Contact Number"
                                  aria-label="Contact Number"
                                  aria-describedby="basic-addon1"
                                  name="contactNumber"
                                  value={this.state.contactNumber}
                                  onChange={this.handleContactNumberChange}
                                  required={this.state.isRequiredContactNumber ? "required" : ""}
                                  //onChange={(e) => this.inputChangeHandler(e)} 
                                /> */}
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Contact Number"
                                aria-label="Contact Number"
                                aria-describedby="basic-addon1"
                                name="contactNumber"
                                value={this.state.contactNumber}
                                onChange={(event) => {this.handleContactNumberChange(event); this.CommonFieldCheck(event)}}
                              />
                              
                              {/* {error && <p style={{ color: 'red' }}>{error}</p>}
                              {this.state.errorMessageContactNumber && (
                              <div className="requiredError">
                              {this.state.errorMessageContactNumber} 
                              </div>
                              )}*/}
                              </div>
                              { (!this.state.CommonFieldValidations.contactNumber && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i> Valid Contact Number required </div>}
                            </div>
                            <div className="col-lg-4 col-md-4">
                            <div className="did-floating-label-content">
                              <div className="input-group">
                                <select className="country-code"  value={this.state.prefixAltrContactNumber}  name="prefixAltrContactNumber" onChange={(e)=>{this.selectionFieldsChange(e)}} id="prefixAltrContactNumber" >
                                  <option value="+91">+91</option>
                                  <option value="+971">+971</option>
                                  <option value="+944">+944</option>
                                  <option value="+944">+944</option>
                                </select>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Alternate Contact Number"
                                  aria-label="Contact Number"
                                  aria-describedby="basic-addon1"
                                  name="alternateContactNumber"
                                  onChange={(event) => {this.inputChangeHandler(event); this.CommonFieldCheck(event)}}
                                />
                                
                              </div>
                              { (!this.state.CommonFieldValidations.alternateContactNumber && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i> Valid  Alternate Number required </div>}
                               </div>
                            </div>
                            <div className="col-lg-4 col-md-4 mb-2">
                              <input
                                type="text"
                                className="form-control email-input"
                                placeholder="Email"
                                aria-label="Email"
                                name="contactEmail"
                                value={this.state.contactEmail}
                                onChange={(event) => {this.handleContactEmailChange(event); this.CommonFieldCheck(event)}}
                                required={this.state.isRequiredContactEmail ? "required" : ""}
                                //onChange={(e) => this.inputChangeHandler(e)} 
                              />
                              {/* {this.state.errorMessageContactEmail && (
                              <div style={{ color: "red", marginTop: "5px" }}>
                              {this.state.errorMessageContactEmail}
                              </div>
                              )}  */}
                              { (!this.state.CommonFieldValidations.contactEmail && this.state.isFormSubmitted) && <div className="error-message drop-in"><i className="material-icons">&#xe002;</i> Valid  Email ID required </div>}
                            </div>
                          </div>
                          <div className="row pt-2 align-items-center">
                            {/* <div className="col-lg-6 col-md-6">
                              <h6 className="no-margin font-500">
                               I have a GST number (optional)
                              </h6>
                            </div> */}
                            <div className="col-lg-3 col-md-3">
                              <a
                                className="btn btn-border full-width"
                                data-bs-toggle="collapse"
                                href="#collapseGst"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseGst"
                              >
                              Add GST (optional)
                              </a>
                            </div>
                            <div className="collapse" id="collapseGst">
                              <div className="card card-body">
                                <h4>GST Details</h4>
                                <button
                                className="btn btn-close collapse-closegst"
                                data-bs-toggle="collapse"
                                href="#collapseGst"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseGst"
                              >
                            
                              </button>
                                <div className="row">
                                  <div className="col-lg-6 col-md-6 col-6">
                                    <div className="did-floating-label-content">
                                 
                                    <input
                                      type="text" placeholder=" "
                                      className="did-floating-input form-control mb-3"
                                      // placeholder="GST Number"
                                      name="gstNumber"
                                      onChange={(e) => this.gstInputChangeHandler(e)}
                                    />
                                     <label className="did-floating-label">GST Number</label>
                                      </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-6">
                                  <div className="did-floating-label-content">
                                 
                                    <input
                                      type="text" placeholder=" "
                                      className="did-floating-input form-control mb-3"
                                      // placeholder="Company Name"
                                      name="companyName"
                                      onChange={(e) => this.gstInputChangeHandler(e)}
                                    />
                                     <label className="did-floating-label">Company Name</label>
                                  </div>
                                    </div>
                                  <div className="col-lg-6 col-md-4 col-6">
                                  <div className="did-floating-label-content">
                                 
                                    <input
                                      type="text" placeholder=" "
                                      className="did-floating-input form-control mb-3"
                                      //placeholder="Email ID"
                                      name="email"
                                      onChange={(e) => this.gstInputChangeHandler(e)}
                                    />
                                     <label className="did-floating-label">Email ID</label>
                                  </div>
                                   </div>
                                  <div className="col-lg-6 col-md-4 col-6">
                                  <div className="did-floating-label-content">
                                 
                                    <input
                                      type="text" placeholder=" "
                                      className="did-floating-input form-control mb-3"
                                      //placeholder="Mobile Number"
                                      name="mobile"
                                      onChange={(e) => this.gstInputChangeHandler(e)}
                                    />
                                     <label className="did-floating-label">Mobile Number</label>
                                  </div>
                                    </div>
                                  <div className="col-lg-6 col-md-4 col-6">
                                  <div className="did-floating-label-content">
                                 
                                    <input
                                      type="text" placeholder=" "
                                      className="did-floating-input form-control mb-3"
                                      //placeholder="Address"
                                      name="address"
                                      onChange={(e) => this.gstInputChangeHandler(e)}
                                    />
                                     <label className="did-floating-label">Address</label>
                                  </div>
                                   </div>
                                  <div className="col-lg-6 col-md-4 col-6">
                                  <div className="did-floating-label-content">
                                    <input
                                      type="text" placeholder=" "
                                      className="did-floating-input form-control mb-3"
                                      //placeholder="City"
                                      name="city"
                                      onChange={(e) => this.gstInputChangeHandler(e)}
                                    />
                                     <label className="did-floating-label">City</label>
                                    </div>  
                                  </div>
                                  <div className="col-lg-6 col-md-4 col-6">
                                  <div className="did-floating-label-content">
                                   
                                    <input
                                      type="text" placeholder=" "
                                      className="did-floating-input form-control mb-3"
                                      //placeholder="Pincode"
                                      name="pincode"
                                      onChange={(e) => this.gstInputChangeHandler(e)}
                                    />
                                     <label className="did-floating-label">Pincode</label>
                                  </div>
                                   </div>
                                  {/* <div className="col-lg-6 col-md-4 col-6">
                                  <label>State</label>
                                    <select
                                              name="state"
                                              placeholder="State"
                                              id="state"
                                              className="form-control mb-3"
                                              value={selectedState}
                                              onChange={(e) => this.gstInputChangeHandler(e)}
                                            >
                                              {this.state.IndianStates.map(
                                                (state) => (
                                                  <option
                                                    key={state.id}
                                                    value={state.state_code}
                                                  >
                                                    {state.name}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                  </div> */}
                                  <div className="col-lg-12 col-md-12">
                                    <p className="small-text text-danger">
                                      Disclaimer: - Please ensure that all the
                                      details filled by you are correct. In case
                                      the details are incorrect, you will not be
                                      able to claim input tax credit. Details
                                      cannot be amended later.
                                    </p>
                                  </div>
                              
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*Addon tab ends*/}
                <div className="row align-items-center mb-4">
                  <div className="col-lg-6 col-md-6 acc_box">
                    <p className="no-margin small-text">
                      All fares &amp; seats are subject to availability at the
                      time of booking
                    </p>
                  </div>

                  {this.state.checkoutRequest !== null && (
                    <div className="col-lg-6 col-md-6">
                      {this.state.isLogin == true && (
                      <button
                        className="btn btn-primary full-width"
                        onClick={() =>
                          this.getRePricingDetailsResult(
                            this.state.PricingDetailsResult.itinId,
                            this.state.PricingDetailsResult.fareId,
                            this.state.PricingDetailsResult.providerCode,
                            this.state.PricingDetailsResult.itinIdR,
                            this.state.PricingDetailsResult.fareIdR,
                            this.state.PricingDetailsResult.providerCodeR,
                            this.state.RePricingPaxlist
                          )
                        }
                        // data-bs-toggle="offcanvas"
                        // data-bs-target="#offcanvasConfirm"
                        // aria-controls="offcanvasConfirm"
                        //onClick={this.handleShow}
                      >
                        Continue
                      </button>
                      )}
                      {/* <button
                        className="btn btn-primary full-width" data-bs-toggle="modal" data-bs-target="#non-user">Continue</button> */}


<div>
{this.state.isLogin == false && (
  <button type="button" onClick={this.ModalHideShow} className="btn btn-primary full-width" >
    Continue
  </button>
)}
 
</div>
<ToastContainer />






                        
                    </div>
                  )}

                  {/* {this.state.IRTcheckoutRequest !== null && (
                    <div className="col-lg-6 col-md-6">
                      <button
                        className="btn btn-primary full-width"
                        onClick={() =>
                          this.getRePricingDetailsResult(
                            this.state.PricingDetailsResult.itinId,
                            this.state.PricingDetailsResult.fareId,
                            this.state.PricingDetailsResult.providerCode,
                            this.state.PricingDetailsResult.itinIdR,
                            this.state.PricingDetailsResult.fareIdR,
                            this.state.PricingDetailsResult.providerCodeR,
                            this.state.RePricingPaxlist
                          )
                        }
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasConfirm"
                        aria-controls="offcanvasConfirm"
                      >
                        Continue1
                      </button>
                    </div>
                  )} */}
                </div>
              </div>
              <div className="col-lg-3 col-md-3">
                <div className="right-fix">
                <div className="row">
                  <div className="col-md-6 col-lg-6 pb-2">
                    <h4 className="no-margin">Price Summary</h4>
                  </div>
                  <div className="col-md-6 col-lg-6 pb-2">
                    <p className="small-text text-right">
                      <a
                        href="#"
                        className="no-underline"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                      >
                        View fare rules
                      </a>
                    </p>
                  </div>
                  <Offcanvas className="offcanvas-size-xl" show={this.state.show} onHide={this.handleClose} placement="end" backdrop="static" onEscapeKeyDown={this.handleClose}>
          <Offcanvas.Header>
          <div className="confirm-header">
                        <h5 id="ConfirmLabel">
                          Total Fare :{" "}
                          <span className="text-red">   
                          {Math.round(this.state.RePricingDetailsResult.finalAmount)}
                          </span>
                        </h5>
                      </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
          <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="t-details-area">
                            {this.state.ReobjseglistResult.map((item, index) => (
                              <div className="row align-items-center" key={index}>
                                <div className="airline-company col-lg-2 col-md-2 text-center">
                                  <img
                                    src={`https://agents.alhind.com/images/logos/${item.airlineCode}.gif`}
                                    width={40}
                                  />
                                  <p className="small-text">
                                    {item.airlineName}
                                    <br />
                                    {item.airlineCode}
                                  </p>
                                </div>
                                <div className="route-details-area col-lg-2 col-md-2 text-right">
                                  <div className="row">
                                    <p className="small-text">
                                      {item.departureAirportCode}
                                    </p>
                                    <h6 className="time-text">
                                      {item.departureTime}
                                    </h6>
                                    <p className="small-text text-grey">
                                      {item.departureDate}
                                      <br />
                                      {item.departureCity}
                                    </p>
                                  </div>
                                </div>
                                <div className="travel-time col-lg-3 col-md-3 text-center">
                                  <p className="small-text text-grey">
                                    {item.travelDuration}
                                  </p>
                                  <span>
                                    <img src="assets/img/arrow.png" />
                                  </span>
                                  {/* <p className="small-text text-grey">
                                    Non-stop123
                                  </p> */}
                                </div>
                                <div className="route-details-area col-lg-2 col-md-2 text-left">
                                  <div className="row">
                                    <p className="small-text">
                                      {item.arrivalAirportCode}
                                    </p>
                                    <h6 className="time-text">
                                      {item.arrivalTime}
                                    </h6>
                                    <p className="small-text text-grey">
                                      {item.arrivalDate}
                                      <br />
                                      {item.arrivalCity}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-3 col-md-3 airline-price-area me-auto">
                                  <div className="d-flex align-items-center justify-content-center">
                                    <div className="flex-row">
                                      <p className="small-text">
                                        {item.airlineFlightClass}
                                      </p>
                                      {item.cabinBaggage.length > 0 && (
                                      <p className="small-text">
                                        <img
                                          src="assets/img/baggage.svg"
                                          width={15}
                                        />
                                        Adult: {item.cabinBaggage}
                                      </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="row">
                          <div>
                            <h5 className="no-margin">Retail Saver</h5>
                          </div>
                          <div className="col-md-6 col-lg-6">
                            <span className="no-margin">    
                              Base Fare (
                              {this.state.RePricingDetailsResult.adult +
                                this.state.RePricingDetailsResult.child +
                                this.state.RePricingDetailsResult.infant}{" "}
                              Travellers)
                            </span>
                          </div>
                          <div className="col-md-6 col-lg-6 text-right">
                            <p
                              className="no-margin"
                              data-bs-toggle="collapse"
                              href="#priceCollapse"
                              role="button"
                              aria-expanded="false"
                              aria-controls="priceCollapse"
                            >
                              ₹{" "}    
                              {Math.round(this.state.RePricingDetailsResult.totalBasic)}{" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={10}
                                height={10}
                                fill="currentColor"
                                className="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                            </p>
                          </div>
                          <div className="collapse show" id="priceCollapse">
                            <div className="card card-body confirm-card-body">
                              <table className="table" style={{ width: '100%' }}>
                                <tr>
                                  <td className="small-text">
                                     Adult x{" "}
                                    {this.state.RePricingDetailsResult.adult}
                                  </td>
                                  <td className="small-text" align="right">
                                  ₹{" "}    
                                  {Math.round(this.state.RePricingDetailsResult.adult * this.state.RePricingDetailsResult.adultBasic)}
                                  </td>
                                </tr>
                                {this.state.RePricingDetailsResult.child > 0 && (
                                <tr>
                                  <td className="small-text">
                                  Child x{" "}
                                    {this.state.RePricingDetailsResult.child}
                                  </td>
                                  <td className="small-text" align="right">
                                  ₹{" "}    
                                  {Math.round(this.state.RePricingDetailsResult.child * this.state.RePricingDetailsResult.childBasic)}
                                  </td>
                                </tr>
                                )}
                                {this.state.RePricingDetailsResult.infant > 0 && (
                                <tr>
                                  <td className="small-text">
                                  Infant x{" "}
                                    {this.state.RePricingDetailsResult.infant}
                                  </td>
                                  <td className="small-text" align="right">
                                  ₹{" "}    
                                  {Math.round(this.state.RePricingDetailsResult.infant * this.state.RePricingDetailsResult.infantBasic)}
                                  </td>
                                </tr>
                                )}
                              </table>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-6">
                            <span className="">Taxes & Fees</span>
                          </div>
                          <div className="col-md-6 col-lg-6 text-right">
                            <p
                              className="no-margin"
                              data-bs-toggle="collapse"
                              href="#taxCollapse"
                              role="button"
                              aria-expanded="false"
                              aria-controls="priceCollapse"
                            >   
                              ₹ {Math.round(this.state.RePricingDetailsResult.totalTax)}{" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={10}
                                height={10}
                                fill="currentColor"
                                className="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                            </p>
                          </div>
                          <div className="collapse show" id="taxCollapse">
                            <div className="card card-body confirm-card-body">
                              {this.state.ReTaxListResult.map((item, index) => {
                                //totalAmount += item.amount;
                                return (
                                  <div className="row" key={index}>
                                    <div className="col-lg-6 col-md-6">
                                      <p className="small-text no-margin">
                                        {item.taxCode}
                                      </p>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                      <p className="small-text no-margin text-right">                                      
                                        ₹ {Math.round(item.amount)}
                                      </p>
                                    </div>

                                    {/* <div className="col-lg-6 col-md-6"><p className="small-text no-margin">GST</p></div>
                  <div className="col-lg-6 col-md-6"><p className="small-text no-margin text-right">₹ 100</p></div>
                  <div className="col-lg-6 col-md-6"><p className="small-text no-margin">Convinient Fee</p></div>
                  <div className="col-lg-6 col-md-6"><p className="small-text no-margin text-right">₹ 100</p></div> */}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-6">
                            <span className="no-margin">Baggage & Meals</span>
                          </div>
                          <div className="col-md-6 col-lg-6 text-right">
                            <span className="no-margin">₹ {Math.round(this.state.RePricingDetailsResult.ssrTotal)}</span>
                          </div>

                          <div className="col-md-6 col-lg-6">
                            <span className="no-margin">Total Fare</span>
                          </div>
                          <div className="col-md-6 col-lg-6 text-right">
                            <span className="no-margin">   
                              ₹ {Math.round(this.state.RePricingDetailsResult.totalResponseAmount)}
                            </span>
                          </div>
                          <div className="col-md-6 col-lg-6">
                            <span className="no-margin">Discount</span>
                          </div>
                          <div className="col-md-6 col-lg-6 text-right">
                            <span className="no-margin">     
                              ₹ {Math.round(this.state.RePricingDetailsResult.discountAmount)}
                            </span>
                          </div>
                          <div className="col-md-6 col-lg-6">
                            <h4 className="no-margin">You Pay</h4>
                          </div>
                          <div className="col-md-6 col-lg-6 text-right mb-3">
                            <h4 className="no-margin">
                              ₹{" "}    
                              {Math.round(this.state.RePricingDetailsResult.finalAmount)}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div>
                        <h4>Are you sure you want to continue the booking ?</h4>
                      </div>
                      <div className="row">
                      <div className="col-md-6 col-lg-6">
                          <button className="btn btn-secondary confirm-btn" onClick={this.handleClose}>
                            CANCEL
                          </button>
                        </div>
                        {this.state.checkoutRequest !== null && (
                          <div className="col-md-6 col-lg-6">
                            {/* <button
                              className="btn btn-primary confirm-btn"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                              onClick={() =>
                                this.getBookingResponseResult(
                                  this.state.RePricingDetailsResult.itinId,
                                  this.state.RePricingDetailsResult.fareId,
                                  this.state.RePricingDetailsResult.providerCode,
                                  this.state.RePricingDetailsResult.itinIdR,
                                  this.state.RePricingDetailsResult.fareIdR,
                                  this.state.RePricingDetailsResult.providerCodeR,
                                  this.state.contactNumber,
                                  this.state.alternateContactNumber,
                                  this.state.contactEmail,
                                  this.state.BookingPaxdetails,
                                  this.state.BookingGstDetails
                                )
                              }
                            >
                              YES
                            </button> */}

                            <Button variant="primary" className="confirm-btn" disabled={this.isBookingBtnPressed} onClick={() =>
                                this.getBookingResponseResult(
                                  this.state.RePricingDetailsResult.itinId,
                                  this.state.RePricingDetailsResult.fareId,
                                  this.state.RePricingDetailsResult.providerCode,
                                  this.state.RePricingDetailsResult.itinIdR,
                                  this.state.RePricingDetailsResult.fareIdR,
                                  this.state.RePricingDetailsResult.providerCodeR,
                                  this.state.contactNumber,
                                  this.state.alternateContactNumber,
                                  this.state.contactEmail,
                                  this.state.BookingPaxdetails,
                                  this.state.BookingGstDetails
                                )
                              }>
                                { this.isBookingBtnPressed && <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                      />}  
                                            YES
                          </Button>

                          </div>
                        )}

                        {/* {this.state.IRTcheckoutRequest !== null && (
                          <div className="col-md-6 col-lg-6">
                            <button
                              className="btn btn-primary confirm-btn"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                              onClick={() =>
                                this.getBookingResponseResult(
                                  this.state.RequestFields.itinId,
                                  this.state.RequestFields.fareId,
                                  this.state.RequestFields.providerCode,
                                  this.state.RequestFields.itinIdR,
                                  this.state.RequestFields.fareIdR,
                                  this.state.RequestFields.providerCodeR,
                                  this.state.contactNumber,
                                  this.state.alternateContactNumber,
                                  this.state.RequestFields.contactEmail,
                                  this.state.BookingPaxdetails,
                                  this.state.BookingGstDetails
                                )
                              }
                            >
                              YES1
                            </button>
                          </div>
                        )} */}

                       
                      </div>
          </Offcanvas.Body>
        </Offcanvas>




                  
                 
                </div>
                <div
                  className="offcanvas offcanvas-end offcanvas-size-xl"
                  tabIndex={-1}
                  id="offcanvasRight"
                  aria-labelledby="offcanvasRightLabel"
                >
                  <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel">Fare Rules</h5>
                    <button
                      type="button"
                      className="btn-close text-reset"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    />
                  </div>
                  <div className="offcanvas-body">
                    <table className="table table-bordered">
                      <thead>
                      <tr>
                        <td>
                          <strong>Time</strong>
                        </td>
                        <td>
                          <strong>Airline Cancellation Fess</strong>
                        </td>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>DURATION (Prior to the date/time of departure)</td>
                        <td>PER PASSENGER</td>
                      </tr>
                      <tr>
                        <td>0 hour to 2 hours</td>
                        <td>Non Refundable</td>
                      </tr>
                      <tr>
                        <td>&gt; 2 hours</td>
                        <td>4200</td>
                      </tr>
                      </tbody>
                    </table>
                    <table className="table table-bordered">
                      <thead>
                      <tr>
                        <td>
                          <strong>Time</strong>
                        </td>
                        <td>
                          <strong>Airline Cancellation Fess</strong>
                        </td>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>DURATION (Prior to the date/time of departure)</td>
                        <td>PER PASSENGER</td>
                      </tr>
                      <tr>
                        <td>0 hour to 2 hours</td>
                        <td>Non Refundable</td>
                      </tr>
                      <tr>
                        <td>&gt; 2 hours</td>
                        <td>4200</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="price_area">
                  <h4>
                    ₹{" "}
                    {this.state.ApiPricingResponse.finalAmount+this.state.sumMealAnBaggage}
                  </h4>
                </div>
                <div className="t-details-area price-box mb-4">
                  <div className="row">
                    <div className="bottom-border">
                      <span className="small-text">Retail Saver:</span>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <span className="small-text">
                        Base Fare (
                        {this.state.PricingDetailsResult.adult +
                          this.state.PricingDetailsResult.child +
                          this.state.PricingDetailsResult.infant}{" "}
                        Travellers)
                      </span>
                    </div>
                    <div className="col-md-6 col-lg-6 text-right">
                      <p
                        className="no-margin"
                        data-bs-toggle="collapse"
                        href="#priceCollapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="priceCollapse"
                      >
                        ₹{" "}
                        {this.state.ApiPricingResponse.totalBasic}{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={10}
                          height={10}
                          fill="currentColor"
                          className="bi bi-chevron-down"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                      </p>
                    </div>
                    <div className="collapse" id="priceCollapse">
                      <div className="card card-body">
                      <table className="table" style={{ width: '100%' }}>
                                <tr>
                                  <td className="small-text">
                                     Adult x{" "}
                                    {this.state.PricingDetailsResult.adult}
                                  </td>
                                  <td className="small-text" align="right">
                                  ₹{" "}
                                    {this.state.PricingDetailsResult.adult *
                                      this.state.ApiPricingResponse
                                        .adultBasic}
                                  </td>
                                </tr>
                                {this.state.PricingDetailsResult.child > 0 && (
                                <tr>
                                  <td className="small-text">
                                  Child x{" "}
                                    {this.state.PricingDetailsResult.child}
                                  </td>
                                  <td className="small-text" align="right">
                                  ₹{" "}
                                    {this.state.PricingDetailsResult.child *
                                      this.state.ApiPricingResponse
                                        .childBasic}
                                  </td>
                                </tr>
                                )}
                                {this.state.PricingDetailsResult.infant > 0 && (
                                <tr>
                                  <td className="small-text">
                                  Infant x{" "}
                                    {this.state.PricingDetailsResult.infant}
                                  </td>
                                  <td className="small-text" align="right">
                                  ₹{" "}
                                    {this.state.PricingDetailsResult.infant *
                                      this.state.ApiPricingResponse
                                        .infantBasic}
                                  </td>
                                </tr>
                                )}
                              </table>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <span className="small-text">Taxes & Fees</span>
                    </div>
                    <div className="col-md-6 col-lg-6 text-right">
                      <p
                        className="no-margin"
                        data-bs-toggle="collapse"
                        href="#taxCollapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="priceCollapse"
                      >
                        ₹ {this.state.ApiPricingResponse.totalTax}{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={10}
                          height={10}
                          fill="currentColor"
                          className="bi bi-chevron-down"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                      </p>
                    </div>
                    <div className="collapse" id="taxCollapse">
                      <div className="card card-body">
                        {this.state.TaxListResult.map((item, index) => (
                          <div className="row" key={index}>
                            <div className="col-lg-6 col-md-6">
                              <p className="small-text no-margin">
                                {item.taxCode}
                              </p>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <p className="small-text no-margin text-right">
                                ₹ {item.amount}
                              </p>
                            </div>

                            {/* <div className="col-lg-6 col-md-6"><p className="small-text no-margin">GST</p></div>
                  <div className="col-lg-6 col-md-6"><p className="small-text no-margin text-right">₹ 100</p></div>
                  <div className="col-lg-6 col-md-6"><p className="small-text no-margin">Convinient Fee</p></div>
                  <div className="col-lg-6 col-md-6"><p className="small-text no-margin text-right">₹ 100</p></div> */}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <span className="no-margin small-text">
                        Baggage & Meals
                      </span>
                    </div>
                    <div className="col-md-6 col-lg-6 text-right">
                      <span className="no-margin">₹ {this.state.sumMealAnBaggage}</span>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <span className="no-margin">Total Fare</span>
                    </div>
                    <div className="col-md-6 col-lg-6 text-right">
                      <span className="no-margin">
                        ₹ {this.state.ApiPricingResponse.totalResponseAmount+this.state.sumMealAnBaggage}
                      </span>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <span className="no-margin">Discount</span>
                    </div>
                    <div className="col-md-6 col-lg-6 text-right">
                      <span className="no-margin">
                        ₹ {this.state.ApiPricingResponse.discountAmount}
                      </span>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <span className="no-margin">
                        <strong>You Pay</strong>
                      </span>
                    </div>
                    <div className="col-md-6 col-lg-6 text-right">
                      <span className="no-margin">
                        <strong>
                          ₹{" "}
                          {this.state.ApiPricingResponse.finalAmount+this.state.sumMealAnBaggage}
                        </strong>
                      </span>
                    </div>
                  </div>
                </div>
                {/* <h4 className="no-margin">Promo Code</h4>
        <div className="t-details-area price-box mb-2">
          <div className="row">
            <h6 className="mb-2 mt-2">Have a Promo Code?</h6>
            <div className="input-group mb-3">
              <input type="text" className="form-control" placeholder="Your Promo Code" aria-label="Promo code" aria-describedby="basic-addon2" />
              <input type="submit" className="btn btn-primary" defaultValue="APPLY" />
            </div>  
            <div className="promo-selection">
              <div className="d-flex flex-row mb-2">
                <div className="promo-radio">
                  <input type="radio" id="promo1" defaultChecked name="promo1" />
                </div>
                <div className="promo-text">
                  <label htmlFor="promo1"><span className="promo-box">TM99</span><span className="save-text">Save 99</span><br />
                    <span className="small-text promo-text">Upto RS 2500 off. Valid with all card and payment method.</span>
                  </label>
                </div>
              </div>
              <div className="d-flex flex-row mb-2">
                <div className="promo-radio">
                  <input type="radio" id="promo2" name="promo2" />
                </div>
                <div className="promo-text">
                  <label htmlFor="promo2"><span className="promo-box">TM99</span><span className="save-text">Save 99</span><br />
                    <span className="small-text promo-text">Upto RS 2500 off. Valid with all card and payment method.</span>
                  </label>
                </div>
              </div>
              <div className="d-flex flex-row mb-2">
                <div className="promo-radio">
                  <input type="radio" id="promo3" name="promo3" />
                </div>
                <div className="promo-text">
                  <label htmlFor="promo3"><span className="promo-box">TM99</span><span className="save-text">Save 99</span><br />
                    <span className="small-text promo-text">Upto RS 2500 off. Valid with all card and payment method.</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div> */}
              </div>
               </div>
            </div>
          </div>
        </div>
        )}
{/* Non-user login popup */}

    <Modal show={this.state.showLogin}>
    
    <div className="modal-content">
        <div className="modal-header">
         
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.setState({ showLogin: false })} />
        </div>
         <div className="modal-body">
                        {
                            //!this.state.isOtpSent && <form className="login-form" id="login" action="">
                           !this.state.isOtpSent && this.state.isGoogleAuthenticated && <div>
                             <h5 className="no-margin text-center">Email ID / Mobile Number</h5>
                             <div className="input-field">
                              <input
                                type="text"
                                name='mobileNumber'
                                required=""
                                spellCheck="false"
                                className="form-control mb-4 mt-4"
                                ref={(input) => { this.inputField = input; }} // Ref added to the input field
                                onKeyDown={(e) => this.EnterBtnSubmit(e, "mob")}
                                onChange={(e) => this.mobileInputChangeHandler(e)}
                              />
                              <label>Enter Email or Mobile</label>
                            </div>
        
                            <button type="button" onClick={this.LoginOTP} className="btn btn-primary f-login-btn">
                              SUBMIT
                            </button>
                            {/* <div className="pt-2">
                              <a href="" className="no-underline f-password">
                                Forgot Password?
                              </a>
                          </div> */}
                            {/*  <div className="pt-2 pb-2">
                              <p className="or-section">Or</p>
                            </div> */}
                            {/* <div className="row">
                              <div className="col-md-6">
                                <a href="" className="google-login">
                                  <img src="assets/img/otp1.svg" width={40} /> Login with OTP
                                </a>
                              </div>
                              <div className="col-md-6">
                                <a href="" className="google-login">
                                  <img src="assets/img/Google__G__Logo.svg" width={30} /> Sign in
                                  with Google
                                </a>
                              </div>
                            </div> */}
                            <div className="pt-3 pb-3 small-text text-center">
                              <p>
                                By continuing, you agree to our{" "}
                                <a href="" className="privacy-link">
                                  privacy policy &amp; terms of use.
                                </a>
                              </p>
                            </div>
                            </div>
                          // </form>
                        }
                        {
                          
                           !this.state.isOtpSent && !this.state.isGoogleAuthenticated && <div>
                             <h5 className="no-margin text-center">Email ID / Mobile Number</h5>
                             <div className="did-floating-label-content">
                              <input
                                type="text"
                                name='mobileNumber'
                                required=""
                                spellCheck="false" placeholder=" "
                                className="did-floating-input form-control mb-4 mt-4"
                                ref={(input) => { this.inputField = input; }} 
                                onKeyDown={(e) => this.EnterBtnSubmit(e, "mob")}
                                onChange={(e) => this.mobileInputChangeHandler(e)}
                              />
                              <label className="did-floating-label">Enter Email or Mobile</label>
                            </div>
        
                            <button type="button" onClick={this.LoginOTP} className="btn btn-primary f-login-btn">
                              SUBMIT
                            </button>
                          
                             <h6 className="no-margin text-center">Or</h6>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                              <div className="text-center">
                              <button className="login-with-google-btn" onClick={this.signInWithGoogle}> Signin with google</button>
                                  {/* <GoogleLogin 
                                    onSuccess={this.onSuccess}
                                    shape="circle"
                                    onError={error => console.log(error)}
                                   
                                  /> */}
                              </div>
                            </div>
                            <div className="pt-3 pb-3 small-text text-center">
                              <p>
                                By continuing, you agree to our{" "}
                                <a className="privacy-link">
                                  privacy policy &amp; terms of use.
                                </a>
                              </p>
                            </div>
                            </div>
                         
                        }
                        {
                          this.state.isOtpSent && <div className="login-form">
                            <h4 className="text-center">OTP Verification</h4>
                            <p className="mb-3 text-center">An otp has been sent to : <span>{this.state.loginRequest.mobileNumber} <a onClick={this.UseAnotherMobileNumber}><img src="assets/img/edit.png" width={25} /></a></span>
                            
                            </p>
                            <div className="input-field">
        
                              <input
                              autoFocus
                                type="text"
                                name='otp'
                                required=""
                                spellCheck="false"
                                className="form-control"
                                onKeyDown={(e) => this.EnterBtnSubmit(e, "otp")} 
                                onChange={(e) => this.otpInputChangeHandler(e)}
                              />
                              {this.state.isOtpSuccess && <span className="mb-3 small-text otp-valid">Please enter a valid OTP</span>}
                              {/* <span className="mb-3 small-text" >Resend OTP in: 60 </span> */}
                              {showButton ? (
                                        // <button onClick={this.handleResendOTP}>Resend OTP</button>
                                        this.state.OtpResentOnce < 1 &&
                                        <span onClick={this.handleResendOTP} className="mb-3 small-text" >Resend OTP</span>
                                      ) : (
                                        this.state.OtpResentOnce < 1 &&
                                        <div>
                                          <span className="mb-3 small-text" >Resend OTP in: {secondsRemaining} </span>
                                         
                                        </div>
                                      )}
                              <label>Enter OTP</label>

                            </div>
                           
                            

                            <button type="button" onClick={this.SubmitOTP} className="btn btn-primary f-login-btn mt-3">
                              LOGIN
                            </button>
                            {/* <div className="mob-change text-center"><a onClick={this.UseAnotherMobileNumber}>Change Mobile number</a></div> */}
                            {/* <div className="pt-2">
                              <a href="" className="no-underline f-password">
                                Forgot Password?
                              </a>
                            </div> */}
                            {/*<div className="pt-2 pb-2">
                              <p className="or-section">Or</p>
                          </div>*/}
                            {/* <div className="row">
                              <div className="col-md-6">
                                <a href="" className="google-login">
                                  <img src="assets/img/otp.svg" width={40} /> Login with OTP
                                </a>
                              </div>
                              <div className="col-md-6">
                                <a href="" className="google-login">
                                  <img src="assets/img/Google__G__Logo.svg" width={30} /> Sign in
                                  with Google
                                </a>
                              </div>
                            </div> */}
                            <div className="pt-3 pb-3 small-text text-center">
                              <p>
                                By continuing, you agree to our{" "}
                                <a href="" className="privacy-link">
                                  privacy policy &amp; terms of use.
                                </a>
                              </p>
                            </div>
                          </div>
                        }
        
                      </div>
       
      </div>
    </Modal>


      </div>
      
      ) :  (
        <div>
        
        <div className="loader">
  <div className="wait"> 
    <h4>Please wait!</h4>
    
  </div>
   {/* <div class="iata_code departure_city">CDG</div> */}
  <div className="plane1">
    <img src="assets/img/loader.gif" class="plane1-img" />
  </div>
  <div class="earth-wrapper">
    <div class="earth"></div>
  </div>  
   {/* <div class="iata_code arrival_city">JFK</div> */}  
</div>

</div>
    )
  }
}


function WithNavigate(props) {
  let navigate = useNavigate();
  return <UserDetails {...props} navigate={navigate} />
}

export default WithNavigate
