import React from 'react'

function Footer() {
  return (
    <div>
       <div>
        {/* <div className="top-footer">
          <div className="container">
            <div className="d-flex">
              <div className="top-footer-links">
                <a >International Flight Tickets</a>
                <a >Domestic Flight Tickets</a>
              </div>
            </div>
          </div>
        </div> */}
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-12 footer-widget">
                <img src="assets/img/logo-travelmythri.webp" alt=""/>
                <p>TravelMythri. is the leading travel company with strong presence in India and overseas. In GCC countries, we operate with the tradename TravelMythri.</p>
              </div>
              <div className="col-lg-3 col-md-3 col-6 footer-widget">
                <h4>Useful Links</h4>
                <ul>
                  <li>Holidays</li>
                  <li>Attestation</li>
                  <li>Visa</li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-3 col-6 footer-widget">
                <h4>Navigate</h4>
                <ul>
                  <li>Holidays</li>
                  <li>Attestation</li>
                  <li>Visa</li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-3 col-12 footer-widget">
                <h4>Get in touch with us</h4>
                <ul>
                  <li>000 000000</li>
                  <li>b2c@travelmythri.com</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="copyright text-center">
            <p>Copyright © 2002 - 2023, TravelMythri all rights reserved.</p>
          </div>
        </footer>
        <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Sign in to your TravelMythri Account!</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <form className="login-form" id="login" >
                  <input type="text" className="form-control" defaultValue="Enter Mobile Number" />
                  <button type="submit" className="btn btn-primary">GET OTP</button>
                  <div className="pt-2 pb-2">
                    <p className="or-section">Or</p>
                  </div>
                  <div className="text-center">
                    <a  className="google-login"><img src="assets/img/Google__G__Logo.svg" width={30} alt=""/> Sign in with Google</a>
                  </div>
                  <div className="pt-3 pb-3 small-text text-center"><p>By continuing, you agree to our <a  className="privacy-link">privacy policy &amp; terms of use.</a></p></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
