import { ToastContainer, toast } from 'react-toastify';

export function Required(fieldData, index) {

  if (fieldData != "")
    return true;
  else
    return false;
}

export function isValidatePassport(data) {
  //Max length 20
  const passportRegex = /^[a-zA-Z0-9]{2,20}$/;
  if (!passportRegex.test(data)) {
    return false;
  } else {
    return true;
  }
}

export function isNumbersOnly(data) {
  //Max length 20
  const numberRegex = /^[0-9\b]+$/;
  if (!numberRegex.test(data)) {
    return false;
  } else {
    return true;
  }
}

export function isValidateUPI(data) {
  //Max length 20
  const UpiRegex = /^[\w.-]+@[\w.-]+$/;
  if (!UpiRegex.test(data)) {
    return false;
  } else {
    return true;
  }
}

export function isValidateMobileNumber(data) {
  //Max length 20
  const mobileRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  if (!mobileRegex.test(data)) {
    return false;
  } else {
    return true;
  }
}

export function isValidCardDOE(data) {
  //Max length 20
  const cardDOERegex = /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/;
  if (!cardDOERegex.test(data)) {
    return false;
  } else {
    return true;
  }
}

export function isValidCVV(data) {
  //Max length 20
  const cvvRegex = /^[0-9]{3, 4}$/;
  if (!cvvRegex.test(data)) {
    return false;
  } else {
    return true;
  }
}


export function isValidatePinCode(data) {

  const PinRegex = /^(\+\d{1,3}[- ]?)?\d{6}$/;
  if (PinRegex.test(data) || (data == null || data == "")) {
    return true;
  } else {
    return false;
  }
}

export function isValidateCountryId(data) {

  const PinRegex = /^[a-zA-Z]{2}$/;
  if (PinRegex.test(data) || (data == null || data == "")) {
    return true;
  } else {
    return false;
  }
}

export function isValidateEmail(data) {
  const emailRegex = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
  if (!emailRegex.test(data)) {
    return false;
  } else {
    return true;
  }
}


export function isCardNumberValid(data) {
  const cardNmbrRegex = /^4[0-9]{12}(?:[0-9]{3})?$/;
  if (!cardNmbrRegex.test(data)) {
    return false;
  } else {
    return true;
  }
}

// export function isValidateUPI(data) {
//   const upiRegex = /^[a-zA-Z0-9.-]{2, 256}@[a-zA-Z][a-zA-Z]{2, 64}$/;
//   if (!upiRegex.test(data)) {
//     return false;
//   } else {
//     return true;
//   }
// }

export function isValidateNames(data) {
  const namesRegex = /^[a-zA-Z ]*$/;
  if (!namesRegex.test(data) ) {
    return false;
  } else {
    return true;
  }
}

export function CheckValidation(event, index, type, fieldName, FieldArray, allPax, isPassportRequired) {
  for (let i = 0; i < allPax.length; i++) {
    if (allPax[i].paxKey == FieldArray[index].paxKey) {
      if (type == "dateType") {
        if (Required(allPax[i][type])) {
          FieldArray[index][fieldName] = true;
        }
        else {
          FieldArray[index][fieldName] = false;
        }
      }
      else {
        if (event.target.name == "documentNumber") {

          if ((event.target.value != "" && event.target.value.length < 2) && !isPassportRequired) {
            FieldArray[index].documentNumber = false;
            if(!allPax[index].dateofBirth)
            {
                FieldArray[index].dateofBirth = false;
            }
            FieldArray[index].dateOfExpiry = false;
            break;
          }
          else if (event.target.value == "" && !isPassportRequired) {
            FieldArray[index].documentNumber = true;
            FieldArray[index].dateofBirth = true;
            FieldArray[index].dateOfExpiry = true;
            break;
          }

          if (isValidatePassport(allPax[i][event.target.name])) {
            FieldArray[index][event.target.name] = true;
          }
          else {
            FieldArray[index][event.target.name] = false;
          }
        }
        else if (event.target.name == "firstName" || event.target.name == "lastName") {
          if (isValidateNames(allPax[i][event.target.name]) && event.target.value != "") {
            FieldArray[index][event.target.name] = true;
          }
          else {
            FieldArray[index][event.target.name] = false;
          }
        }
        else {
          if (Required(allPax[i][event.target.name])) {
            FieldArray[index][event.target.name] = true;
          }
          else {
            FieldArray[index][event.target.name] = false;
          }
        }
      }
    }
  }
  return FieldArray
}

export function CommonFieldCheckValidation(event, CommonFieldValidations) {
  if (event.target.name == "contactNumber") {
    if (isValidateMobileNumber(event.target.value))
      CommonFieldValidations.contactNumber = true;
    else
      CommonFieldValidations.contactNumber = false;
  }
  else if (event.target.name == "contactEmail") {
    if (isValidateEmail(event.target.value))
      CommonFieldValidations.contactEmail = true;
    else
      CommonFieldValidations.contactEmail = false;
  } else if (event.target.name == "alternateContactNumber") {
    if (event.target.value != "") {
      if (isValidateMobileNumber(event.target.value))
        CommonFieldValidations.alternateContactNumber = true;
      else
        CommonFieldValidations.alternateContactNumber = false;
    }
    else {
      CommonFieldValidations.alternateContactNumber = true;
    }

  }

  return CommonFieldValidations;
}

export function IsBasicValidationSuccess(AdultArray, ChildArray, InfantArray, isPassportMandatory, CommonFieldCheckValidation) {

  let isSuccess = true;
  let AdultFields, ChildAndInfantFields;
  // if(!isPassportMandatory)
  // {
  //     AdultFields=["title", "firstName", "lastName"];
  //     ChildAndInfantFields=["title", "firstName", "lastName", "dateofBirth"];
  // }
  // else
  // {
  AdultFields = ["title", "firstName", "lastName", "dateofBirth", "documentNumber", "dateOfExpiry"];
  ChildAndInfantFields = ["title", "firstName", "lastName", "dateofBirth", "documentNumber", "dateOfExpiry"];
  //CommonFileds=["contactNumber", "alternateContactNumber", "contactEmail"];
  // }  

  //Adult
  for (let i = 0; i < AdultArray.length; i++) {
    for (let j = 0; j < AdultFields.length; j++) {

      if (AdultArray[i][AdultFields[j]] == false) {
        isSuccess = false;
        return isSuccess;
      }
    }
  }
  //CHild
  for (let i = 0; i < ChildArray.length; i++) {
    for (let j = 0; j < ChildAndInfantFields.length; j++) {
      if (ChildArray[i][ChildAndInfantFields[j]] == false) {
        isSuccess = false;
        return isSuccess;
      }
    }
  }
  //Infant
  for (let i = 0; i < InfantArray.length; i++) {
    for (let j = 0; j < ChildAndInfantFields.length; j++) {
      if (InfantArray[i][ChildAndInfantFields[j]] == false) {
        isSuccess = false;
        return isSuccess;
      }
    }
  }
  //Common Fileds   
  if (!CommonFieldCheckValidation.contactNumber || !CommonFieldCheckValidation.alternateContactNumber || !CommonFieldCheckValidation.contactEmail) {
    isSuccess = false;
    return isSuccess;
  }

  return isSuccess;
}

export function isProfileValidationSuccess(data) {
  let ProfileFieldValidity={};
  ProfileFieldValidity.firstName = isValidateNames(data.firstName);
  ProfileFieldValidity.lastName = isValidateNames(data.lastName);
  ProfileFieldValidity.email = isValidateEmail(data.email);
  ProfileFieldValidity.countryId = isValidateCountryId(data.countryId)
  ProfileFieldValidity.city = isValidateNames(data.city);
  ProfileFieldValidity.pincode = isValidatePinCode(data.pincode);

  return ProfileFieldValidity;
}

export function isProfileEachFieldValidationSuccess(data) {
  let ProfileFieldValidity;
  if(data.target.name == "firstName")
  {
    ProfileFieldValidity = isValidateNames(data.target.value);
  }
  else if(data.target.name == "lastName")
  {
    ProfileFieldValidity = isValidateNames(data.target.value);
  }
  else if(data.target.name == "email")
  {
    ProfileFieldValidity = isValidateEmail(data.target.value);
  }
  else if(data.target.name == "city")
  {
    ProfileFieldValidity = isValidateNames(data.target.value);
  }
  else if(data.target.name == "pincode")
  {
    ProfileFieldValidity = isValidatePinCode(data.target.value);
  }

  return ProfileFieldValidity;
}

export function getCardTypeFromNumber(input) {
      let cardType;
      
    if (/^((5[1-5])|(222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720))/.test(input)) {
      cardType = "Master";
    } else if (/^[4]/.test(input)) {
    cardType = "Visa";
    } else if (/^((506(0|1))|(507(8|9))|(6500))/.test(input)) {
    cardType = "Verve";
      } else if (/^((34)|(37))/.test(input)) {
      cardType = "AmericanExpress";
    } else if (/^((6[45])|(6011)|(81)|(82)|(508))/.test(input)) {
      cardType = "ruPay";
    } else if (/^((30[0-5])|(3[89])|(36)|(3095))/.test(input)) {
      cardType = "DinersClub";
      } else if (/^(352[89]|35[3-8][0-9])/.test(input)) {
      cardType = "ruPay";
    } else if (input.length <= 8) {
      cardType = "Others";
    } else {
      cardType = "Invalid";
    }
      
      return cardType;
  }

export function CheckNetworkStatus(){
  let status=true;
  if(!window.navigator.onLine)
  {
      toast.success('No Internet Connection...', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      status=false;
  }
  return status;
}

export function paxNameDuplicationCheck(paxList){
  let duplicateNames = [];
  let listSize = paxList.length;

  for (let i = 0; i < listSize; i++) {
    let fullName1 = `${paxList[i].firstName.trim()} ${paxList[i].lastName.trim()}`.toUpperCase().replace(/ +(?= )/g, '');
      
      for (let j = i + 1; j < listSize; j++) {
        let fullName2 = `${paxList[j].firstName.trim()} ${paxList[j].lastName.trim()}`.toUpperCase().replace(/ +(?= )/g, '');
          
          if (fullName1 === fullName2) {
              duplicateNames.push(fullName1);
          }
      }
  }

  return duplicateNames;
}