import React, { Component } from 'react'
import { Authenticate, SubmitOTPToLogin, LogoutUser } from '../../Services/AccountServices';
import { useNavigate, Link, json } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid';
import { GetNonUserToken } from "../../Services/NonUser"
import { ToastContainer, toast } from 'react-toastify';
import { CheckNetworkStatus, isValidateMobileNumber, isNumbersOnly, isValidateEmail} from '../../Utilities/Validations';
import { GoogleLogin } from '@react-oauth/google';
import {decodeJwt} from 'jose'
import { GoogleAuthentication } from "../../Services/GoogleAuthentication"
import {useLocation} from "react-router-dom"
import { auth , provider} from "../../Configuration/firebase";
import { signInWithPopup, signOut, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";


class HeaderNew extends Component {

  constructor(props) {
    super(props);
    this.state = {
      UserDetails: {},
      loginRequest: {},
      otpSubmitRequest: {},
      isOtpSent: false,
      isLoaded:false,
      isOtpSuccess:false,
      OtpResentOnce:0,    
      showButton: false,
      secondsRemaining: 60,
      IsRefresh:false,
      isGoogleAuthenticated: false,
      GoogleAuthenticatedEmail:"",
      isGoogleVerificationSuccess : false,
      credential:'',
      email:'',
      newEmail:'',
      psswd:''


    };
    this.componentDidMount = this.componentDidMount.bind(this);
    this.LoginOTP = this.LoginOTP.bind(this);
    this.SubmitOTP = this.SubmitOTP.bind(this);
    this.UserLogout = this.UserLogout.bind(this);
    this.UseAnotherMobileNumber = this.UseAnotherMobileNumber.bind(this);
  }

  ///////////Page OnLoad/////////////////////////////
  async componentDidMount() {
   
    if(!window.navigator.onLine)
    {
      //Connection  Lost toast here...
    }

    if (this.inputField) {
      this.inputField.focus(); // Focus on the input field
    }
    
    let data={};

    if (localStorage.getItem("tokenValue") != null) {
      this.setState({
        UserDetails: JSON.parse(localStorage.getItem("TM_UDetails"))
      });
     
    }    
    else if(!localStorage.getItem('DeviceId') || !localStorage.getItem('NonUsertokenValue'))
    {
      if (!localStorage.getItem('DeviceId'))
      {
        const DeviceId = uuidv4();
       
       
        data.Id=DeviceId;
        localStorage.setItem('DeviceId',data.Id);
      }
      else if(!localStorage.getItem('NonUsertokenValue'))
      {
       
        data.Id=localStorage.getItem('DeviceId');
      }
      await GetNonUserToken(data).then((response) => {
      
      if (response.data.status) {
        localStorage.setItem('NonUsertokenValue', response.data.token);
        this.setState({
          isLoaded:true,
        })
      
        this.setState({
          DefaultUserLoginResponse: response.data,          
        });      
        
      }
    })
    .catch((error) => {
      this.setState({
        isLoaded:false,
      })
      
      toast.success('Something went wrong...', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
     
      if(error.response.data.responseMessage=="InvalidToken")
      {
        this.props.navigate('/');        
      }
    });
    }




  //   if (!localStorage.getItem('DeviceId')) {
  //   const DeviceId = uuidv4();
 
  //   //localStorage.setItem('DeviceId',DeviceId);
  //   let data={};
  //   data.Id=DeviceId;
  //   localStorage.setItem('DeviceId',data.Id);

 
  //    await GetNonUserToken(data).then((response) => {
  //       //GetNonUserToken().then((response) => {

    
  //     if (response.data.status) {
  //       localStorage.setItem('NonUsertokenValue', response.data.token);
  //       this.setState({
  //         isLoaded:true,
  //       })
      
  //       this.setState({


  //         DefaultUserLoginResponse: response.data,
          
  //       });

  //       // if (localStorage.getItem("NonUsertokenValue") == null) {

  //       //     localStorage.setItem('NonUsertokenValue', response.data.token);
  //       // }

        
  //     }
  //   })
  //   .catch((error) => {
  //     this.setState({
  //       isLoaded:false,
  //     })

  //     if(error.response.data.responseMessage=="InvalidToken")
  //     {
  //       // Swal.fire(
  //       //   'Unauthorized Access !',
  //       //   'Please Login Again',
  //       //   'warning'
  //       // )
        
  //       this.props.navigate('/');
        
  //     }
  //   });
  //   // if(this.state.isLoaded)
  // }

  this.sleep(2000).then(() => {  
    this.componentDidMount();
  })
  .catch((error) => {
    if(error.response.data.responseMessage=="InvalidToken")
    {           
      this.props.navigate('/');            
    }
      // console.log(error);
    toast.success('Something went wrong...', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  });
  
}

signInWithGoogle = async () => {
  signOut(auth).then((data)=>{
    try {
      // console.log(auth);
      // console.log(provider)
      provider.setCustomParameters({ prompt: 'select_account' });
    signInWithPopup(auth,provider).then((data)=>{
        //console.log(data);
  
        if(data.user.emailVerified)
        {
            // Call your API function with the necessary data
            this.IdTokenValidation( data._tokenResponse.oauthIdToken,  data.user.email );
        }
    });
    } catch (err){
      console.error(err);
    }
});

};

googleSignOut(){
  signOut(auth);
}



IdTokenValidation(credential,email){
  let data = {};
  data.idToken= credential;
  data.emailId = email;
  this.setState({
    credential: credential,          
    email: email,
  });
  GoogleAuthentication(data)
    .then((response) => {
      // Handle the API response here
      // console.log('Google Authentication API response', response);
      if (response.data.status && response.data.responseMessage == 'Logined Successfully') { 
        this.setState({
          isGoogleAuthenticated: false,  
          isGoogleVerificationSuccess :true,                
        });
        localStorage.setItem('tokenValue', response.data.token);
          localStorage.removeItem('NonUsertokenValue');
          localStorage.setItem('TM_UDetails', JSON.stringify(response.data.objUser));

          this.setState({
            isOtpSuccess:false,
            IsRefresh:true,
          })
         
          
          window.location.reload();
         
      }
      else if(response.data.status && response.data.responseMessage == 'Success')
      {
        let data = this.state.loginRequest;
        data.emailId = response.data.email;
        this.setState({
          isGoogleAuthenticated: true,
          GoogleAuthenticatedEmail : response.data.email,
          loginRequest : data,
          isGoogleVerificationSuccess : true,
        });
      }
      else
      {
        this.setState({
          isGoogleAuthenticated: false,
          isGoogleVerificationSuccess :false,
        });
      }
    })
    .catch((error) => {
      // Handle API call errors here
      console.error('Google Authentication API error', error);
    });
}

onSuccess = (credentialResponse) => {
  // console.log(credentialResponse);
  const { credential } = credentialResponse;
  const payload = credential ? decodeJwt(credential) : undefined;

  if (payload) {
    // console.log(payload);
    // console.log(payload.email);

    // Call your API function with the necessary data
    this.IdTokenValidation( credential,  payload.email );
  }
};



sleep = ms => {  
  return new Promise(resolve => setTimeout(resolve, ms));  
  }; 

UseAnotherMobileNumber(){
  this.setState({
    isOtpSent:false,
  })
}


  ///////////AUthentication/////////////////////////////
  LoginOTP() {
      
    //Check network connection...
    if(!CheckNetworkStatus())
        return;

    //Check Valid Mobile...
    if(!isValidateMobileNumber(this.state.loginRequest.mobileNumber) && !isValidateEmail(this.state.loginRequest.mobileNumber))
    {
        toast.success('Invalid Mobile Number...', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        });
        return;
    }
        
    let data = this.state.loginRequest;
    data.isGoogleVerificationSuccess=this.state.isGoogleVerificationSuccess;
  
    if(this.state.OtpResentOnce <= 1)
      Authenticate(data).
        then((response) => {
          // console.log(response);
          if (response.data.status) {  
            this.setState({
              isOtpSent: true
            });
            this.startTimer();
            this.setState({
              secondsRemaining: 60,
            })
          }
          else
          {
            toast.warning(response.data.responseMessage, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            });
          }
        })
        .catch((error) => {      
          // console.log(error);
          toast.success('Something went wrong...', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        });
  }

  EnterBtnSubmit(event, type) {
    
    if(event.key=="Enter")
      if(type=="mob")
        this.LoginOTP();
      else
        this.SubmitOTP();

  }

  startTimer = () => {
    this.timer = setInterval(() => {
      this.setState(prevState => ({
        secondsRemaining: prevState.secondsRemaining - 1
      }), () => {
        if (this.state.secondsRemaining === 0) {
          this.setState({ showButton: true });
          clearInterval(this.timer);
        }
      });
    }, 1000); // 1 second
  };


   handleResendOTP = () => {
    this.setState({
      OtpResentOnce:this.state.OtpResentOnce + 1 ,

    })
    // Handle resend OTP logic here
    // For example, make an API call to resend the OTP
    this.setState({ showButton: false, secondsRemaining: 60 }, () => {
      this.startTimer();
      this.LoginOTP()
    });
  };


  ///////////OTP Submit/////////////////////////////
  SubmitOTP() {
      
    //Check network connection...
    if(!CheckNetworkStatus())
        return;

            //Check network connection...
    if(!isNumbersOnly(this.state.otpSubmitRequest.otp))
    {
      toast.success('Invalid OTP...', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
    });
    return;
    }
    
    //otpSubmitRequest.mobileNumber=loginRequest.mobileNumber;
    let data = {};
    data = this.state.otpSubmitRequest;
    data.mobileNumber = this.state.loginRequest.mobileNumber;
    data.otp=data.otp.trim();
    data.emailId=this.state.email;
    data.idToken=this.state.credential;
    data.isGoogleAuthSuccess = this.state.isGoogleVerificationSuccess;
    SubmitOTPToLogin(data).
      then((response) => {
       
        if (response.data.status) {
          localStorage.setItem('tokenValue', response.data.token);
          localStorage.removeItem('NonUsertokenValue');
          localStorage.setItem('TM_UDetails', JSON.stringify(response.data.objUser));
           localStorage.setItem('RS', JSON.stringify(response.data.recentSearchList));
          
          this.setState({
            isOtpSuccess:false,
            IsRefresh:true,
          })
          // this.componentDidMount();
          
          window.location.reload();
          // this.forceUpdate();
          //this.render();
        }
        else
        {
          this.setState({
              isOtpSuccess:true
          })
        }
      })
  }

  //////////////////// User Logout /////////////////////////////////////////
  UserLogout() {
      
    //Check network connection...
    if(!CheckNetworkStatus())
        return;

    LogoutUser({ UserId: this.state.UserDetails.userId }).
      then((response) => {
        this.googleSignOut();
        if (response.data.status) {
          localStorage.removeItem("TM_UDetails");
          localStorage.removeItem("tokenValue");
          localStorage.removeItem("DeviceId");
          localStorage.setItem("RS", null);
          window.location.reload();
        //   const DeviceId = uuidv4();      
        // let data={};
        // data.Id=DeviceId;
        // localStorage.setItem('DeviceId',data.Id);

        //   GetNonUserToken(data).then((response) => {
        //     //GetNonUserToken().then((response) => {

        
        //   if (response.data.status) {
            
        //     this.setState({
    
    
        //       DefaultUserLoginResponse: response.data,
              
        //     });

        //     localStorage.setItem('NonUsertokenValue', response.data.token);
        //    window.location.reload();
    
           
            
        //   }
        // })
        // .catch((error) => {

        //   if(error.response.data.responseMessage=="InvalidToken")
        //   {
        //     // Swal.fire(
        //     //   'Unauthorized Access !',
        //     //   'Please Login Again',
        //     //   'warning'
        //     // )
            
        //     this.props.navigate('/');
            
        //   }
        // });

        // window.location.reload();

        
        // if(localStorage.getItem("TM_UDetails")==null || localStorage.getItem("tokenValue"))
        // {

        // }
        // else
        //   window.location.reload();

        }

      })
     

  }
  /////////////////Input value set//////////////////////////////////////////////
  mobileInputChangeHandler = (event) => {
    const { name, value } = event.target
    let data = this.state.loginRequest;
    data.mobileNumber = value.trim();
    this.setState({
      loginRequest: data,
    })
  }

  /////////////////Input value set//////////////////////////////////////////////
  otpInputChangeHandler = (event) => {
    const { name, value } = event.target;
    let data = {};
    data.otp = value.trim();
    this.setState({
      otpSubmitRequest: data,
    })
  }


  ///////////Goto////////////////////////////////////////////////////////////////
  goto = (nav) => {
    this.props.navigate(nav);
  }


  render() {

    const { showButton, secondsRemaining } = this.state;


    ///////Change in Header////////////////////////////////////////////////
    // let HeaderContent;
    // if (localStorage.getItem("tokenValue") != null) {
    //   HeaderContent = <div className="dropdown" id="logged">
    //     <a className="dropdown-toggle logged-text" type="button" id="account-login" data-bs-toggle="dropdown" aria-expanded="false"><span><img src="assets/img/user.svg" width={30} />
    //     </span> Hi {this.state.UserDetails.firstName} {" "}{this.state.UserDetails.lastName} !</a>
    //     <ul className="dropdown-menu" aria-labelledby="account-login">
    //       <li><a onClick={() => { this.goto('/Profile') }} className="dropdown-item" ><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-person mr-10" viewBox="0 0 16 16">
    //         <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
    //       </svg> My Profile</a></li>
    //       <li><a onClick={() => { this.goto('/MyTrips') }} className="dropdown-item"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-briefcase mr-10" viewBox="0 0 16 16">
    //         <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
    //       </svg> My Trips</a></li>
    //       <li><a onClick={this.UserLogout} className="dropdown-item" ><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-box-arrow-left mr-10" viewBox="0 0 16 16">
    //         <path fillRule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z" />
    //         <path fillRule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z" />
    //       </svg> Logout</a></li>
    //     </ul>
    //   </div>;
    // } else {
    //   HeaderContent = <div>

    //     <p data-bs-toggle="modal" data-bs-target="#exampleModal" className="login-link"><span><img src="assets/img/user.svg" width={30} /></span> Sign up</p>


    //     <div
    //       className="modal fade"
    //       id="exampleModal"
    //       tabIndex={-1}
    //       aria-labelledby="exampleModalLabel"
    //       aria-hidden="true"
    //     >
    //       <div className="modal-dialog">
    //         <div className="modal-content">
    //           <div className="modal-header">
    //             <h5 className="modal-title" id="exampleModalLabel">
    //               Sign in to your TravelMythri Account!
    //             </h5>
    //             <button
    //               type="button"
    //               className="btn-close"
    //               data-bs-dismiss="modal"
    //               aria-label="Close"
    //             />
    //           </div>
    //           <div className="modal-body">
    //             {
    //               !this.state.isOtpSent && <div className="login-form">
                  
    //                 <div className="input-field">
    //                   <input
    //                     type="text"
    //                     name='mobileNumber'
    //                     required=""
    //                     spellCheck="false"
    //                     className="form-control mb-4"
    //                     onChange={(e) => this.mobileInputChangeHandler(e)}
    //                   />
    //                   <label>Enter your Mobile number</label>
    //                 </div>

    //                 <button type="button" onClick={this.LoginOTP} className="btn btn-primary f-login-btn">
    //                   SUBMIT
    //                 </button>
    //                 {/* <div className="pt-2">
    //                   <a href="" className="no-underline f-password">
    //                     Forgot Password?
    //                   </a>
    //               </div> */}
    //                 {/*  <div className="pt-2 pb-2">
    //                   <p className="or-section">Or</p>
    //                 </div> */}
    //                 {/* <div className="row">
    //                   <div className="col-md-6">
    //                     <a href="" className="google-login">
    //                       <img src="assets/img/otp.svg" width={40} /> Login with OTP
    //                     </a>
    //                   </div>
    //                   <div className="col-md-6">
    //                     <a href="" className="google-login">
    //                       <img src="assets/img/Google__G__Logo.svg" width={30} /> Sign in
    //                       with Google
    //                     </a>
    //                   </div>
    //                 </div> */}
    //                 <div className="pt-3 pb-3 small-text text-center">
    //                   <p>
    //                     By continuing, you agree to our{" "}
    //                     <a className="privacy-link">
    //                       privacy policy &amp; terms of use.
    //                     </a>
    //                   </p>
    //                 </div>
    //               </div>
    //             }
    //             {
    //               this.state.isOtpSent && <div className="login-form">
    //                 <p className="mb-3">An otp has been sent to your mobile number</p>
    //                 <div className="input-field">

    //                   <input
    //                     type="text"
    //                     name='otp'
    //                     required=""
    //                     spellCheck="false"
    //                     className="form-control mb-3"
    //                     onChange={(e) => this.otpInputChangeHandler(e)}
    //                   />
    //                   <label>Enter OTP</label>
    //                 </div>

                    

    //                 <button type="button" onClick={this.SubmitOTP} className="btn btn-primary f-login-btn">
    //                   LOGIN
    //                 </button>
    //                 {/* <div className="pt-2">
    //                   <a href="" className="no-underline f-password">
    //                     Forgot Password?
    //                   </a>
    //                 </div> */}
    //                 {/*<div className="pt-2 pb-2">
    //                   <p className="or-section">Or</p>
    //               </div>*/}
    //                 {/* <div className="row">
    //                   <div className="col-md-6">
    //                     <a href="" className="google-login">
    //                       <img src="assets/img/otp.svg" width={40} /> Login with OTP
    //                     </a>
    //                   </div>
    //                   <div className="col-md-6">
    //                     <a href="" className="google-login">
    //                       <img src="assets/img/Google__G__Logo.svg" width={30} /> Sign in
    //                       with Google
    //                     </a>
    //                   </div>
    //                 </div> */}
    //                 <div className="pt-3 pb-3 small-text text-center">
    //                   <p>
    //                     By continuing, you agree to our{" "}
    //                     <a className="privacy-link">
    //                       privacy policy &amp; terms of use.
    //                     </a>
    //                   </p>
    //                 </div>
    //               </div>
    //             }

    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //     ;
    // }
    ///////////////////////////////////////////////////////////////////////


    return (
      <div >
        <div className="top-nav d-flex">
          <div className="container">
            <div className="d-flex flex-row float-start">
              <p><span><img src="assets/img/call.svg" width={35} /></span> +91 0000000000</p>
            </div>
            <div className="d-flex flex-row-reverse float-end">

              {/* {HeaderContent} */}

              {
              localStorage.getItem("tokenValue") != null && this.state.UserDetails ? <div className="dropdown" id="logged">
                <a className="dropdown-toggle logged-text" type="button" id="account-login" data-bs-toggle="dropdown" aria-expanded="false"><span><img src="assets/img/user.svg" width={30} />
                </span> Hi {this.state.UserDetails.firstName} {" "}{this.state.UserDetails.lastName} !</a>
                <ul className="dropdown-menu" aria-labelledby="account-login">
                  <li><a onClick={() => { this.goto('/Profile') }} className="dropdown-item" ><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-person mr-10" viewBox="0 0 16 16">
                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                  </svg> My Profile</a></li>
                  <li><a onClick={() => { this.goto('/MyTrips') }} className="dropdown-item" ><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-briefcase mr-10" viewBox="0 0 16 16">
                    <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                  </svg> My Trips</a></li>
                  {this.props.location.pathname != "/checkout" && 
                    <li><a onClick={this.UserLogout} className="dropdown-item" ><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-box-arrow-left mr-10" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z" />
                      <path fillRule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z" />
                    </svg> Logout</a></li>
                  }
                </ul>
              </div>:
              <div></div>}
              {
                (localStorage.getItem("tokenValue") == null) && <div>

                <p data-bs-toggle="modal" data-bs-target="#exampleModal" className="login-link"><span><img src="assets/img/user.svg" width={30} /></span> Sign up</p>
        
        
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabIndex={-1}
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header login-header">
                        <h6 className="modal-title" id="exampleModalLabel">
                          Sign in to TravelMythri!
                        </h6>
                        <button
                          type="button"
                          className="btn-close login-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        />
                      </div>
                      <div className="modal-body">
                      {
                          
                           !this.state.isOtpSent && this.state.isGoogleAuthenticated && <div>
                             <h5 className="no-margin text-center">Mobile Number</h5>
                             <div className="did-floating-label-content">
                              <input
                                type="text"
                                name='mobileNumber'
                                required=""
                                spellCheck="false" placeholder=" "
                                className="did-floating-input form-control mb-4 mt-4"
                                ref={(input) => { this.inputField = input; }} // Ref added to the input field
                                onKeyDown={(e) => this.EnterBtnSubmit(e, "mob")}
                                onChange={(e) => this.mobileInputChangeHandler(e)}
                              />
                              <label className="did-floating-label">Enter Mobile</label>
                            </div>
        
                            <button type="button" onClick={this.LoginOTP} className="btn btn-primary f-login-btn">
                              SUBMIT
                            </button>

                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                              <div className="text-center">
                             <button className="login-with-google-btn" onClick={()=>{this.signInWithGoogle()}}> Change Google Account</button>
                              {/* <GoogleLogin 
                                    onSuccess={this.onSuccess}
                                    shape="circle"
                                    onError={error => console.log(error)}
                                    //useOneTap 
                                  /> */}
                              </div>
                            </div>
                           
                            
                            <div className="pt-3 pb-3 small-text text-center">
                              <p>
                                By continuing, you agree to our{" "}
                                <a className="privacy-link">
                                  privacy policy &amp; terms of use.
                                </a>
                              </p>
                            </div>
                            </div>
                          
                        }
                        {
                          // !this.state.isOtpSent && <form className="login-form" id="login" action="">
                           !this.state.isOtpSent && !this.state.isGoogleAuthenticated && <div>
                             <h5 className="no-margin text-center">Email ID / Mobile Number</h5>
                             <div className="did-floating-label-content">
                              <input
                                type="text"
                                name='mobileNumber'
                                required=""
                                spellCheck="false" placeholder=" "
                                className="did-floating-input form-control mb-4 mt-4"
                                ref={(input) => { this.inputField = input; }} // Ref added to the input field
                                onKeyDown={(e) => this.EnterBtnSubmit(e, "mob")}
                                onChange={(e) => this.mobileInputChangeHandler(e)}
                              />
                              <label className="did-floating-label">Enter Email or Mobile</label>
                            </div>
        
                            <button type="button" onClick={this.LoginOTP} className="btn btn-primary f-login-btn mb-3">
                              SUBMIT
                            </button>
                            {/* <div className="pt-2">
                              <a href="" className="no-underline f-password">
                                Forgot Password?
                              </a>
                          </div> */}
                            {/*  <div className="pt-2 pb-2">
                              <p className="or-section">Or</p>
                            </div> */}
                            {/* <div className="row">
                              <div className="col-md-6">
                                <a href="" className="google-login">
                                  <img src="assets/img/otp1.svg" width={40} /> Login with OTP
                                </a>
                              </div>
                              <div className="col-md-6">
                                <a href="" className="google-login">
                                  <img src="assets/img/Google__G__Logo.svg" width={30} /> Sign in
                                  with Google
                                </a>
                              </div>
                            </div> */}
                             <h6 className="no-margin text-center">Or</h6>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                              <div className="text-center">
                              <button className="login-with-google-btn" onClick={this.signInWithGoogle}> Sign in with google</button>                              
                                  {/* <GoogleLogin 
                                    onSuccess={this.onSuccess}
                                    shape="circle"
                                    onError={error => console.log(error)}
                                    //useOneTap 
                                  /> */}
                              </div>
                            </div>
                            <div className="pt-3 pb-3 small-text text-center">
                              <p>
                                By continuing, you agree to our{" "}
                                <a className="privacy-link">
                                  privacy policy &amp; terms of use.
                                </a>
                              </p>
                            </div>
                            </div>
                          // </form>
                        }
                        {
                          this.state.isOtpSent && <div className="login-form">
                            <h4 className="text-center">OTP Verification</h4>
                            <p className="mb-3 text-center">An otp has been sent to : <span>{this.state.loginRequest.mobileNumber} <a onClick={this.UseAnotherMobileNumber}><img src="assets/img/edit.png" width={25} /></a></span>
                            
                            </p>
                            <div className="did-floating-label-content">
        
                              <input
                              autoFocus
                                type="text"
                                name='otp'
                                required=""
                                spellCheck="false" placeholder=" "
                                className="did-floating-input form-control"
                                autoComplete='off'
                                onKeyDown={(e) => this.EnterBtnSubmit(e, "otp")} 
                                onChange={(e) => this.otpInputChangeHandler(e)}
                              />
                              {this.state.isOtpSuccess && <span className="mb-3 small-text otp-valid">Please enter a valid OTP</span>}
                              {/* <span className="mb-3 small-text" >Resend OTP in: 60 </span> */}
                              {showButton ? (
                                        // <button onClick={this.handleResendOTP}>Resend OTP</button>
                                        this.state.OtpResentOnce < 1 &&
                                        <span onClick={this.handleResendOTP} className="mb-3 small-text" >Resend OTP</span>
                                      ) : (
                                        this.state.OtpResentOnce < 1 &&
                                        <div>
                                          <span className="mb-3 small-text" >Resend OTP in: {secondsRemaining} </span>
                                         
                                        </div>
                                      )}
                            <label className="did-floating-label">Enter OTP</label>

                            </div>
                           
                            

                            <button type="button" onClick={this.SubmitOTP} className="btn btn-primary f-login-btn mt-3">
                              LOGIN
                            </button>
                          
                            {/* <div className="pt-2">
                              <a href="" className="no-underline f-password">
                                Forgot Password?
                              </a>
                            </div> */}
                            {/*<div className="pt-2 pb-2">
                              <p className="or-section">Or</p>
                          </div>*/}
                            {/* <div className="row">
                              <div className="col-md-6">
                                <a href="" className="google-login">
                                  <img src="assets/img/otp.svg" width={40} /> Login with OTP
                                </a>
                              </div>
                              <div className="col-md-6">
                                <a href="" className="google-login">
                                  <img src="assets/img/Google__G__Logo.svg" width={30} /> Sign in
                                  with Google
                                </a>
                              </div>
                            </div> */}
                            <div className="pt-3 pb-3 small-text text-center">
                              <p>
                                By continuing, you agree to our{" "}
                                <a  className="privacy-link">
                                  privacy policy &amp; terms of use.
                                </a>
                              </p>
                            </div>
                          </div>
                        }
        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              }

            </div>
          </div>
        </div>
        <div className="main-nav">
          <nav className="navbar navbar-expand-lg bg-white navbar-light p-0">
            <div className="container">
              <p className="navbar-brand d-flex align-items-center m-0 text-primary"><Link to="/"><img className="img-fluid me-2" src="assets/img/logo-travelmythri.webp" width={250} alt="" /></Link>
              </p>
              <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse justify-content-start" id="navbarCollapse">
                <div className="navbar-nav py-4 py-lg-0">
                <Link to="/" className="nav-item nav-link active hvr-underline-reveal">Flights</Link>
                <Link to="ComingSoon" className="nav-item nav-link active hvr-underline-reveal">Hotels</Link>
                <Link to="ComingSoon" className="nav-item nav-link active hvr-underline-reveal">Bus</Link>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <ToastContainer />
      </div>
    )
  }
}


function WithNavigate(props) {
  let navigate = useNavigate();
  const location = useLocation();
   //console.log(location.pathname);
  return <HeaderNew {...props} navigate={navigate} location={location} />
}

export default WithNavigate
