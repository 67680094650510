import React, { Component } from 'react'
import {AccountStatementServices} from '../../Services/ControlPanal'
import {Link, useNavigate} from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateFormat, { masks } from "dateformat";
import { CheckNetworkStatus} from '../../Utilities/Validations';

class AccountStatement extends Component {

  requestData={};

  constructor(props) {
    super(props);
    this.state = {
    isLoaded: false,
    RequestBody:{},
    ResponseBody:[],
    AccountStatementResponse:[],
    DaywiseStatement:[],
    startDate: "",
    endDate: new Date(),
  };
  this.getAccountStatementsList = this.getAccountStatementsList.bind(this);
  
  }

  componentDidMount(){ 
    this.setState({

        isLoaded:false,
      });

      
    //Check network connection...
    if(!CheckNetworkStatus())
      return;

    this.getAccountStatementsList();
  }


  getAccountStatementsList() {
    AccountStatementServices(this.state.RequestBody).
        then((response) => {
          
            if (response.data.status) {  
              let data1={};
              data1=response.data;
              this.setState({
                AccountStatementResponse:data1,
                DaywiseStatement:data1.objDaywiseStatement,
                isLoaded: true,
                //ResponseBody:data1.objAccountStatement
              })
            }
            else
            {
              this.setState({
                isLoaded: true,
              })
            }
            // SetAccountStatement(response.data.objAccountStatement)
            //this.state.ResponseBody=response.data;
        })
        .catch((error) => {
          if(error.response.data.responseMessage=="InvalidToken")
          {
            // Swal.fire(
            //   'Unauthorized Access !',
            //   'Please Login Again',
            //   'warning'
            // )
            localStorage.removeItem("TM_UDetails");
            localStorage.removeItem("tokenValue");
            localStorage.removeItem("onewaySearchResult");
            
            this.props.navigate('/');

            
          }
        });
}

inputChangeHandler = (selectedDate, type) => {
   
  var date=this.state.RequestBody;
  if(type == "fromDate")
  {
    date.fromDate=dateFormat(selectedDate, "yyyy-mm-dd");
    this.setState({
      RequestBody:date
      ////////fromDate:event.target.value
    })
    date.toDate=dateFormat(selectedDate, "yyyy-mm-dd");
    this.setState({
      RequestBody:date
      ///////toDate:event.target.value
    })
  }
  else if(type == "toDate")
  {
    date.toDate=dateFormat(selectedDate, "yyyy-mm-dd");
    this.setState({
      RequestBody:date
      ///////toDate:event.target.value
    })
  }
  
}
setStartDate = (date) => {
  this.setState({
    startDate: date
  });
}
setEndDate = (date) => {
  this.setState({
    endDate: date
  });
}


  render() {
    return (
      <div>
        <div className="my-acnt-bg">
          <div className="container">
            <div className="user-profile pt-3">
              <h5 className="text-white">My Account</h5>
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <Link to="/Profile"><button className="nav-link "  type="button" >Profile</button></Link>
                  <Link to="/MyTrips"><button className="nav-link" type="button"  >My Trips</button></Link>
                  <Link to="/AccountStatement"><button className="nav-link active" id="nav-account-tab" data-bs-toggle="tab" data-bs-target="#nav-account" type="button" role="tab" >My Transactions</button></Link>
                  {/* <Link to="/TravelCalender"><button className="nav-link"  type="button" >Travel Calendar</button></Link> */}
                </div>
              </nav>
              <div className="tab-content pt-3" id="nav-tabContent">
               
               

                <div>
                  <div className="profile-area mb-3">
                    <div className="top-wallet sticky-top">
                      <div className="row">
                      <div className="col-lg-8 col-md-8">
                        <div className="d-flex flex-row">
                         <span className="wallet-con mr-10"><img src="assets/img/wallet.svg" width={35} /></span>
                         <span><h4 className="no-margin">TravelMythri Wallet</h4></span>
                        </div>
                      </div>
                     <div className="col-lg-2 col-md-2 w-amount-border pl-25"><span><h4 className="no-marging">&#8377; {this.state.AccountStatementResponse.totalAmount}</h4></span></div>
                      </div>
                  

                    </div>
                 

                    <form className="ac_statement" >
                       <div className="table-responsive wallet-table">
                        <table className="table mt-3">
                          <thead>
                            <tr>
                              <th>Transactions</th>
                              <th>Amount</th>
                              <th>Comments</th>
                            </tr>
                          </thead>
                          {!this.state.isLoaded &&
                                            <span className="loader text-center"></span>
                                            }
                          {this.state.isLoaded && this.state.DaywiseStatement.length >0 && this.state.DaywiseStatement.map((item, dayWiseIndex) => (
                             <tbody key={dayWiseIndex}>
                             <a className="no-margin">{item.date}</a>
                             {this.state.isLoaded && item.objAccountStatement.length >0 && item.objAccountStatement.map((item1, statementIndex) => (
                             <tr key={statementIndex}>
                              <td>
                              
                              <p className="row">
                              
                                <img className="col-md-1 col-lg-1 mr-10" src="assets/img/plane01.svg" width={50} />
                               
                                 <p className="col-md-4 col-lg-4">
                                 <p>{item1.transactionDetails}</p>
                                <p>{item1.time}</p>
                                <p>{item1.source}</p>
                                 </p>
                              </p>
                               
                               
                               
                              
                              </td>
                              
                               <td>
                              <h6 className="no-margin">
                                {item1.amount < 0 ? (
                                <span className="debit">- &#8377;{Math.abs(item1.amount)}</span>
                                ) : (
                                <span className="credit">+ &#8377;{item1.amount}</span>
                                )}
                              </h6>
                                </td>
                                 <td>{item1.comments}
                                 
                                 </td>
                             </tr>
                              ))}
                             {/* <tr>
                             <td>
                             
                              <div className="row">
                              <div className="col-md-1 col-lg-1 mr-10">
                                <span><img src="assets/img/reward.svg" width={50} /></span>
                                </div>
                                 <div className="col-md-9 col-lg-9">
                                 <span>Refund from Razorpay</span><br />
                                <span>17 MAY, 10:23 PM</span><br />
                                <span>FROM UPI</span>
                                 </div>
                              </div>
                              
                              </td>
                              <td>
                                <h6 className="no-margin">+ &#8377;2000</h6>
                                </td>
                                 <td>Bank txn ID 313725994496
                                 
                                 </td>
                             </tr>
                             <tr>
                              <td>
                             
                              <div className="row">
                              <div className="col-md-1 col-lg-1 mr-10">
                                <span><img src="assets/img/wallet.svg" width={50} /></span>
                                </div>
                                 <div className="col-md-9 col-lg-9">
                                 <span>Added to wallet</span><br />
                                <span>17 MAY, 10:23 PM</span><br />
                                <span>FROM UPI</span>
                                 </div>
                              </div>
                               
                               
                               
                              
                              </td>
                              
                               <td>
                                <h6 className="no-margin">+ &#8377;2000</h6>
                                </td>
                                 <td>Bank txn ID 313725994496
                                 
                                 </td>
                             </tr>
                             <tr>
                              <td>
                            
                              <div className="row">
                              <div className="col-md-1 col-lg-1 mr-10">
                                <span><img src="assets/img/wallet.svg" width={50} /></span>
                                </div>
                                 <div className="col-md-9 col-lg-9">
                                 <span>Added to wallet</span><br />
                                <span>17 MAY, 10:23 PM</span><br />
                                <span>FROM UPI</span>
                                 </div>
                              </div>
                               
                               
                               
                              
                              </td>
                              
                               <td>
                                <h6 className="no-margin">+ &#8377;2000</h6>
                                </td>
                                 <td>Bank txn ID 313725994496
                                 
                                 </td>
                             </tr> */}
                             </tbody>
                          ))}
                          {this.state.isLoaded && this.state.DaywiseStatement.length==0 && this.state.ResponseBody.length==0 &&
                          <tbody> 
                            <tr>
                                                    <td className="no-data text-center" colSpan={8}>
                                                    <img src="assets/img/no-records.svg" width={200} />
                                                    </td>

                                                    </tr></tbody>}
                                                    
                          
                          


                        </table>
                        {/* {!this.state.isLoaded && <div className="text-center">
                                            <span className="loader"></span>
                                            </div>} */}
                      </div> 
                      <div className="row">





{/* <div className="col-lg-4 col-md-4">
<div className="wallet-amount-head text-center">
<h4>&#8377; 200</h4>
<p className="text-white">Wallet Balance</p>
</div>

</div> */}




</div>

           

                    </form>
                  </div>
                </div>
              
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <AccountStatement {...props} navigate={navigate} />
}

export default WithNavigate
