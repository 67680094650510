import React, { Component } from "react";
import { AES, enc } from 'crypto-js';
import { GetBookingConfirm } from "../../Services/FlightDetails"
//import { GetBookingConfirmIR } from "../../Services/FlightDetails"
import { useLocation} from "react-router-dom";
import { GetTicketCopyUrl } from "../../Services/FlightDetails"

class BookingSuccess extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      BookingSegmentDetails: [],
      BookingPaxlistRsp: [],
      BookingResponse: [],
      // objseglistResult: [],
      // TaxListResult: [],
      // AdtpricingPaxListResult: [],
      // ChdpricingPaxListResult: [],
      // InfpricingPaxListResult: [],
      // //objbaggageListResult: [],
      // itinId: 0,
      // fareId: 0,
      // providerCode: "",
      // requestBody: {},
      // RePricingDetailsResult: [],
      // ReobjseglistResult: [],
      // ReTaxListResult: [],

      // objAdtPaxList:[],
      // objchdPaxList:[],  
      // objinfPaxList:[],     

    };
  }

  componentDidMount() {
    // Access the passed data from props.location.state

    
    const { location } = this.props;
    //const { bookParam } = location.state;
    this.setState({
      isLoaded:false,
    });
    const params = new URLSearchParams(window.location.search);
    if(params.get('BookingConfirmRequest')!== null)
    {
    const BookingConfirmRequest = params.get('BookingConfirmRequest');
 
    // Same secret key used for encryption
    const secretKey = '123';
    
    //Decrypt the encrypted data
    const decryptedData = AES.decrypt(BookingConfirmRequest, secretKey).toString(enc.Utf8);
    
    //Parse the decrypted data back to an object
    const parsedData = JSON.parse(decryptedData);


    this.setState({
            BookingSegmentDetails: parsedData.objSegmentList,
            BookingPaxlistRsp: parsedData.objPaxlist,
            
            BookingResponse: parsedData,
            isLoaded: true,
          });
   
   
      //this.requestBody = JSON.parse(localStorage.getItem("onewayBookingResult"));
      // this.requestBody = bookParam;
      // console.log(bookParam);
      //let response = parsedData;
     
    //        GetBookingConfirm(this.requestBody).then((response) => {
            
    //     // console.log(response);
       

    //   if (response.data.status) {
    //     this.setState({


    //       BookingSegmentDetails: response.data.objSegmentList,
    //       BookingPaxlistRsp: response.data.objPaxlist,
          
    //       BookingResponse: response.data,
    //       isLoaded: true,
    //     });

    //    }
    //  })
    // .catch((error) => {
    //   if(error.response.data.responseMessage=="InvalidToken")
    //   {
    //     // Swal.fire(
    //     //   'Unauthorized Access !',
    //     //   'Please Login Again',
    //     //   'warning'
    //     // )
        
    //     this.props.navigate('/');
        
    //   }
    // });
    }
  
  //   if(params.get('IRTBookingConfirmRequest')!== null)
  //   {
  //     const IRTBookingConfirmRequest = params.get('IRTBookingConfirmRequest');
  // // Same secret key used for encryption
  // const secretKey = '123';
  
  // // Decrypt the encrypted data
  // const IRTdecryptedData = AES.decrypt(IRTBookingConfirmRequest, secretKey).toString(enc.Utf8);
  
  // // Parse the decrypted data back to an object
  // const IRTparsedData = JSON.parse(IRTdecryptedData);
   
  //     //this.requestBody = JSON.parse(localStorage.getItem("onewayBookingResult"));
  //     //this.requestBody = JSON.parse(checkoutRequest);
  //     //this.requestBody1 = JSON.parse(localStorage.getItem("IRTBookingResult"));
  //     this.requestBody1 = IRTparsedData;
  //     // this.setState({
  //     //   requestBody:JSON.parse(localStorage.getItem("crtBookingResult"))
  //     // })
  //     GetBookingConfirmIR(this.requestBody1).then((response) => {
  //       if (response.data.status) {
  //         this.setState({
  
  
  //           BookingSegmentDetails: response.data.objSegmentList,
  //           BookingPaxlistRsp: response.data.objPaxlist,
            
  //           BookingResponse: response.data,
  //           //isLoaded: true,
  //         });
  //       }
  //     })
  //     .catch((error) => {

  //       if(error.response.data.responseMessage=="InvalidToken")
  //       {
  //         Swal.fire(
  //           'Unauthorized Access !',
  //           'Please Login Again',
  //           'warning'
  //         )
          
  //         this.props.navigate('/');
          
  //       }
  //     });
  //   }
  }

  handleDownload = (url) => {
    // const data = {
    //   pnr: pnr,
    //   pnrType: 1,
    //   withFare: false,
    //   withTaxSplitup: false
    // };

   
    //GetTicketCopyUrl(data).then((response) => {    
      //if (response.data.status) { 

         //const pdfFilePath = 'assets/appData/TravelMythri E-Ticket.pdf'; // Replace with the actual PDF file path
          //const pdfFilePath = 'https://uattm.jameer.xyz/data/TravelMythri_E-Ticket.pdf';
          const pdfFilePath = url;
          
          fetch(pdfFilePath)
            .then(response => response.blob())
            .then(blob => {
              // Create a URL object from the blob
              const url = URL.createObjectURL(blob);
              
              // Open a new window or tab to display the PDF
              window.open(url, '_blank');
            })
            .catch(error => {
              console.error('Error downloading PDF:', error);
            });
      //}
      //else
      //{
        
      //}
    //})
      // .catch((error) => {
      
      //   if (error.response.data.responseMessage == "InvalidToken") {
          

      //     this.props.navigate('/');

      //   }
      //   const url = "";
      //   // Open a new window or tab to display the PDF
      //   window.open(url, '_blank');
      // })
      // .catch(error => {
      //   // console.error('Error downloading PDF:', error);
      // });
    
  };


  render() { 
  return (this.state.isLoaded) ? (
    <div>
 <div className="passenger-details-area pt-5">
  <div className="container">
    <div className="row">
      <div className="col-lg-9 col-md-9">
        {this.state.BookingResponse.status == true && (
         <div className="row">
          <div className="col-lg-1 col-md-1">
            <img src="assets/img/booking-success.png" width={60} />
          </div>
          <div className="col-lg-8 col-md-8">
            <h3>Your Booking Confirmed!</h3>
              <p className="no-margin">Thank you for booking with TravelMythri!</p>
        <p className="no-margin">Your E-ticket has been sent to <strong>{this.state.BookingResponse.email}</strong>.<br /></p>
        {this.state.BookingResponse.bookingReferenceIdR!=null && <p className="no-margin">PNR / Booking ID: <strong>{this.state.BookingResponse.bookingReferenceId}</strong> <strong>{this.state.BookingResponse.bookingReferenceIdR}</strong></p>}
        {this.state.BookingResponse.bookingReferenceIdR==null && <p className="no-margin">PNR / Booking ID: <strong>{this.state.BookingResponse.bookingReferenceId}</strong> </p>}
        
        <div className="mb-3"><h3 className="no-margin">You Paid <strong>₹ {this.state.BookingResponse.paidAmount}</strong></h3></div>
          </div>
          <div className="col-lg-3 col-md-3 float-end text-right mb-3">
          <a className="btn btn-primary" onClick={()=>{this.handleDownload(this.state.BookingResponse.ticketUrl)}}>
            <img src="assets/img/ticket.svg" width={30} /> Get your Ticket
          </a>
        </div>
       
      
         </div> 
        )}
{/* Failed Booking section starts */}
{this.state.BookingResponse.status == false && (
<div className="row">
          <div className="col-lg-2 col-md-2 mb-3">
            <img src="assets/img/booking-failed.png" width={100} />
          </div>
          <div className="col-lg-9 col-md-9" style={{minHeight: '310px'}}>
            <h3>Your Booking Failed!</h3>
              <p className="no-margin">Please contact with our customer care</p>
       
        
        
          </div>
          
       
      
         </div>
)}
{/* Failed Booking section ends */}

{this.state.BookingResponse.status == true && (
<div className="flight-details">
        <h4 className="td-head">Flight Details</h4>
        {/*Flight details*/}  
        <div className="deal-wrapper mb-3">
        {this.state.BookingSegmentDetails.map((item, index) => (
          <div className="t-details-area mb-0" key={index}>
          <div className="row align-items-center">
            <div className="airline-company col-lg-2 col-md-2 text-center">
              <img src={`https://agents.alhind.com/images/logos/${item.airlineCode}.gif`} width={40} />
              <p className="small-text">{item.airlineName}<br />{item.airlineCode}</p>
            </div>
            <div className="route-details-area col-lg-2 col-md-2 text-right">
              <div className="row">
                <p className="small-text">{item.departureAirportCode}</p>
                <h6 className="time-text">{item.departureTime}</h6>
                <p className="small-text text-grey">{item.departureDate}<br />{item.departureCity}</p>
              </div>
            </div>
            <div className="travel-time col-lg-2 col-md-2 text-center">
              <p className="small-text text-grey">{item.travelDuration}</p>
              <span><img src="assets/img/arrow.png"  /></span>
              {/* <p className="small-text text-grey">{item.stop }</p> */}
            </div>
            <div className="route-details-area col-lg-2 col-md-2 text-left">
              <div className="row">
                <p className="small-text">{item.arrivalAirportCode}</p>
                <h6 className="time-text">{item.arrivalTime}</h6>
                <p className="small-text text-grey">{item.arrivalDate}<br />{item.arrivalCity}</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 airline-price-area">
           
               <p className="small-text">{item.airlineFlightClass}</p>
               {item.cabinBaggage.length > 0 && (
                  <p className="small-text"><img src="assets/img/baggage.svg" width={15} /> {item.cabinBaggage}</p>
               )}
            </div>
            <div className="col-lg-2 col-md-2">
              <p className="no-margin"><strong>PNR: {item.airlinePnr}</strong></p>
            </div>
          </div>
        </div>
          ))}
        </div>
        </div>
)}
        {/*flight details ends*/}
        {this.state.BookingResponse.status == true && (
        <div className="travel-details">
        <h4>Traveller Details</h4>
       <div className="bg-white p-2 mb-3">
       <table className="table pax-table">
        <tbody>
          {this.state.BookingPaxlistRsp.map((item, index) => (
            <tr key={index}>
              <td align="left"><img src="assets/img/user.svg" width={50}/>{item.paxName}</td>
              <td align="right">{item.paxType}</td>
            </tr>
          ))}
          {/* <tr>
          <td align="left"><img src="assets/img/user.svg" width={50}/>Second Passenger Name</td>
           <td align="right">Child</td>
        </tr>
        <tr>
          <td align="left"><img src="assets/img/user.svg" width={50}/>Third Passenger Name</td>
           <td align="right">Infant</td>
        </tr> */}
        </tbody>
       </table>
       </div>
        </div>
        )}
      </div>
      <div className="col-lg-3 col-md-3">
       <div className="bg-white p-3">
        <h4 className="no-margin"><img src="assets/img/support.svg" width={35}/> 1800 6455 7789.</h4>
        <h4 className="no-margin"><img src="assets/img/email.svg" width={35}/> travelmythri@gmail.com</h4>
       </div>
     
       
      </div>
    </div>
  </div>
</div>



    </div>
   ) :  (
    <div>
        
    <div className="loader">
<div className="wait"> 
<h4>Hold on!</h4>
We are fetching the best fares for You!
</div>
{/* <div class="iata_code departure_city">CDG</div> */}
<div className="plane1">
<img src="assets/img/loader.gif" class="plane1-img" />
</div>
<div class="earth-wrapper">
<div class="earth"></div>
</div>  
{/* <div class="iata_code arrival_city">JFK</div> */}  
</div>

</div>
)
  }
}

function WithNavigate(props) {
  let location= useLocation();
  return <BookingSuccess {...props} location={location}/>
}

export default WithNavigate;
