import AxiosConfig from '../Configuration/AxiosConfig'
import HeaderConfig from '../Configuration/HeaderConfig';
import { CheckInternetConnection } from '../Services/UtilitiesServices';

export function GetAirlineTicketHistory(data)
{ 
    return AxiosConfig.post("Airline/GetAirlineTicketHistory",data, HeaderConfig())

}

export function GetAirlineBanners(data)
{ 
    return AxiosConfig.post("Airline/GetAirlineBanners",data, HeaderConfig())
}

export function GetFlightDeals(data)
{ 
    return AxiosConfig.post("Airline/GetFlightDeals",data, HeaderConfig())
}









